// import { formatDate } from "helpers/StringNumberFunction/formatDate";
// import { amtStr } from "helpers/numFormatter";
import React, { CSSProperties } from 'react'
import { format } from 'date-fns'
import { amtStr } from '../../containers/helper/numFormatter'

interface TextXProps {
  data: string | number
  // style?: React.CSSProperties
  className?: string
  dataType?: 'Text' | 'Number' | 'Date' | 'Phone' | 'Email' | 'Percentage'
  alt?: string
  textStyle?: {
    color?: CSSProperties['color']
    fontSize?: CSSProperties['fontSize']
    fontStyle?: CSSProperties['fontStyle']
    fontWeight?: CSSProperties['fontWeight']
    letterSpacing?: CSSProperties['letterSpacing']
    lineHeight?: CSSProperties['lineHeight']
    textAlign?: CSSProperties['textAlign']
    textDecoration?: CSSProperties['textDecoration']
    textTransform?: CSSProperties['textTransform']
    whiteSpace?: CSSProperties['whiteSpace']
    wordBreak?: CSSProperties['wordBreak']
    wordSpacing?: CSSProperties['wordSpacing']
  }
}

const TextX: React.FC<TextXProps> = React.memo(
  ({ data, className, dataType = 'Text', alt = '-', textStyle }) => {
    let tStyle = {
      color: textStyle?.color,
      fontSize: textStyle?.fontSize,
      fontStyle: textStyle?.fontStyle,
      fontWeight: textStyle?.fontWeight,
      letterSpacing: textStyle?.letterSpacing,
      lineHeight: textStyle?.lineHeight,
      textAlign: textStyle?.textAlign,
      textDecoration: textStyle?.textDecoration,
      textTransform: textStyle?.textTransform,
      whiteSpace: textStyle?.whiteSpace,
      wordBreak: textStyle?.wordBreak,
      wordSpacing: textStyle?.wordSpacing,
      // ...style,
    }

    if (data) {
      switch (dataType) {
        case 'Number':
          return (
            <span className={className} style={tStyle}>
              {amtStr(data.toString())}
            </span>
          )
        case 'Percentage':
          return (
            <span className={className} style={tStyle}>
              {`${data.toLocaleString('en-US')}%`}
            </span>
          )
        case 'Text':
          return (
            <span className={className} style={tStyle}>
              {data}
            </span>
          )
        case 'Date':
          const dateData = typeof data === 'number' ? data.toString() : data
          return (
            <span className={className} style={tStyle}>
              {format(new Date(dateData), 'dd MMM yyyy')}
            </span>
          )
        case 'Phone':
          return (
            <span className={className} style={tStyle}>
              <a href={`tel:${data}`}>{data}</a>
            </span>
          )
        case 'Email':
          return (
            <span className={className} style={tStyle}>
              <a href={`mailto:${data}`}>{data}</a>
            </span>
          )
        default:
          break
      }
    }

    return (
      <span className={className} style={tStyle}>
        {dataType === 'Number' ? '0.00' : alt}
      </span>
    )
  }
)

export default TextX
