import {
  Card,
  Chip,
  ColumnFlex,
  Container,
  Dialog,
  EmptyState,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  RowFlex,
  RowGrid,
  SubContainerX,
  Subheader,
  SubheaderText,
  useMenuOption,
} from '@ifca-ui/core'
import { Box, Button, IconButton, Link, Typography } from '@mui/material'
import { TopSectionHeader } from 'component/HeaderSection'
import useNav from 'component/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useProjectSummaryQuery,
  useJointBuyerDetailsQuery,
  useGetEditJointBuyerQuery,
  useDeactivateJointBuyerMutation,
  CommonStatus,
  useGetPurchaserDetailsQuery,
} from 'generated/graphql'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import EmailIcon from '@mui/icons-material/Email'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { BOOKING_LIST_PATH } from './Routes'

const JoinBuyerList = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const saleId = queryParams.get('saleId')
  const projectid = localStorage.getItem('lastestProject')
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null
  )

  const navigate = useNavigate()

  // region Dialog's Const
  const [openProfileInfoDialog, setOpenProfileInfoDialog] = useState(false)
  const [selectedFullName, setSelectedFullName] = useState<string | null>(null)
  const [selectedIC, setSelectedIC] = useState<string | null>(null)
  const [selectedSalutation, setSelectedSalutation] = useState<string | null>(
    null
  )
  const [selectedRace, setSelectedRace] = useState<string | null>(null)
  const [selecteNationality, setSelectedNationality] = useState<string | null>(
    null
  )
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState<
    string | null
  >(null)
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null)
  const [selectedOccupation, setSelectedOccupation] = useState<string | null>(
    null
  )
  const [selectedMobileNo, setSelectedMobileNo] = useState<string | null>(null)
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null)

  // region end

  // region Menu

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)

  const [activatedJointBuyerId, setActivatedJointBuyerId] = useState(null)
  const [activatedJointBuyerName, setActivatedJointBuyerName] = useState(false)
  const [deactivate, setDeactivate] = useState(false)
  const [deactivateDialog, setDeactivateDialog] = useState(false)

  const handleEditClick = (contactId: string) => {
    navigate(
      `${BOOKING_LIST_PATH.EDITJOINTBUYER}?saleId=${saleId}&project_id=${projectid}&unitId=${BookingDetail?.unit?.id}&contactId=${contactId}`
    )
    handleClose()
  }

  const handleDeactivateClick = (id: any, name: any, deactivate: any) => {
    setActivatedJointBuyerId(id)
    setActivatedJointBuyerName(name)
    setDeactivate(deactivate)
    setDeactivateDialog(true)
  }

  // region end

  // region Project header

  const {
    refetch: refetchProjectQuery,
    data: { getProjectById: projectName } = { getProjectbyId: null },
  } = useProjectSummaryQuery({
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Joint Buyer',
          },
        },
      },
    })
  }, [projectName])

  // region end

  // region Joint buyer listing

  const {
    data: { GetBookingDetail: BookingDetail } = { GetBookingDetail: null },
    refetch,
  } = useJointBuyerDetailsQuery({
    variables: {
      sale_id: saleId,
    },
    onError: error => {
      // Handle error
      rootDispatch({
        type: 'snackBar',
        payload: {
          open: true,
          message: error.graphQLErrors.map(({ message }) => message).join(', '),
        },
      })
    },
  })

  const jointBuyerList = BookingDetail?.joinBuyer

  // region end

  // region Joint Buyer Deactivate / Activate API call

  const [deactivateJointBuyer] = useDeactivateJointBuyerMutation({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data && data.deactivateJointBuyer) {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: deactivate
              ? 'Joint Buyer has been deactivated successfully.'
              : 'Joint Buyer has been activated successfully.',
          },
        })
      }
      setDeactivateDialog(false)
      refetch()
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  // region Dialog API call

  const {
    data: { GetPurchaserDetails: PurchaserDetailsData } = {
      GetBookingDetail: null,
    },
  } = useGetPurchaserDetailsQuery({
    variables: {
      contactId: selectedContactId,
    },
    skip: !openProfileInfoDialog,
    onCompleted: data => {
      const firstAddress = data.GetPurchaserDetails.contactAddress?.[0]
      if (data?.GetPurchaserDetails) {
        setSelectedFullName(data.GetPurchaserDetails.fullName || null)
        setSelectedIC(
          data.GetPurchaserDetails.icNo ||
            data.GetPurchaserDetails.militaryIc ||
            null
        )
        setSelectedSalutation(data.GetPurchaserDetails.salutation?.name || null)
        setSelectedRace(data.GetPurchaserDetails.race?.name || null)
        setSelectedNationality(
          data.GetPurchaserDetails.nationality?.name || null
        )
        setSelectedMaritalStatus(
          data.GetPurchaserDetails.maritalStatus?.name || null
        )
        setSelectedAddress(
          firstAddress
            ? (firstAddress.address || '') +
                ', ' +
                (firstAddress.postCode || '') +
                ', ' +
                (firstAddress.city || '') +
                ', ' +
                (firstAddress.state || '') +
                ', ' +
                (firstAddress.country || '')
            : null
        )
        setSelectedOccupation(data.GetPurchaserDetails.companyName || null)
        setSelectedMobileNo(data.GetPurchaserDetails.phoneNo || null)
        setSelectedEmail(data.GetPurchaserDetails.email || null)
      }
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  // region Dialog

  const handleDialogClick = (id: any) => {
    const contactId = id
    setSelectedContactId(contactId)
    setOpenProfileInfoDialog(true)
  }

  // region end

  // region Deactivate / activate joint buyer dialog

  const DeactivateDialog = (
    <Dialog
      fullWidth={true}
      open={deactivateDialog}
      onClose={() => setDeactivateDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {deactivate
                      ? 'Deactivate Joint Buyer'
                      : 'Activate Joint Buyer'}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box width="100%" display="flex">
          <Box width="100%" boxSizing="border-box" marginBottom="14px">
            <Typography
              noWrap
              component="div"
              color="grey.600"
              fontSize="10px"
              fontWeight={400}
              lineHeight="16px"
            >
              Name
            </Typography>
            <Typography
              noWrap
              component="div"
              color="common.black"
              fontSize="12px"
              fontWeight={500}
              lineHeight="16px"
            >
              {activatedJointBuyerName}
            </Typography>
          </Box>
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeactivateDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => {
              deactivateJointBuyer({
                variables: {
                  jointBuyerId: activatedJointBuyerId,
                },
              })

              setDeactivateDialog(false)
            },
          },
        ],
      }}
    />
  )

  // region end

  return (
    <>
      <Container>
        <Card
          className="card-container-detail"
          header={{
            title: (
              <SubheaderText
                primary={
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      color="black"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Joint Buyer Listing
                    </Typography>
                  </Box>
                }
              />
            ),
            action: (
              <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  navigate(
                    `${BOOKING_LIST_PATH.ADDJOINTBUYER}?saleId=${saleId}&project_id=${projectid}&unitId=${BookingDetail?.unit?.id}`
                  )
                }
              >
                <AddCircleOutlineIcon color="primary" />
              </IconButton>
            ),
          }}
        >
          <Container>
            <List>
              {jointBuyerList === undefined || jointBuyerList?.length === 0 ? (
                <EmptyState />
              ) : (
                jointBuyerList.map((jointBuyer, index) => (
                  <Box key={jointBuyer?.id} component="div">
                    <ListItemText
                      primary={
                        <Typography
                          color="common.black"
                          component="div"
                          display="flex"
                          variant="inherit"
                        >
                          <Typography
                            className="text-icon text-xsTitle"
                            color="black"
                            component="span"
                            flex="1"
                            variant="inherit"
                            sx={{
                              textDecoration: 'underline',
                              marginTop: '-12px',
                            }}
                            onClick={() =>
                              handleDialogClick(jointBuyer?.contact?.id)
                            }
                          >
                            {jointBuyer?.contact?.fullName}
                          </Typography>
                        </Typography>
                      }
                      secondary={
                        <>
                          <RowGrid gridTemplateColumns="1fr 1fr" height="15px">
                            <Typography
                              className="text-icon text-desc"
                              color="#454545"
                              component="div"
                              variant="inherit"
                            >
                              <PhoneAndroidIcon />
                              <Link href={`tel:${jointBuyer.contact.phoneNo}`}>
                                {jointBuyer?.contact?.phoneNo}
                              </Link>
                              {'\u00A0|\u00A0'}
                              <EmailIcon />
                              <Link href={`mailto:${jointBuyer.contact.email}`}>
                                {jointBuyer.contact.email}
                              </Link>
                            </Typography>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              {jointBuyer?.commonStatus ===
                              CommonStatus.Inactive ? (
                                <Chip
                                  label={
                                    <Typography
                                      component="span"
                                      className="text-label"
                                      variant="inherit"
                                      sx={{ padding: '0px' }}
                                    >
                                      INACTIVE
                                    </Typography>
                                  }
                                  sx={{
                                    background: '#696969',
                                    color: '#ffffff',
                                    padding: '0px',
                                  }}
                                />
                              ) : null}
                              <IconButton
                                sx={{ marginRight: '-14px' }}
                                onClick={(
                                  e: React.MouseEvent<HTMLButtonElement>
                                ) =>
                                  handleClick(
                                    e,
                                    jointBuyer?.id,
                                    index,
                                    jointBuyer
                                  )
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Box>
                          </RowGrid>
                        </>
                      }
                    />
                  </Box>
                ))
              )}
            </List>
          </Container>
        </Card>
      </Container>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem  onClick={() => {handleEditClick(menu?.data?.contact?.id); }}>Edit</MenuItem>
        <MenuItem
          onClick={() =>
            handleDeactivateClick(
              menu?.data?.id,
              menu?.data?.contact?.fullName,
              menu?.data?.commonStatus === CommonStatus.Active
            )
          }
        >
          {menu?.data?.commonStatus === CommonStatus.Active
            ? 'Deactivate'
            : 'Activate'}
        </MenuItem>
      </Menu>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openProfileInfoDialog}
        onClose={() => setOpenProfileInfoDialog(false)}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Typography
                      className="text-mdTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Joint Buyer Info
                    </Typography>
                  </Box>
                }
              />
            </Subheader>
          </>
        }
        body={
          <>
            <ColumnFlex gap="12px">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Full Name (as per NRIC)
                </span>
                <span className="color-text fw-600 fs-12">
                  {selectedFullName || '-'}
                </span>
              </ColumnFlex>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  NRIC No. / Passport No. / ROC No.
                </span>
                <span className="color-text fw-600 fs-12">
                  {selectedIC || '-'}
                </span>
              </ColumnFlex>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Salutation</span>
                  <span className="color-text fw-600 fs-12">
                    {selectedSalutation || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Race</span>
                  <span className="color-text fw-600 fs-12">
                    {selectedRace || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Nationality</span>
                  <span className="color-text fw-600 fs-12">
                    {selecteNationality || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Marital Status
                  </span>
                  <span className="color-text fw-600 fs-12">
                    {selectedMaritalStatus || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Correspondence Address
                </span>
                <span className="color-text fw-600 fs-12">
                  {selectedAddress || '-'}
                </span>
              </ColumnFlex>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Occupation</span>
                  <span className="color-text fw-600 fs-12">
                    {selectedOccupation || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Mobile No.</span>
                  <a
                    className="color-orange fw-600 fs-12"
                    href={`tel:${selectedMobileNo}`}
                  >
                    {selectedMobileNo || '-'}
                  </a>
                </ColumnFlex>
              </RowGrid>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">Email Address</span>
                <a
                  className="color-orange fw-600 fs-12"
                  href={`mailto:${selectedEmail}`}
                >
                  {selectedEmail || '-'}
                </a>
              </ColumnFlex>
            </ColumnFlex>
          </>
        }
      />

      {DeactivateDialog}
    </>
  )
}

export default JoinBuyerList
