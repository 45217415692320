import { Card, Container, SubheaderText } from '@ifca-ui/core'
import { ContentCopyRounded } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { TopSectionHeader } from 'component/HeaderSection'
import useNav from 'component/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useProjectSummaryQuery } from 'generated/graphql'
import { useContext, useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useLocation } from 'react-router'

const ShareELettering = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const baseUrl = 'https://sales-dev.ifca.io/e-signing/e-signature'

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const saleId = queryParams.get('saleId')

  // region Project Header

  const { data: { getProjectById: projectName } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Show QR Code',
          },
        },
      },
    })
  }, [projectName])

  // region end

  // region QR Code

  const [shareTo, setShareTo] = useState({
    qrCodeUrl: '',
  })

  useEffect(() => {
    const link = `${baseUrl}?saleId=${saleId}`
    setShareTo({
      qrCodeUrl: link,
    })
  }, [baseUrl, saleId])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareTo.qrCodeUrl)
  }

  // region end

  return (
    <>
      <Container>
        <Card
          className="card-container-detail"
          header={{
            title: (
              <SubheaderText
                primary={
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Share E-Lettering URL
                    </Typography>
                  </Box>
                }
              />
            ),
          }}
        >
          <Box>
            <QRCode
              value={shareTo.qrCodeUrl}
              size={150}
              style={{ display: 'block', margin: '0 auto', padding: '10px' }}
            />

            <Box>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  width: '100%',
                  border: '1px solid #DCDCDC',
                  borderRadius: '5px',
                  padding: '8px',
                  fontSize: '12px',
                  fontWeight: 500,
                  marginTop: '10px',
                }}
              >
                <span style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                  {shareTo.qrCodeUrl}
                </span>

                <div
                  onClick={copyToClipboard}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <ContentCopyRounded fontSize="small" />
                </div>
              </span>
            </Box>

            <span
              style={{
                display: 'block',
                width: '100%',
                textAlign: 'center',
                fontSize: '11px',
              }}
            >
              If cannot scan the QR Code, copy this link.
            </span>
          </Box>
        </Card>
      </Container>
    </>
  )
}

export default ShareELettering
