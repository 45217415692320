import { yupResolver } from '@hookform/resolvers/yup'
import { AuthContainer, AuthCopyRight, AuthButton } from '@ifca-ui/core'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import AuthBackground from 'assets/images/product/auth-background.jpg'
import IfcaLogo from 'assets/images/product/w-ifca-logo.png'
import Logo from 'assets/images/product/property-x.png'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { CryptoHelper } from '../../helper/Crypto/Crypto'
import {
  getAccessToken,
  getRegistrationToken,
  setAccessToken,
} from 'AccessToken'
import { Controller, useForm } from 'react-hook-form'
import { Check, Visibility, VisibilityOff } from '@mui/icons-material'
import { encryptMessage } from '../../helper/Crypto/JsEncrypt'
import {
  useGetDefaultTranslationQuery,
  useLoginLazyQuery,
} from 'generated/graphql'
import { ACCESS_SECURITY_PATH } from '../AccessSecurity/Routes'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { AUTH_PATH } from './Routes'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useGetTranslationQuery } from 'generated/graphql'

interface LoginProps {}
interface LoginForm {
  email: string
  password: string
}

const Login: FC<LoginProps> = () => {
  let navigate = useNavigate()

  const rememberUsers: any = JSON.parse(
    CryptoHelper.decryption(localStorage.getItem('rememberUsers') ?? null) ??
      null
  )

  const [rememberUser, setRememberUser] = useState(
    rememberUsers !== null ? true : false
  )

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email address is required')
      .email('Email address is invalid'),
    password: Yup.string().required('Password is required'),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<LoginForm>({
    defaultValues: {
      email: rememberUsers?.loginId ?? '',
      password: rememberUsers?.password ?? '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  })

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    const password = document.querySelector('#Password')
    const type =
      password?.getAttribute('type') == 'password' ? 'text' : 'password'
    password?.setAttribute('type', type)
    setShowPassword(!showPassword)
  }

  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)

  const {
    data: { getDefaultTranslation: defTranslation } = {
      getDefaultTranslation: null,
    },
  } = useGetDefaultTranslationQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      JSON.stringify(
        localStorage.setItem('getTranslation', data?.getDefaultTranslation?.obj ?? '')
      )
    },
  })

  const [loginQuery, { data, loading }] = useLoginLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      console.log(data, 'apo')
      if (data && data.login?.accessToken) {
        localStorage.setItem(
          'key',
          CryptoHelper.encryption(data.login.accessToken)
        )
        if (rememberUser) {
          let rememberUsers = {
            loginId: getValues('email'),
            password: getValues('password'),
          }
          localStorage.setItem(
            'rememberUsers',
            CryptoHelper.encryption(JSON.stringify(rememberUsers))
          )
        } else {
          localStorage.removeItem('rememberUsers')
        }

        setAccessToken(data.login?.accessToken)
        navigate(`/dashboards/landing`)
      }
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async (data: any) => {
    console.log(executeRecaptcha, 'executeRecaptcha')
    if (!executeRecaptcha) {
      console.log('Execute recaptcha is not yet available')
      return
    }

    // Execute reCAPTCHA and get the token
    const token = await executeRecaptcha('importantAction')

    // Send this token to your server to verify
    // Perform form submission logic here

    loginQuery({
      variables: {
        LoginId: data?.email,
        Password: data?.password || '',
        captchaToken: token,
      },
    })
  }

  const {
    data: { getDefaultTranslation: translation } = {
      getDefaultTranslation: null,
    },
  } = useGetDefaultTranslationQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if(data?.getDefaultTranslation?.obj) JSON.stringify(localStorage.setItem('getTranslation', data.getDefaultTranslation.obj))
    },
  })

  return (
    <AuthContainer
      backgroundImage={AuthBackground}
      title="Agency Online Platform"
      logo={Logo}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="form-content">
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.email ? true : false}
                helperText={errors.email?.message}
                label="Email"
                type="email"
                variant="standard"
                // name="email"
                // id="email"
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
                label="Password"
                type="password"
                variant="standard"
                id="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Box className="forget-password-row">
            <FormControlLabel
              control={
                <Checkbox
                  name="rmbUser"
                  color="primary"
                  checked={rememberUser}
                  onChange={e => setRememberUser(e.target.checked)}
                />
              }
              label="Remember User"
              labelPlacement="end"
            />
            <Typography
              variant="inherit"
              component="span"
              className="forget-password"
              onClick={() => navigate(AUTH_PATH.FORGOT_PASSWORD)}
            >
              Forgot Password?
            </Typography>
          </Box>
        </Box>
        <AuthButton variant="contained" color="primary" type="submit">
          Login
        </AuthButton>
      </form>
      <AuthCopyRight logo={IfcaLogo} />
    </AuthContainer>
  )
}

export default Login
