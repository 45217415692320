import Compressor from 'compressorjs'

export const attachmentType = {
    BOOKING_CCR_ATTACHMENT: "BOOKING_CCR_ATTACHMENT",
    BOOKING_BUYER_NRIC_ATTACHMENT: "BOOKING_BUYER_NRIC_ATTACHMENT",
}

export const attachmentRefTypeList = {
    bookingFrom: "BOOKING_FORM",
    customerIC_BuyerIC: "BOOKING_BUYER_NRIC_ATTACHMENT",
    customerIC_JointBuyerIC: "BOOKING_JOINT_BUYER_NRIC_ATTACHMENT",
    customerIC_BuyerPassport: "BOOKING_BUYER_PASSPORT_ATTACHMENT",
    customerCreditReport: "BOOKING_CCR_ATTACHMENT",
    customerPayslips: "LOAN_PAY_SLIP",
    customerEPFEABank: "LOAN_INCOME_STATEMENT",
    pdpaConsent: "LOAN_PDPA_CONSENT",
    otherDocuments: "LOAN_OTHER_ATTACHMENT",
  } as const;

  export const acceptFileType = {
    GIF: "image/gif",
    PNG: "image/png",
    JPG: "image/jpg",
    JPEG: "image/jpeg",
    PDF: "application/pdf",
  } as const;

  const compressImageFile = (file: File, waterMarkText?: string) => {
    let fileName = file.name
    let fileType = file.type
    return new Promise<File>((resolve, reject) => {
      let option: Compressor.Options = {
        strict: false,
        success: result => {
          let file = new File([result], fileName, {
            type: fileType,
          })
          resolve(file)
        },
        error: err => {
          reject('Failed to set watermark.')
        },
      }
  
      if (waterMarkText) {
        option.drew = (
          context: CanvasRenderingContext2D,
          canvas: HTMLCanvasElement
        ) => {
          let context2D = canvas.getContext('2d')
          let waterMarkMetrics = context2D.measureText(waterMarkText)
          let waterMarkWidth = waterMarkMetrics.width
          let waterMarkHeight = 15 // font size 15px
  
          context.fillStyle = '#808080'
          context.globalAlpha = 1
          context.font = '15px Tahoma'
          context.translate(
            canvas.width - (canvas.width * 20) / 100,
            ((15 + canvas.height) * 20) / 100
          )
          context.rotate(Math.atan(canvas.height / canvas.width))
          context.fillText(
            waterMarkText,
            -waterMarkWidth / 2,
            waterMarkHeight / 2
          )
        }
      }
  
      new Compressor(file, option)
    })
  }

  export const compressImage = (file: File) => {
    return compressImageFile(file)
  }

  export const setWatermark = (file: File, waterMarkText: string) => {
    return compressImageFile(file, waterMarkText)
  }

export class AttachmentHelper {
    acceptFileType: string[];
    maxFileSize: number;

    constructor(acceptFileType: string[], maxFileSize: number) {
        this.acceptFileType = acceptFileType;
        this.maxFileSize = maxFileSize;
    }

    checkFile(file): boolean {
        return this.checkFileType(file) && this.checkFileSize(file);
    }

    checkFileType(file): boolean {
        return this.acceptFileType.some((x) => x == file.type);
    }

    checkFileSize(file): boolean {
        let fileSize = file.size / 1024 / 1024;
        return fileSize <= this.maxFileSize;
    }

}



