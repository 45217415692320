import {
  Card,
  ColumnFlex,
  Container,
  Dialog,
  RowGrid,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetLeadPurchaserDetailsQuery,
  useWaitlistByUnitQuery,
} from 'generated/graphql'
import { useEffect, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { getName, getAgentDetail } from 'UserDetail'
import { navigationService } from 'navigationService'
import { formatDate } from 'containers/helper/formatDate'
import useNav from 'component/RouteService/useNav'

const WaitingListDetail = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const location = useLocation()
  const getQueryParams = (search: any) => {
    const params = new URLSearchParams(search)
    return {
      unitId: params.get('unitId'),
    }
  }
  const { unitId } = getQueryParams(location.search)
  const [profileID, setProfileID] = useState('')
  const [openProfileInfoDialog, setOpenProfileInfoDialog] = useState(false)

  const nav = new navigationService()
  let _historyItem = nav.getSessionStorage()
  const pathname = useLocation()
  useEffect(() => {
    if (nav.getSearchValue() !== null) {
      nav.updateState(nav.getSearchValue())
    }
  }, [pathname])

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: getName(),
          title: getAgentDetail(),
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 1,
            current: `Waiting List Details/${listDetails?.unitNo}`,
          },
        },
      },
    })
  }, [getName(), getAgentDetail()])

  const {
    data: { waitingListByUnit: listDetails } = { waitingListByUnit: null },
  } = useWaitlistByUnitQuery({
    variables: {
      unitId: unitId || '',
    },
    fetchPolicy: 'no-cache',
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { GetLeadPurchaserDetails: Profile } = {
      GetLeadPurchaserDetails: null,
    },
  } = useGetLeadPurchaserDetailsQuery({
    variables: { contactId: profileID },
    skip: !profileID,
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  return (
    <>
      <Container>
        {listDetails?.bookingWaitlists?.map((item, index) => (
          <Card>
            <Box width="100%" boxSizing="border-box">
              <Typography
                noWrap
                component="div"
                color="common.black"
                fontSize="12px"
                fontWeight="bold"
                lineHeight="16px"
                sx={{ textDecoration: 'underline' }}
                onClick={e => {
                  e.stopPropagation()
                  const id = item?.contact?.id ?? ''
                  setProfileID(id)
                  setOpenProfileInfoDialog(true)
                }}
              >
                {index + 1}. {item?.contact?.fullName}
              </Typography>
              <Typography
                noWrap
                component="div"
                color="common.black"
                fontSize="10px"
                fontWeight={400}
                lineHeight="16px"
              >
                {'Queued date: ' +
                  formatDate(item?.queueTime) +
                  ' | ' +
                  item?.agency}
              </Typography>
            </Box>
          </Card>
        ))}
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openProfileInfoDialog}
        onClose={() => setOpenProfileInfoDialog(false)}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Typography
                      className="text-mdTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Purchaser Info
                    </Typography>
                  </Box>
                }
              />
            </Subheader>
          </>
        }
        body={
          <>
            <ColumnFlex gap="12px">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Full Name (as per NRIC)
                </span>
                <span className="color-text fw-600 fs-12">
                  {Profile?.fullName || '-'}
                </span>
              </ColumnFlex>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  NRIC No. / Passport No. / ROC No.
                </span>
                <span className="color-text fw-600 fs-12">
                  {Profile?.icNo || '-'}
                </span>
              </ColumnFlex>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Salutation</span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.salutation?.name || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Race</span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.race?.name || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Nationality</span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.nationality?.name || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Marital Status
                  </span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.maritalStatus?.name || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Correspondence Address
                </span>
                <span className="color-text fw-600 fs-12">
                  {Profile?.address?.address || '-'}
                </span>
              </ColumnFlex>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Mobile No.</span>
                  <a
                    className="color-orange fw-600 fs-12"
                    href={`tel:${Profile?.phoneNo}`}
                  >
                    {Profile?.phoneNo || '-'}
                  </a>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Email Address
                  </span>
                  <a
                    className="color-orange fw-600 fs-12"
                    href={`tel:${Profile?.email}`}
                  >
                    {Profile?.email || '-'}
                  </a>
                </ColumnFlex>
              </RowGrid>
            </ColumnFlex>
          </>
        }
      />
    </>
  )
}

export default WaitingListDetail
