import { RouteObject } from 'react-router-dom'
import WaitingList from './WaitingList'
import WaitingListDetail from './WaitingListDetail'
import NewWaitList from './NewWaitList'

export const WAITINGLIST_PATH = {
  WAITINGLIST: '/book/waiting-list',
  WAITINGLISTDETAIL: '/book/waiting-purchaser/:unitId',
  NEWWAITLIST: '/book/new-wait-list/:projectid',
}

const WaitingListRoutes: RouteObject[] = [
  {
    path: WAITINGLIST_PATH.WAITINGLIST,
    element: <WaitingList />,
  },
  {
    path: WAITINGLIST_PATH.WAITINGLISTDETAIL,
    element: <WaitingListDetail />,
  },
  {
    path: WAITINGLIST_PATH.NEWWAITLIST,
    element: <NewWaitList />,
  },
]

export default WaitingListRoutes
