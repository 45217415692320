import {
  Container,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  EmptyState,
  SubContainerX,
  RowFlex,
  RowGrid,
} from '@ifca-ui/core'
import { Add as AddIcon, HouseOutlined } from '@mui/icons-material'
import { KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { Filter } from 'containers/helper/searchFilterHelper'
import {
  useProjectSummaryQuery,
  useGetBookingListDataQuery,
  useGetProjectSpecDataQuery,
  useAllowBookQuery,
} from 'generated/graphql'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import React from 'react'
import { useFuseSearch } from 'containers/helper/hooks/useSearch'
import useNav from 'component/RouteService/useNav'
import { TopSectionHeader } from 'component/HeaderSection'
import { navigationService } from 'navigationService'
import { SwitchProjectDialog } from 'component/SwitchProjectDialog'
import { formatDate } from 'containers/helper/formatDate'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PersonIcon from '@mui/icons-material/Person'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import LabelIcon from './../../../assets/images/icon/date-book.svg'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { BOOKING_LIST_PATH } from './Routes'
import SwapVertIcon from '@mui/icons-material/SwapVert'

const BookingList = () => {
  const nav = new navigationService()
  const { navBack, navData } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const [change, setChanges] = React.useState(false)

  const projectid = localStorage.getItem('lastestProject')

  const [limit, setLimit] = useState(30)
  const [page, setPage] = useState(1)

  const navigate = useNavigate()

  // Date

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  // Status

  const [selectedStatus, setSelectedStatus] = useState<
    'All' | 'Booked' | 'Signed' | 'Cancelled'
  >('Booked')

  const [searchInput, setSearchInput] = React.useState('')
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()

  const searchValue = (x: any) => {
    handleSearch(x, ['name'])
  }

  const handleSearchInputChange = (value: any) => {
    setSearchInput(value)
    searchValue(value)
  }

  const handleClickChangesDialog = () => {
    setChanges(true)
  }
  const handleCloseChangesDialog = () => {
    setChanges(false)
    nav.removeProject()
    refetchProjectQuery()
    refetchListQuery()
  }

  const {
    refetch: refetchProjectQuery,
    data: { getProjectById: projectName } = { getProjectbyId: null },
  } = useProjectSummaryQuery({
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          icon: 'switch',
          props: {
            onClick: () => handleClickChangesDialog(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Booking',
          },
        },
      },
    })
  }, [projectName])

  const {
    refetch: refetchListQuery,
    data: { getBookingListData: BookingList } = { getBookingListData: null },
  } = useGetBookingListDataQuery({
    variables: {
      status: selectedStatus,
      limit: limit,
      page: page,
    },
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // Sorting

  const [sortBy, setSortBy] = useState<
    'unitNo' | 'buyerName' | 'salesPerson' | 'default'
  >('default')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleSort = (field: 'unitNo' | 'buyerName' | 'salesPerson') => {
    if (sortBy === field) {
      setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortBy(field)
      setSortDirection('asc')
    }
  }

  const sortedList = (list: any[]) => {
    if (sortBy === 'default') return list

    return list.sort((a: any, b: any) => {
      const aValue = a?.data?.[sortBy]
      const bValue = b?.data?.[sortBy]

      if (!aValue || !bValue) return 0
      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1
      return 0
    })
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // Date

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  // Status

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const status = event.target.value as
      | 'All'
      | 'Booked'
      | 'Signed'
      | 'Cancelled'
    setSelectedStatus(status)
    refetchListQuery()
  }

  return (
    <>
      <SubContainerX>
        <Box width="100%" display="flex" alignItems="center">
          <Box width="100%" display="flex" alignItems="center">
            <SearchBar
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  {`Booking List`} (
                  <Typography
                    component="span"
                    variant="inherit"
                    className="search-bar-count-f"
                  >
                    {BookingList?.total}
                  </Typography>
                  )
                </Typography>
              }
              searchInput={{
                value: searchInput,
                onChange: e => {
                  handleSearchInputChange(e.target.value)
                },
                onChangeClearInput: () => {
                  handleSearchInputChange('')
                },
              }}
              isAdvanceSearch={false}
            />
          </Box>

          <Box
            width="auto"
            display="flex"
            alignItems="center"
            pl="6px"
            bgcolor="white"
            ml={0}
            boxShadow="0 1px 4px rgba(0, 0, 0, 0.1)"
          >
            <Tooltip title="Sort">
              <IconButton onClick={handleMenuClick}>
                <SwapVertIcon />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleSort('unitNo')}>
                Unit No (A-Z)
              </MenuItem>
              <MenuItem onClick={() => handleSort('unitNo')}>
                Unit No (Z-A)
              </MenuItem>
              <MenuItem onClick={() => handleSort('buyerName')}>
                Purchaser Name (A-Z)
              </MenuItem>
              <MenuItem onClick={() => handleSort('buyerName')}>
                Purchaser Name (Z-A)
              </MenuItem>
              <MenuItem onClick={() => handleSort('salesPerson')}>
                Sales Person Name (A-Z)
              </MenuItem>
              <MenuItem onClick={() => handleSort('salesPerson')}>
                Sales Person Name (Z-A)
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </SubContainerX>

      <Container>
        <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="10px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  placeholder: 'Date',
                  sx: {
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: 0,
                      },
                      height: '40px',
                    },
                    '& input::placeholder': {
                      fontSize: '12px',
                      color: 'gray',
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>

          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{ border: 'none' }}
          >
            <Select
              value={selectedStatus}
              onChange={handleStatusChange}
              labelId="status-select-label"
              sx={{
                backgroundColor: 'white',
                border: 'none',
                padding: '0px',
                height: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-icon': {
                  right: '8px',
                },
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Booked">Booked</MenuItem>
              <MenuItem value="Signed">Signed</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </RowGrid>
      </Container>

      <Container>
        <List>
          {!BookingList ||
          !Array.isArray(BookingList.data) ||
          BookingList.total === 0 ? (
            <EmptyState />
          ) : (
            sortedList(Filter(BookingList.data, searchInput))?.map(
              (item: any) => (
                <ListItem
                  key={item?.salesId}
                  onClick={() => {
                    navigate(
                      `${BOOKING_LIST_PATH.BOOKINGDETAILS}?saleId=${item?.salesId}&projectid=${projectid}`
                    )
                  }}
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        navigate(
                          `${BOOKING_LIST_PATH.BOOKINGDETAILS}?saleId=${item?.salesId}&projectid=${projectid}`
                        )
                      }}
                    >
                      <KeyboardArrowRightOutlinedIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      <Typography
                        component="div"
                        variant="inherit"
                        color="common.black"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                              }}
                            >
                              <HouseOutlined
                                sx={{
                                  fontSize: '12px',
                                  marginRight: '5px',
                                }}
                              />
                              {item?.unitNo}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#454545"
                              sx={{
                                marginTop: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                              }}
                            >
                              <PersonIcon
                                sx={{
                                  fontSize: '12px',
                                  color: '#454545',
                                  marginRight: '5px',
                                }}
                              />
                              {item?.buyerName}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#454545"
                              sx={{
                                marginTop: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                              }}
                            >
                              <MonetizationOnIcon
                                sx={{
                                  fontSize: '12px',
                                  color: 'blue',
                                  marginRight: '5px',
                                }}
                              />
                              {new Intl.NumberFormat('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item?.bookPaidAmount)}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#454545"
                              sx={{
                                marginTop: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                              }}
                            >
                              <PersonOutlineIcon
                                sx={{
                                  fontSize: '12px',
                                  color: '#454545',
                                  marginRight: '5px',
                                }}
                              />
                              {item?.agentName}
                              <span
                                style={{
                                  marginLeft: '4px',
                                }}
                              >
                                ({item?.agencyAppointedName})
                              </span>
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              color="#454545"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                                paddingBottom: '20px',
                              }}
                            >
                              <MonetizationOnIcon
                                sx={{
                                  fontSize: '12px',
                                  color: 'blue',
                                  marginRight: '5px',
                                }}
                              />
                              {new Intl.NumberFormat('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item?.sellingPrice)}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#454545"
                              sx={{
                                marginTop: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                              }}
                            >
                              Booking Fee Paid By {item?.paymentMethodDesc}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              color="#454545"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                              }}
                            >
                              <RowFlex
                                crossAxisAlignment="center"
                                height="18px"
                              >
                                <ListItemIcon
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <img
                                    src={LabelIcon}
                                    height="16px"
                                    alt="Label"
                                    style={{ verticalAlign: 'middle' }}
                                  />
                                </ListItemIcon>
                                <span
                                  style={{
                                    fontSize: '12px',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  {formatDate(item?.saleDate)}
                                </span>
                              </RowFlex>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    }
                  />
                </ListItem>
              )
            )
          )}
        </List>
      </Container>

      <FloatButton
        color="primary"
        onClick={() =>
          navigate(`${BOOKING_LIST_PATH.NEWBOOKING}?projectid=${projectid}`)
        }
      >
        <AddIcon />
      </FloatButton>

      <SwitchProjectDialog
        open={change}
        onClose={handleCloseChangesDialog}
        name="BookingList"
        currProjectId={projectid}
        page="BookingList"
      />
    </>
  )
}

export default BookingList
