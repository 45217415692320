// import useNav from 'component/RouteService/useNav'
// import AppContext, { AppContextProps } from 'containers/context/Context'
// import React, { useEffect, useReducer, useRef, useState } from 'react'
// import { PxLetterB } from '@ifca-ui/icons/dist/index.esm'
// import {
//   useGetSalesChartDataQuery,
//   useGetBlockUnitsQuery,
//   useGetSalesChartByBlockQuery,
//   useGetMultiELetterBySaleIdQuery,
//   useGetMultiplePhaseIntegrateQuery,
//   useProjectSummaryQuery,
//   useGetSalesChartByBlockLazyQuery,
//   SalesChartFloorUnitOutput,
// } from 'generated/graphql'
// import { UseGetSelectedUnit } from 'component/Table/UseGetSelectedUnit'
// import { TopSectionHeader } from 'component/HeaderSection'
// import {
//   Box,
//   Checkbox,
//   Divider,
//   FormControl,
//   FormControlLabel,
//   IconButton,
//   InputLabel,
//   Popover,
//   Select,
//   Slider,
//   TextField,
//   Typography,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material'
// import {
//   Container,
//   EmptyState,
//   SearchBar,
//   SubContainerX,
//   Subheader,
//   SubheaderText,
//   Tab,
//   Tabs,
//   Wrap,
// } from '@ifca-ui/core'
// import { CustomFooter } from 'component/CustomFooter'
// import './unit.scss'
// import './unitSetup.scss'
// import PrintIcon from '@mui/icons-material/Print'
// import { ArrowDropDown, Visibility } from '@mui/icons-material'
// import { Unit2DView } from 'component/Table/Unit2DView'
// import ZoomInIcon from '@mui/icons-material/ZoomIn'
// import ZoomOutIcon from '@mui/icons-material/ZoomOut'

// const UnitListing = () => {
//   const { rootState, rootDispatch } =
//     React.useContext<AppContextProps>(AppContext)
//   const { navBack, navTo } = useNav()
//   const projectid = localStorage.getItem('lastestProject')

//   const [footerHeight, setFooterHeight] = useState(0)
//   const [subheaderHeight, setSubheaderHeight] = useState(0)
//   const [tableHeight, setTableHeight] = useState(300)
//   const [value, setData] = React.useState(0)
//   const [blockName, setBlockName] = useState('')
//   const [floorList, setFloorList] = useState<SalesChartFloorUnitOutput | null>(
//     null
//   )
//   const [floorDetail, setFloorDetail] = useState([] as any)
//   const [filterDetail, setFilterDetail] = useState([] as any)
//   const initialState = {
//     getBlock: false,
//     getFloorByBlock: false,
//     tempHeight: false,
//   }
//   function apiStateReducer(state: any, action: any) {
//     return {
//       ...state,
//       [action.field]: action.payload, // Dynamically update any field
//     }
//   }

//   const [apiState, dispatch] = useReducer(apiStateReducer, initialState)

//   const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
//     setData(newValue)
//   }

//   const {
//     refetch: refetchProjectQuery,
//     data: { getProjectById: projectName } = { getProjectbyId: null },
//   } = useProjectSummaryQuery({
//     onCompleted: data => {
//       localStorage.getItem('lastestProject')
//     },
//     onError: error => {
//       return error.graphQLErrors.map(({ message }) => {
//         rootDispatch({
//           type: 'snackBar',
//           payload: {
//             open: true,
//             message: message,
//           },
//         })
//       })
//     },
//   })

//   // Blocks listing
//   const { data: { GetSalesChartData: getData } = { GetSalesChartData: null } } =
//     useGetSalesChartDataQuery({
//       variables: {
//         projectId: projectid,
//       },
//     })

//   const [
//     getFloorByBlock,
//     {
//       data: { GetSalesChartByBlock: getBlock } = {
//         GetSalesChartByBlock: [],
//       },
//       refetch,
//     },
//   ] = useGetSalesChartByBlockLazyQuery({
//     onCompleted: data => {
//       setFilterDetail([])
//       const floorData = data.GetSalesChartByBlock as SalesChartFloorUnitOutput
//       setFloorList(floorData)
//       setFloorDetail([...floorData.floors])
//       dispatch({ field: 'getBlock', payload: true })
//     },
//   })

//   useEffect(() => {
//     rootDispatch({
//       type: 'headerComponent',
//       payload: {
//         ...rootState.headerComponent,
//         leftIcon: {
//           icon: 'back',
//           props: {
//             onClick: () => navBack(),
//           },
//         },
//         topSection: {
//           smTitle: TopSectionHeader,
//           title: `${projectName?.name}`,
//         },
//         bottomSection: {
//           breadcrumbs: {
//             current: 'Select Unit',
//             maxItems: 2,
//           },
//         },
//       },
//     })
//   }, [])

//   useEffect(() => {
//     if (getData?.distinctBlock?.length > 0) {
//       setBlockName(getData?.distinctBlock[value])
//     }
//   }, [value, getData?.distinctBlock])

//   useEffect(() => {
//     if (blockName) {
//       setFloorList([])
//       getFloorByBlock({
//         variables: {
//           projectId: projectid,
//           block: blockName,
//         },
//       })
//     } else {
//       dispatch({ field: 'getFloorByBlock', payload: true })
//     }
//   }, [blockName])

//   const theme = useTheme()
//   const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
//     defaultMatches: true,
//   })

//   useEffect(() => {
//     const headerHeight = rootState.headerHeight ?? 0

//     let tempHeight = footerHeight + headerHeight + subheaderHeight + 24
//     tempHeight += 64

//     setTableHeight(tempHeight)
//     dispatch({ field: 'tempHeight', payload: true })
//   }, [footerHeight, rootState.headerHeight, subheaderHeight, isDesktop])

//   console.log(apiState)

//   // Search Function

//   const [searchQuery, setSearchQuery] = useState('')

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchQuery(event.target.value)
//   }

//   const clearSearch = () => {
//     setSearchQuery('')
//   }

//   const handlePrint = () => {
//     window.print()
//   }

//   // Price range

//   const [minPrice, setMinPrice] = useState(0)
//   const [maxPrice, setMaxPrice] = useState(17000000)

//   const [anchorElPrice, setAnchorElPrice] = useState<null | HTMLElement>(null)
//   const [openPricePopover, setOpenPricePopover] = useState(false)

//   const [anchorElUnit, setAnchorElUnit] = useState<null | HTMLElement>(null)
//   const [openUnitPopover, setOpenUnitPopover] = useState(false)

//   const [selectAll, setSelectAll] = useState(false)

//   const openPrice = Boolean(anchorElPrice) // For price range popover
//   const openUnit = Boolean(anchorElUnit)

//   const handlePriceClick = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorElPrice(event.currentTarget) // Set anchor for Price Range
//     setOpenPricePopover(true) // Open Price Range Popover
//   }

//   const handleUnitClick = event => {
//     setAnchorElUnit(event.currentTarget)
//     setOpenUnitPopover(!openUnitPopover)
//   }

//   const handleClosePricePopover = () => {
//     setOpenPricePopover(false)
//   }

//   const handleCloseUnitPopover = () => {
//     setOpenUnitPopover(false)
//   }

//   const filterUnitsByPrice = () => {
//     if (floorList?.units) {
//       const filteredUnits = floorList.units.filter(unit => {
//         const unitPrice = unit.price

//         console.log('Unit Price:', unitPrice)

//         if (unitPrice == null) {
//           return false
//         }

//         return unitPrice >= minPrice && unitPrice <= maxPrice
//       })

//       setFilterDetail(filteredUnits)
//     }
//   }

//   const handleMinSliderChange = (event: Event, newValue: number | number[]) => {
//     setMinPrice(newValue as number)
//   }

//   const handleMaxSliderChange = (event: Event, newValue: number | number[]) => {
//     setMaxPrice(newValue as number)
//   }

//   const handleMinSliderChangeCommitted = () => {
//     filterUnitsByPrice()
//   }

//   const handleMaxSliderChangeCommitted = () => {
//     filterUnitsByPrice()
//   }

//   // Important

//   const insertFilter = (filter: string) => {
//     setFilterDetail((prevFilterDetail: any) => {
//       const updatedFilterDetail = [...prevFilterDetail]
//       const index = updatedFilterDetail.indexOf(filter)

//       if (index > -1) {
//         updatedFilterDetail.splice(index, 1)
//       } else {
//         updatedFilterDetail.push(filter)
//       }

//       return updatedFilterDetail
//     })
//   }

//   // const insertFilter = (filter: string) => {
//   //   const updatedFilterDetail = [...filterDetail]

//   //   if (filter === 'ALL_TYPES') {
//   //     // Toggle "All Types"
//   //     if (selectAll) {
//   //       // Deselect all unit types
//   //       setFilterDetail([])
//   //     } else {
//   //       // Select all unit types
//   //       const allUnitTypes =
//   //         floorList?.unitTypesByBlock?.map(unit => unit.name) ?? []
//   //       setFilterDetail(allUnitTypes)
//   //     }
//   //     setSelectAll(!selectAll) // Toggle the "All Types" checkbox state
//   //   } else {
//   //     // Handle individual unit type checkboxes
//   //     const index = updatedFilterDetail.indexOf(filter)
//   //     if (index > -1) {
//   //       updatedFilterDetail.splice(index, 1) // Remove unit type from filter
//   //     } else {
//   //       updatedFilterDetail.push(filter) // Add unit type to filter
//   //     }

//   //     // Automatically select "All Types" if all unit types are selected
//   //     const allUnitTypes =
//   //       floorList?.unitTypesByBlock?.map(unit => unit.name) ?? []
//   //     if (updatedFilterDetail.length === allUnitTypes.length) {
//   //       setSelectAll(true) // All unit types are selected, so "All Types" is checked
//   //     } else {
//   //       setSelectAll(false) // Not all unit types are selected
//   //     }

//   //     setFilterDetail(updatedFilterDetail) // Update filter state
//   //   }
//   // }

//   const filteredUnits =
//     floorList?.units?.filter(unit => {
//       const matchesSearchQuery = unit.unit_no
//         .toLowerCase()
//         .includes(searchQuery.toLowerCase())
//       const matchesPriceRange = unit.price >= minPrice && unit.price <= maxPrice

//       return matchesSearchQuery && matchesPriceRange
//     }) ?? []

//   // const filteredUnits =
//   //   floorList?.units?.filter(unit => {
//   //     const matchesSearchQuery = unit.unit_no
//   //       .toLowerCase()
//   //       .includes(searchQuery.toLowerCase())
//   //     const matchesPriceRange = unit.price >= minPrice && unit.price <= maxPrice

//   //     // If filterDetail is empty, show all units (no unit type filtering)
//   //     const matchesUnitType =
//   //       filterDetail.length === 0 || filterDetail.includes(unit.unit_type)

//   //     return matchesSearchQuery && matchesPriceRange && matchesUnitType
//   //   }) ?? []

//   // useEffect(() => {
//   //   if (selectAll && floorList?.unitTypesByBlock) {
//   //     const allUnitTypes = floorList?.unitTypesByBlock?.map(unit => unit.name)
//   //     setFilterDetail(allUnitTypes)
//   //   }
//   // }, [selectAll, floorList?.unitTypesByBlock, setFilterDetail])

//   const [zoomLevel, setZoomLevel] = useState(1) // Default zoom level (no zoom)
//   const [isZoomingIn, setIsZoomingIn] = useState(true) // Track zoom direction

//   const containerRef = useRef<HTMLDivElement | null>(null)

//   // Handle Mouse Wheel Event to Zoom In/Out
//   const handleWheel = (event: WheelEvent) => {
//     event.preventDefault() // Prevent default scroll behavior (page scroll)

//     const zoomDirection = event.deltaY < 0 ? 'in' : 'out' // Zoom in if wheel scrolls up, out if down

//     if (zoomDirection === 'in' && zoomLevel < 3) {
//       setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 3)) // Max zoom level of 3
//       setIsZoomingIn(true)
//     } else if (zoomDirection === 'out' && zoomLevel > 1) {
//       setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 1)) // Min zoom level of 1
//       setIsZoomingIn(false)
//     }
//   }

//   useEffect(() => {
//     if (containerRef.current) {
//       // Attach the mouse wheel event listener to the container
//       containerRef.current.addEventListener('wheel', handleWheel, {
//         passive: false,
//       })
//     }

//     // Cleanup the event listener when component unmounts
//     return () => {
//       if (containerRef.current) {
//         containerRef.current.removeEventListener('wheel', handleWheel)
//       }
//     }
//   }, [zoomLevel])

//   return (
//     <>
//       {apiState.getBlock && apiState.getFloorByBlock && apiState.tempHeight ? (
//         <>
//           <SubContainerX
//             heightOnChange={height => {
//               setSubheaderHeight(height)
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 padding: '5px',
//               }}
//             >
//               <FormControl
//                 variant="outlined"
//                 sx={{
//                   width: '49.8%',
//                   backgroundColor: 'white',
//                   marginTop: '5px',
//                 }}
//                 onClick={handlePriceClick}
//               >
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     alignItems: 'center',
//                     padding: '8px',
//                     cursor: 'pointer',
//                   }}
//                 >
//                   <Typography variant="body2" color="textSecondary">
//                     Price Range
//                   </Typography>
//                   <ArrowDropDown />
//                 </Box>
//               </FormControl>

//               <Popover
//                 open={openPricePopover}
//                 anchorEl={anchorElPrice}
//                 onClose={handleClosePricePopover}
//                 anchorOrigin={{
//                   vertical: 'bottom',
//                   horizontal: 'left',
//                 }}
//                 transformOrigin={{
//                   vertical: 'top',
//                   horizontal: 'left',
//                 }}
//                 sx={{ padding: '15px' }}
//               >
//                 <Box
//                   sx={{
//                     width: '250px',
//                     padding: '10px',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'space-between',
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       justifyContent: 'space-between',
//                       alignItems: 'center',
//                     }}
//                   >
//                     <Typography
//                       variant="body2"
//                       fontWeight="bold"
//                       display="inline"
//                     >
//                       Min
//                     </Typography>
//                     <Typography
//                       variant="body2"
//                       display="inline"
//                       sx={{ marginLeft: '5px' }}
//                     >
//                       MYR {minPrice.toLocaleString()}
//                     </Typography>
//                   </Box>
//                   <Slider
//                     value={minPrice}
//                     onChange={handleMinSliderChange}
//                     onChangeCommitted={handleMinSliderChangeCommitted}
//                     min={0}
//                     max={17000000}
//                     step={10000}
//                     valueLabelDisplay="auto"
//                     valueLabelFormat={value => `MYR ${value.toLocaleString()}`}
//                     sx={{
//                       width: 'calc(100% - 40px)',
//                       marginLeft: '20px',
//                       marginRight: '20px',
//                     }}
//                   />
//                   <Divider sx={{ margin: '10px 0', flexGrow: 1 }} />
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       justifyContent: 'space-between',
//                       alignItems: 'center',
//                     }}
//                   >
//                     <Typography
//                       variant="body2"
//                       fontWeight="bold"
//                       display="inline"
//                     >
//                       Max
//                     </Typography>
//                     <Typography
//                       variant="body2"
//                       display="inline"
//                       sx={{ marginLeft: '5px' }}
//                     >
//                       MYR {maxPrice.toLocaleString()}
//                     </Typography>
//                   </Box>
//                   <Slider
//                     value={maxPrice}
//                     onChange={handleMaxSliderChange}
//                     onChangeCommitted={handleMaxSliderChangeCommitted}
//                     min={0}
//                     max={17000000}
//                     step={10000}
//                     valueLabelDisplay="auto"
//                     valueLabelFormat={value => `MYR ${value.toLocaleString()}`}
//                     sx={{
//                       width: 'calc(100% - 40px)',
//                       marginLeft: '20px',
//                       marginRight: '20px',
//                     }}
//                   />
//                 </Box>
//               </Popover>

//               <FormControl
//                 variant="outlined"
//                 sx={{
//                   width: '49.8%',
//                   backgroundColor: 'white',
//                   marginTop: '5px',
//                 }}
//                 onClick={handleUnitClick}
//               >
//                 <Box
//                   sx={{
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     alignItems: 'center',
//                     padding: '8px',
//                     cursor: 'pointer',
//                   }}
//                 >
//                   <Typography variant="body2" color="textSecondary">
//                     Unit Type
//                   </Typography>
//                   <ArrowDropDown />
//                 </Box>
//               </FormControl>

//               <Popover
//                 open={openUnitPopover}
//                 anchorEl={anchorElUnit}
//                 onClose={handleCloseUnitPopover}
//                 anchorOrigin={{
//                   vertical: 'bottom',
//                   horizontal: 'left',
//                 }}
//                 transformOrigin={{
//                   vertical: 'top',
//                   horizontal: 'left',
//                 }}
//                 sx={{
//                   padding: '15px',
//                   maxHeight: '300px',
//                   overflowY: 'auto',
//                 }}
//               >
//                 <Box sx={{ maxWidth: '280px', padding: '16px' }}>
//                   <Box
//                     sx={{
//                       display: 'grid',
//                       gridTemplateColumns: '1fr 1fr',
//                       gap: '10px',
//                       gridTemplateRows: 'auto',
//                     }}
//                   >
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={selectAll}
//                           onChange={() => insertFilter('ALL_TYPES')}
//                         />
//                       }
//                       label="All Types"
//                     />
//                     {floorList?.unitTypesByBlock?.map((unit, index) => (
//                       <FormControlLabel
//                         key={index}
//                         control={
//                           <Checkbox
//                             checked={filterDetail.includes(unit.name)}
//                             onChange={() => insertFilter(unit.name)}
//                           />
//                         }
//                         label={unit.name}
//                       />
//                     ))}
//                   </Box>
//                 </Box>
//               </Popover>
//             </Box>

//             <Box
//               display="flex"
//               justifyContent="space-between"
//               alignItems="center"
//               sx={{ padding: '5px' }}
//             >
//               <SearchBar
//                 title={
//                   <Typography
//                     component="span"
//                     variant="inherit"
//                     className="search-bar-count-f"
//                     sx={{ fontSize: '0.875rem' }}
//                   >{`Search Here ... `}</Typography>
//                 }
//                 searchInput={{
//                   value: searchQuery,
//                   onChangeClearInput: () => {
//                     clearSearch()
//                   },
//                   onChange: handleSearchChange,
//                 }}
//               />
//               <IconButton
//                 onClick={handlePrint}
//                 color="primary"
//                 size="small"
//                 sx={{
//                   backgroundColor: 'white',
//                   borderRadius: '50%',
//                   boxShadow: 1,
//                   '&:hover': {
//                     backgroundColor: '#f0f0f0',
//                   },
//                   marginLeft: '5px',
//                   padding: '6px',
//                 }}
//               >
//                 <PrintIcon />
//               </IconButton>
//             </Box>
//             <Typography component="div" className="custom-tab">
//               {floorDetail?.length === 0 ? (
//                 <EmptyState title={'No Records'} />
//               ) : (
//                 <Tabs value={value} onChange={handleTabsChange}>
//                   {getData?.distinctBlock?.map((x: any, index: any) => {
//                     return (
//                       <Tab
//                         className="custom-tab-label"
//                         label={x}
//                         value={index}
//                         key={index}
//                       />
//                     )
//                   })}
//                 </Tabs>
//               )}
//             </Typography>
//           </SubContainerX>

//           {floorDetail?.length > 0 ? (
//             <Typography component="div">
//               {filteredUnits?.length > 0 ? (
//                 <div
//                   ref={containerRef} // Attach ref here
//                   style={{
//                     transform: `scale(${zoomLevel})`,
//                     transformOrigin: 'top left',
//                     transition: 'transform 0.3s ease', // Smooth transition for zoom
//                     overflow: 'hidden', // Prevent content overflow when zooming
//                   }}
//                 >
//                   <Unit2DView
//                     headerList={floorList?.floors || []}
//                     floorList={filteredUnits}
//                     filterList={filterDetail}
//                     tableHeight={tableHeight}
//                   />
//                 </div>
//               ) : (
//                 <EmptyState title="No units match the search" />
//               )}
//             </Typography>
//           ) : null}

//           {/* Floating Zoom In/Out Icon */}
//           <div
//             style={{
//               position: 'absolute',
//               top: '75%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               backgroundColor: 'rgba(0, 0, 0, 0.2)', // Greyish background with transparency
//               borderRadius: '50%',
//               boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
//               padding: '10px',
//               cursor: 'pointer',
//               zIndex: 1000,
//               opacity: 0.7, // Slight transparency for the whole icon (background + icon)
//             }}
//           >
//             <IconButton
//               onClick={() => {
//                 // Toggle the zoom direction based on the current state
//                 if (isZoomingIn) {
//                   setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 3))
//                 } else {
//                   setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 1))
//                 }
//               }}
//               style={{
//                 fontSize: '30px',
//               }}
//             >
//               {isZoomingIn ? <ZoomInIcon /> : <ZoomOutIcon />}
//             </IconButton>
//           </div>

//           <CustomFooter
//             heightOnChange={height => {
//               setFooterHeight(height)
//             }}
//             text={[
//               {
//                 line: [
//                   {
//                     label: (
//                       <Box
//                         display="flex"
//                         alignItems="center"
//                         justifyContent="flex-start"
//                         flexWrap="wrap"
//                         gap={1}
//                         marginRight="300px"
//                       >
//                         <FormControlLabel
//                           control={<Checkbox className="available-unit" />}
//                           label={`Available(${
//                             floorList?.unitCount?.available || 0
//                           })`}
//                           checked={!filterDetail.includes('AVAILABLE')}
//                           className="custom-checkbox"
//                           onChange={e => {
//                             e.stopPropagation()
//                             insertFilter('AVAILABLE')
//                           }}
//                         />
//                         <FormControlLabel
//                           control={<Checkbox className="sold-unit" />}
//                           label={`Sold(${floorList?.unitCount?.booked || 0})`}
//                           checked={!filterDetail.includes('BOOK')}
//                           className="custom-checkbox"
//                           onChange={e => {
//                             e.stopPropagation()
//                             insertFilter('BOOK')
//                           }}
//                         />
//                         <FormControlLabel
//                           control={<Checkbox className="sign-unit" />}
//                           label={`SPA Signed(${
//                             (floorList?.unitCount?.signed ?? 0) +
//                             (floorList?.unitCount?.unAvailable ?? 0)
//                           })`}
//                           checked={
//                             !filterDetail.includes('SIGN') &&
//                             !filterDetail.includes('INACTIVE')
//                           }
//                           className="custom-checkbox"
//                           onChange={e => {
//                             e.stopPropagation()

//                             // If 'SIGN' or 'INACTIVE' filters are present, remove both.

//                             insertFilter('INACTIVE') // Remove 'INACTIVE' filter
//                             insertFilter('SIGN') // Remove 'SIGN' filter
//                           }}
//                         />
//                       </Box>
//                     ),
//                   },
//                   {
//                     label: (
//                       <Box
//                         display="flex"
//                         alignItems="center"
//                         justifyContent="flex-end"
//                         flexWrap="wrap"
//                         gap={5}
//                         marginLeft="300px"
//                       >
//                         <Typography
//                           component="div"
//                           className="status-cookies-label"
//                         >
//                           <Typography className="text-icon">
//                             <PxLetterB />
//                           </Typography>
//                           <Typography
//                             className="text-desc"
//                             color="common.black"
//                           >
//                             Bumi Unit
//                           </Typography>
//                         </Typography>
//                         <Typography
//                           component="div"
//                           className="status-cookies-label"
//                         >
//                           <Typography className="text-icon">
//                             <Visibility />
//                           </Typography>
//                           <Typography
//                             className="text-desc"
//                             color="common.black"
//                           >
//                             In-View
//                           </Typography>
//                         </Typography>
//                       </Box>
//                     ),
//                   },
//                 ],
//               },
//             ]}
//           />
//         </>
//       ) : (
//         <></>
//       )}
//     </>
//   )
// }

// export default UnitListing

import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import {
  ArrowDropDown,
  NavigateBeforeRounded,
  Visibility,
  VisibilityRounded,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import {
  Slider,
  Checkbox,
  CircularProgress,
  useMediaQuery,
  Button,
  Typography,
  Box,
  FormControl,
  IconButton,
  Badge,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Table,
  Paper,
} from '@mui/material'
import {
  CommonStatus,
  SalesChart3DUnitOutput,
  SalesChartUnitOutput,
  useGet3dStatusLazyQuery,
  useGetBlockUnitsLazyQuery,
  useGetSalesChartByBlockLazyQuery,
  useGetSalesChartDataLazyQuery,
  useGetUnitDetailLazyQuery,
  useProjectSummaryQuery,
} from 'generated/graphql'
import { PxLetterB } from '@ifca-ui/icons/dist/index.esm'
// import BumiIcon from '../../../../assets/images/icon/letter-b.svg'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import './unitSetup.scss'
import { AUTH_PATH } from '../Authentication/Routes'
import {
  Card,
  ColumnFlex,
  Container,
  Dialog,
  EmptyState,
  RowFlex,
  RowGrid,
  SubContainerX,
  Subheader,
  SubheaderText,
  Tab,
  Tabs,
} from '@ifca-ui/core'
import { abbreviateNumber } from 'containers/helper/AbbreviateNumber'
import { StackItem, StackX } from 'component/Layout/LayoutX/StackLayout'
import TextX from 'component/TextField/TextX'
import PrintIcon from '@mui/icons-material/Print'
import './unit.scss'
import './unitSetup.scss'
import { CustomFooter } from 'component/CustomFooter'
import { SalesChart3D } from './SalesChart3D'
import AppContext, { AppContextProps } from 'containers/context/Context'
import React from 'react'
import useNav from 'component/RouteService/useNav'
import { TopSectionHeader } from 'component/HeaderSection'

enum UnitStatus {
  Available = 'AVAILABLE',
  Reserved_Sold = 'BOOK',
  SPA_Signed = 'SIGN',
  Available_Bumi = 'AVAILABLE BUMI',
  Reserved_Sold_Bumi = 'BOOK BUMI',
  SPA_Signed_Bumi = 'SIGN BUMI',

  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

type UnitFilterType = {
  available: CommonStatus.Active | null
  sold: UnitStatus.Reserved_Sold | null
  inactiveSigned: boolean
}

const UnitListing = () => {
  const projectid = localStorage.getItem('lastestProject')
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 768px)')

  const {
    refetch: refetchProjectQuery,
    data: { getProjectById: projectName } = { getProjectbyId: null },
  } = useProjectSummaryQuery({
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const [getSalesChartData, { data: salesChartData }] =
    useGetSalesChartDataLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setMinPrice(Number(data?.GetSalesChartData?.unitMinPrice))
        setMaxPrice(Number(data?.GetSalesChartData?.unitMaxPrice))
        setActiveBlock(0)

        if (data?.GetSalesChartData.distinctBlock.length < 1) {
          return
        } else {
          get3dStatus({
            fetchPolicy: 'no-cache',
            variables: {
              projectId: projectid,
            },
          })
        }
      },
    })

  const [getSalesChartByBlock, { data: salesChartByBlockData }] =
    useGetSalesChartByBlockLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        const allTypeArray: string[] =
          data?.GetSalesChartByBlock?.unitTypesByBlock.map(unit => unit.id)
        setUnitTypeFilter(allTypeArray)

        if (
          data.GetSalesChartByBlock.units.length === 0 ||
          !data.GetSalesChartByBlock.units
        ) {
          //   openSnackbar('Unit List is empty!')
        }
      },
    })

  const [getBlockUnits, { data: blockUnitsData }] = useGetBlockUnitsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const allTypeArray: string[] = data?.GetBlockUnits.unitTypesByBlock.map(
        unit => unit.id
      )
      setUnitTypeFilter(allTypeArray)

      if (
        data?.GetBlockUnits.salesChartUnitDisplay?.length === 0 ||
        !data?.GetBlockUnits.salesChartUnitDisplay
      ) {
      }
    },
  })

  const [get3dStatus, { data: threeDStatus }] = useGet3dStatusLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.getProjectChartMapped === true) {
        getBlockUnits({
          variables: {
            projectId: projectid,
            keySearch: null,
            block: salesChartData?.GetSalesChartData.saleschartBlock[0] || '',
          },
        })
      } else {
        getSalesChartByBlock({
          fetchPolicy: 'no-cache',
          variables: {
            projectId: projectid,
            block: salesChartData?.GetSalesChartData.distinctBlock[0] || '',
            keySearch: null,
          },
        })
      }
    },
  })

  const [getUnitDetail, { data: unitDetailData = null }] =
    useGetUnitDetailLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data?.GetUnitDetail) {
          setOpenBottomSheet(true)
        }
      },
    })

  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const { navBack, navTo } = useNav()

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name}`,
        },
        bottomSection: {
          breadcrumbs: {
            current: 'Select Unit',
            maxItems: 2,
          },
        },
      },
    })
  }, [])

  const [minPrice, setMinPrice] = useState<number>(0)
  const [maxPrice, setMaxPrice] = useState<number>(0)
  const [openPriceRange, setOpenPriceRange] = useState(false)
  const [openUnitType, setOpenUnitType] = useState(false)

  const [unitTypeFilter, setUnitTypeFilter] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [activeBlock, setActiveBlock] = useState<number>()
  const [unitFilter, setUnitFilter] = useState<UnitFilterType>({
    available: CommonStatus.Active,
    sold: UnitStatus.Reserved_Sold,
    inactiveSigned: true,
  })
  const AVAIL_COLOR = '#21c986'
  const UNAVAIL_COLOR = '#c5c5c5'
  const [openBottomSheet, setOpenBottomSheet] = useState(false)

  useEffect(() => {
    if (!projectid) {
      navigate(`${AUTH_PATH.NOT_FOUND}`)
    } else {
      getSalesChartData({
        variables: {
          projectId: projectid,
          phase: '',
        },
      })
    }
  }, [projectid])

  const handleMinPrice = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setMinPrice(newValue)

      if (newValue > maxPrice) {
        setMaxPrice(newValue)
      }
    }
  }

  const handleMaxPrice = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setMaxPrice(newValue)

      if (newValue < minPrice) {
        setMinPrice(newValue)
      }
    }
  }

  const handleUnitTypeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, checked } = event.target

    if (id === 'allType' && checked) {
      if (blockUnitsData?.GetBlockUnits) {
        const allTypeArray: string[] =
          blockUnitsData?.GetBlockUnits.unitTypesByBlock.map(
            unitType => unitType.id
          ) || []
        setUnitTypeFilter([...allTypeArray])
      } else {
        const allTypeArray: string[] =
          salesChartByBlockData?.GetSalesChartByBlock.unitTypesByBlock.map(
            unitType => unitType.id
          ) || []
        setUnitTypeFilter([...allTypeArray])
      }
      return
    }

    if (id === 'allType' && !checked) {
      setUnitTypeFilter([])
      return
    }

    if (checked) {
      setUnitTypeFilter(prev => {
        if (prev.includes(id)) return prev
        return [...prev, id]
      })
    } else {
      setUnitTypeFilter(prev => {
        return prev.filter(item => item !== id)
      })
    }
  }

  let timer: NodeJS.Timeout
  const handleSearchValue = (event: any) => {
    const value = event.target.value
    setSearchValue(value)

    //if user keep typing, clear the timer for prevent send search request to backend
    clearTimeout(timer)
  }

  useEffect(() => {
    if (
      !threeDStatus?.getProjectChartMapped &&
      !salesChartByBlockData?.GetSalesChartByBlock
    )
      return

    if (threeDStatus?.getProjectChartMapped && !blockUnitsData?.GetBlockUnits)
      return
    //if user stop typing for 800ms, send search request to backend
    timer = setTimeout(() => {
      if (threeDStatus?.getProjectChartMapped === true) {
        getBlockUnits({
          variables: {
            projectId: projectid,
            keySearch: searchValue,
            block:
              salesChartData?.GetSalesChartData.saleschartBlock[activeBlock],
          },
        })
      } else {
        getSalesChartByBlock({
          variables: {
            projectId: projectid,
            block: salesChartData?.GetSalesChartData.distinctBlock[activeBlock],
            keySearch: searchValue,
          },
        })
      }
    }, 500)
  }, [searchValue])

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target

    if (id === 'available') {
      setUnitFilter(prev => {
        return {
          ...prev,
          available: checked ? CommonStatus.Active : null,
        }
      })
    }

    if (id === 'sold-unit') {
      setUnitFilter(prev => ({
        ...prev,
        sold: checked ? UnitStatus.Reserved_Sold : null,
      }))
    }

    if (id === 'inactive-signed') {
      setUnitFilter(prev => ({
        ...prev,
        inactiveSigned: checked,
      }))
    }
  }

  const handleChangeBlockTab = (index: number) => {
    setActiveBlock(index)

    if (threeDStatus?.getProjectChartMapped === true) {
      getBlockUnits({
        variables: {
          projectId: projectid,
          keySearch: searchValue,
          block: salesChartData?.GetSalesChartData.saleschartBlock[index],
        },
      })
    } else {
      getSalesChartByBlock({
        variables: {
          projectId: projectid,
          block: salesChartData?.GetSalesChartData.distinctBlock[index],
          keySearch: searchValue,
        },
      })
    }
  }

  const isAvailableUnit = (unit: SalesChartUnitOutput): boolean => {
    return (
      unit.unit_status === UnitStatus.Available &&
      unit.common_status === CommonStatus.Active &&
      isValidPriceRange(unit) &&
      unitTypeFilter.includes(unit.unit_type)
    )
  }

  const isSoldUnit = (unit: SalesChartUnitOutput): boolean => {
    return (
      unit.unit_status === UnitStatus.Reserved_Sold &&
      isValidPriceRange(unit) &&
      unitTypeFilter.includes(unit.unit_type)
    )
  }

  const isInactiveOrSignUnit = (unit: SalesChartUnitOutput): boolean => {
    return (
      (unit.common_status === CommonStatus.Inactive ||
        unit.unit_status === UnitStatus.SPA_Signed) &&
      isValidPriceRange(unit) &&
      unitTypeFilter.includes(unit.unit_type)
    )
  }

  const isValidPriceRange = (unit: SalesChartUnitOutput): boolean => {
    return unit.price >= minPrice && unit.price <= maxPrice
  }

  const handleUnitFilter = (unit: SalesChartUnitOutput) => {
    // available
    if (unitFilter.available && isAvailableUnit(unit)) return true

    // sold
    if (unitFilter.sold && isSoldUnit(unit)) return true

    // inactive or signed
    if (unitFilter.inactiveSigned && isInactiveOrSignUnit(unit)) return true

    return false
  }

  const handleOpenBottomSheet_for2DChart = (unit: SalesChartUnitOutput) => {
    if (threeDStatus?.getProjectChartMapped === true) return

    if (
      unit.unit_status === UnitStatus.SPA_Signed ||
      unit.unit_status === UnitStatus.Inactive ||
      unit.common_status === CommonStatus.Inactive
    ) {
      return
    }

    getUnitDetail({
      variables: {
        unitId: unit.unit_id,
      },
    })
  }

  useEffect(() => {
    const element = document.getElementsByTagName('body')[0]
    if (openBottomSheet && unitDetailData) {
      element.classList.add('disable-overflow')
    } else {
      element.classList.remove('disable-overflow')
    }
  }, [openBottomSheet, unitDetailData])

  const handlePrint = () => {
    window.print()
  }

  const [dialog, setDialog] = useState(false)

  const unitDialog = (
    <Dialog
      fullWidth={true}
      open={dialog}
      onClose={() => setDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {projectName?.name}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <Card
            header={{
              title: (
                <SubheaderText
                  primary={
                    <Box className="sub-header-box-container">
                      <Typography
                        className="text-xsTitle"
                        color="#FF9800"
                        component="span"
                        flex="1 1"
                        variant="inherit"
                      >
                        Unit Detail
                      </Typography>
                    </Box>
                  }
                />
              ),
            }}
            className="card-container-detail custom-overflow-card"
          >
            <Box className="left">
              <Typography className="label-f">Unit</Typography>
              <Typography className="value-f">
                {unitDetailData?.GetUnitDetail?.unitNo ?? '-'}
              </Typography>
            </Box>
            <Box className="right">
              <Typography className="label-f">Unit Price</Typography>
              <Typography className="value-f">
                {unitDetailData?.GetUnitDetail?.price
                  ? `MYR ${new Intl.NumberFormat().format(getUnitDetail.price)}`
                  : '-'}
              </Typography>
            </Box>
            <Box className="left">
              <Typography className="label-f">Type</Typography>
              <Typography className="value-f">
                {unitDetailData?.GetUnitDetail?.unitType?.name ?? '-'}
              </Typography>
            </Box>
            <Box className="right">
              <Typography className="label-f">Built-up</Typography>
              <Typography className="value-f">
                {unitDetailData?.GetUnitDetail?.builtUp ?? '-'}
              </Typography>
            </Box>
            <Box className="left">
              <Typography className="label-f">Direction</Typography>
              <Typography className="value-f">
                {unitDetailData?.GetUnitDetail?.facingDirection?.name ?? '-'}
              </Typography>
            </Box>
            <Box className="right">
              <Typography className="label-f">
                Unit Layout DescriptionSend
              </Typography>
              <Typography className="value-f">
                {unitDetailData?.GetUnitDetail?.unitLayoutDes ?? '-'}
              </Typography>
            </Box>
            <Box className="left">
              <Typography className="label-f">Land Area</Typography>
              <Typography className="value-f">
                {unitDetailData?.GetUnitDetail?.landArea === '0.00'
                  ? '-'
                  : unitDetailData?.GetUnitDetail?.landArea ?? '-'}
              </Typography>
            </Box>
          </Card>
        </>
      }
      footer={{
        buttons: [
          {
            children: 'Book Now',
            onClick: () => setDialog(false),
          },
        ],
      }}
    />
  )

  return (
    <>
      {unitDialog}
      <Box
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          backgroundColor: 'white',
        }}
      >
        <RowGrid gap="30px" gridTemplateColumns="1fr 1fr">
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',
            }}
          >
            <FormControl
              variant="outlined"
              sx={{
                width: '1000%',
                backgroundColor: 'white',
                marginTop: '5px',
              }}
              onClick={() => setOpenPriceRange(true)}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px',
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  Price Range
                </Typography>
                <ArrowDropDown />
              </Box>
            </FormControl>
            {openPriceRange && (
              <Card
                style={{
                  position: 'absolute',
                  zIndex: 3,
                  transform: 'translateY(10px)',
                }}
              >
                <Container padding={{ all: '14px' }}>
                  <ColumnFlex gap="14px">
                    <ColumnFlex>
                      <span className="fw-800 fs-16 color-text">
                        Min{' '}
                        <span className="fw-400 fs-16 color-text-2">
                          {abbreviateNumber(minPrice)}
                        </span>
                      </span>
                      <Slider
                        value={minPrice}
                        size="small"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={Number(
                          salesChartData?.GetSalesChartData.unitMinPrice
                        )}
                        max={maxPrice}
                        onChange={handleMinPrice}
                      />
                    </ColumnFlex>
                    <div className="divider-bottom-border"></div>
                    <ColumnFlex className="divider-border-bottom">
                      <span className="fw-800 fs-16 color-text">
                        Max
                        <span className="fw-400 fs-16 color-text-2">
                          {abbreviateNumber(maxPrice)}
                        </span>
                      </span>
                      <Slider
                        value={maxPrice}
                        size="small"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={Number(
                          salesChartData?.GetSalesChartData.unitMinPrice
                        )}
                        max={Number(
                          salesChartData?.GetSalesChartData.unitMaxPrice
                        )}
                        onChange={handleMaxPrice}
                      />
                    </ColumnFlex>
                  </ColumnFlex>
                </Container>
              </Card>
            )}
          </Box>
          <Container>
            <FormControl
              variant="outlined"
              sx={{
                width: '102%',
                backgroundColor: 'white',
                marginTop: '2px',
                marginLeft: '-15px',
              }}
              onClick={() => setOpenUnitType(true)}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px',
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  Unit Type
                </Typography>
                <ArrowDropDown />
              </Box>
            </FormControl>
            {openUnitType && (
              <Card
                style={{
                  position: 'absolute',
                  zIndex: 3,
                  transform: isMobile
                    ? 'translate(-10px, 10px)'
                    : 'translateY(10px)',
                }}
              >
                <Container padding={{ all: '14px' }}>
                  <ColumnFlex gap="14px" crossAxisAlignment="flex-start">
                    <RowFlex crossAxisAlignment="center">
                      <Checkbox
                        id={'allType'}
                        checked={
                          threeDStatus?.getProjectChartMapped
                            ? blockUnitsData?.GetBlockUnits?.unitTypesByBlock.every(
                                unitType => unitTypeFilter.includes(unitType.id)
                              )
                            : salesChartByBlockData?.GetSalesChartByBlock?.unitTypesByBlock.every(
                                unitType => unitTypeFilter.includes(unitType.id)
                              )
                        }
                        onChange={handleUnitTypeCheckbox}
                      />
                      <span className="color-text">All Type</span>
                    </RowFlex>
                    {blockUnitsData?.GetBlockUnits?.unitTypesByBlock &&
                      blockUnitsData?.GetBlockUnits.unitTypesByBlock.map(
                        unitType => (
                          <RowFlex
                            crossAxisAlignment="center"
                            key={unitType.id}
                          >
                            <Checkbox
                              id={unitType.id}
                              checked={unitTypeFilter.includes(unitType.id)}
                              onChange={handleUnitTypeCheckbox}
                            />
                            <span>{`${unitType.name} (${unitType.builtUp} sq.ft)`}</span>
                          </RowFlex>
                        )
                      )}
                    {salesChartByBlockData &&
                      salesChartByBlockData?.GetSalesChartByBlock.unitTypesByBlock.map(
                        unitType => (
                          <RowFlex
                            crossAxisAlignment="center"
                            key={`${unitType.id}`}
                          >
                            <Checkbox
                              id={unitType.id}
                              checked={unitTypeFilter.includes(unitType.id)}
                              onChange={handleUnitTypeCheckbox}
                            />
                            <span>{`${unitType.name} (${unitType.builtUp} sq.ft)`}</span>
                          </RowFlex>
                        )
                      )}
                  </ColumnFlex>
                </Container>
              </Card>
            )}
          </Container>
        </RowGrid>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '5px' }}
        >
          <input
            type="text"
            placeholder="Search here..."
            value={searchValue}
            onChange={handleSearchValue}
            style={{
              width: '96.5%',
              padding: '12px 16px',
              fontSize: '16px',
              marginBottom: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
            className="fullwidth"
          />
          <IconButton
            onClick={handlePrint}
            color="primary"
            size="small"
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              boxShadow: 1,
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
              padding: '6px',
              marginBottom: '15px',
            }}
          >
            <PrintIcon />
          </IconButton>
        </Box>

        {!(threeDStatus?.getProjectChartMapped === true
          ? salesChartData?.GetSalesChartData?.saleschartBlock.length > 0
          : salesChartData?.GetSalesChartData?.distinctBlock?.length > 0) &&
          !blockUnitsData?.GetBlockUnits?.salesChartUnitDisplay?.length &&
          !salesChartByBlockData?.GetSalesChartByBlock?.floors?.length && (
            <EmptyState />
          )}

        <Typography component="div" className="custom-tab">
          {(threeDStatus?.getProjectChartMapped === true
            ? salesChartData?.GetSalesChartData?.saleschartBlock.length > 0
            : salesChartData?.GetSalesChartData?.distinctBlock?.length > 0) && (
            <Tabs value={activeBlock}>
              {threeDStatus?.getProjectChartMapped === true
                ? salesChartData?.GetSalesChartData?.distinctBlock.map(
                    (block, index) => {
                      return (
                        <Tab
                          className="custom-tab-label"
                          style={{ flex: 1 }}
                          label={block}
                          key={block}
                          onClick={() => {
                            handleChangeBlockTab(index)
                          }}
                        />
                      )
                    }
                  )
                : salesChartData?.GetSalesChartData?.distinctBlock.map(
                    (block, index) => {
                      return (
                        <Tab
                          className="custom-tab-label"
                          style={{ flex: 1 }}
                          label={block}
                          key={block}
                          onClick={() => {
                            handleChangeBlockTab(index)
                          }}
                        />
                      )
                    }
                  )}
            </Tabs>
          )}
        </Typography>
      </Box>

      <Typography component="div">
        <div>
          {threeDStatus?.getProjectChartMapped === true &&
            !blockUnitsData?.GetBlockUnits.salesChartUnitDisplay?.length && (
              <EmptyState />
            )}

          {threeDStatus?.getProjectChartMapped === false &&
            !salesChartByBlockData?.GetSalesChartByBlock?.floors?.length && (
              <EmptyState />
            )}

          {/* 3D booking unit */}
          {blockUnitsData?.GetBlockUnits.salesChartUnitDisplay?.length > 0 &&
            threeDStatus?.getProjectChartMapped === true &&
            blockUnitsData?.GetBlockUnits.salesChartUnitDisplay && (
              <Container height={'400px'} width={'100%'} className="cont-chart">
                <SalesChart3D
                  // filterWatch={filterWatch}
                  // checked={checked}
                  // available={availableFilter}
                  imgUrl={blockUnitsData?.GetBlockUnits?.pictureBlockUrl}
                  blockData={blockUnitsData?.GetBlockUnits.salesChartUnitDisplay.filter(
                    block => {
                      return (
                        unitTypeFilter.includes(block.unit_type_id) &&
                        block.price >= minPrice &&
                        block.price <= maxPrice
                      )
                    }
                  )}
                  // blockData={GetBlockUnits?.salesChartUnitDisplay}
                  getUnitDetail={getUnitDetail}
                  // setSelected={setSelected}
                  AVAIL_COLOR={AVAIL_COLOR}
                  UNAVAIL_COLOR={UNAVAIL_COLOR}
                  // setOpenBottomSheet={setOpenBottomSheet}
                  contW={
                    document.getElementsByClassName('cont-chart')[0]
                      ?.clientWidth
                  }
                />
              </Container>
            )}

          {/* 2D booking unit */}
          {salesChartByBlockData?.GetSalesChartByBlock?.floors?.length > 0 &&
            salesChartByBlockData?.GetSalesChartByBlock?.floors && (
              <TableContainer
                component={Paper}
                className="custom-table-container"
              >
                <Typography
                  component="div"
                  className="table-height"
                  style={{
                    height: `calc(100vh - 100px)`,
                    overflow: 'auto',
                  }}
                >
                  <Table className="custom-table">
                    {salesChartByBlockData?.GetSalesChartByBlock.floors.map(
                      floor => (
                        <TableBody key={floor.floor_label}>
                          <TableRow>
                            <TableCell
                              className="sticky-column test-table-header test-table-sub-th custom-column-button"
                              align="center"
                            >
                              <Button
                                variant="contained"
                                className="main-button selectAll unitFloor select view-button"
                                color="inherit"
                              >
                                {floor.floor_label}
                              </Button>
                            </TableCell>

                            {/* Iterate through units for the current floor */}
                            {salesChartByBlockData?.GetSalesChartByBlock.units.map(
                              unit =>
                                unit.floor === floor.floor &&
                                handleUnitFilter(unit) && (
                                  <TableCell
                                    key={unit.unit_id}
                                    align="center"
                                    className="custom-column-button"
                                  >
                                    {(() => {
                                      const status = unit.unit_status
                                      const common_status = unit.common_status
                                      let className = 'available-unit'

                                      if (common_status !== 'ACTIVE') {
                                        className = 'sign-unit'
                                      } else {
                                        if (status === 'AVAILABLE') {
                                          className = 'available-unit'
                                        } else if (status === 'SIGN') {
                                          className = 'sign-unit'
                                        } else if (status === 'BOOK') {
                                          className = 'sold-unit'
                                        }
                                      }

                                      return (
                                        <Button
                                          variant="contained"
                                          color="inherit"
                                          className={`unit-selection box-shadow-dark-2 ${className}`}
                                          onClick={() => {
                                            if (className !== 'sign-unit') {
                                              getUnitDetail({
                                                variables: {
                                                  unitId: unit.unit_id,
                                                },
                                              })
                                              setDialog(true)
                                            }
                                          }}
                                          disabled={className === 'sign-unit'}
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            height: '60px',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                          >
                                            <Typography
                                              variant="body2"
                                              className="unit-no"
                                              sx={{ fontSize: '0.75rem' }}
                                            >
                                              {unit.unit_no}
                                            </Typography>
                                            {unit.unit_status === 'AVAILABLE' &&
                                            unit.common_status === 'ACTIVE' ? (
                                              <Typography
                                                variant="body2"
                                                className="unit-price"
                                                color="text.secondary"
                                                sx={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  fontSize: '0.75rem',
                                                  height: 'fit-content',
                                                }}
                                              >
                                                <MonetizationOnIcon
                                                  fontSize="inherit"
                                                  style={{
                                                    marginRight: '4px',
                                                  }}
                                                />
                                                {unit.price &&
                                                  new Intl.NumberFormat().format(
                                                    unit.price
                                                  )}
                                              </Typography>
                                            ) : null}
                                            {unit.isBumi && (
                                              <Badge className="custom-buttom-badge">
                                                <PxLetterB />
                                              </Badge>
                                            )}
                                            {!unit.isBumi &&
                                              unit.unitViewCount > 0 && (
                                                <Badge className="custom-buttom-badge">
                                                  <Visibility
                                                    sx={{ fontSize: '1rem' }}
                                                  />
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                      fontSize: '0.75rem',
                                                      marginLeft: '4px',
                                                    }}
                                                  >
                                                    {unit.unitViewCount}
                                                  </Typography>
                                                </Badge>
                                              )}
                                          </Box>
                                        </Button>
                                      )
                                    })()}
                                  </TableCell>
                                )
                            )}
                            {salesChartByBlockData.GetSalesChartByBlock.units.filter(
                              unit =>
                                unit.floor === floor.floor &&
                                handleUnitFilter(unit)
                            ).length <= 0 && <EmptyState />}
                          </TableRow>
                        </TableBody>
                      )
                    )}
                  </Table>
                </Typography>
              </TableContainer>
            )}
        </div>
      </Typography>

      <CustomFooter
        text={[
          {
            line: [
              {
                label: (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                    gap={5}
                    marginRight="500px"
                    style={{ fontSize: '12px' }}
                  >
                    <RowFlex crossAxisAlignment="center">
                      <Checkbox
                        id="available"
                        onChange={handleFilter}
                        checked={unitFilter.available === CommonStatus.Active}
                        className={'available-unit'}
                      />
                      <span>
                        Available (
                        {salesChartByBlockData?.GetSalesChartByBlock.units.filter(
                          unit => isAvailableUnit(unit)
                        ).length || 0}
                        )
                      </span>
                    </RowFlex>

                    <RowFlex crossAxisAlignment="center">
                      <Checkbox
                        id="sold-unit"
                        onChange={handleFilter}
                        checked={unitFilter.sold === UnitStatus.Reserved_Sold}
                        className={'sold-unit'}
                      />
                      <span>
                        Booked (
                        {salesChartByBlockData?.GetSalesChartByBlock.units.filter(
                          unit => isSoldUnit(unit)
                        ).length || 0}
                        )
                      </span>
                    </RowFlex>

                    {salesChartByBlockData?.GetSalesChartByBlock.units.filter(
                      unit => isInactiveOrSignUnit(unit)
                    ).length > 0 && (
                      <RowFlex crossAxisAlignment="center">
                        <Checkbox
                          id="inactive-signed"
                          onChange={handleFilter}
                          checked={unitFilter.inactiveSigned}
                          className={'sign-unit'}
                        />
                        <span>
                          SPA Signed (
                          {salesChartByBlockData?.GetSalesChartByBlock.units.filter(
                            unit => isInactiveOrSignUnit(unit)
                          ).length || 0}
                          )
                        </span>
                      </RowFlex>
                    )}
                  </Box>
                ),
              },
              {
                label: (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    flexWrap="wrap"
                    gap={5}
                    marginLeft="300px"
                  >
                    {/* Bumi Unit */}
                    {threeDStatus?.getProjectChartMapped === false && (
                      <Typography
                        component="div"
                        className="status-cookies-label"
                      >
                        <Typography className="text-desc" color="common.black">
                          <span>Bumi Unit</span>
                          <PxLetterB style={{ marginLeft: '8px' }} />
                        </Typography>
                      </Typography>
                    )}

                    {/* In-View Unit */}
                    {threeDStatus?.getProjectChartMapped === false && (
                      <Typography
                        component="div"
                        className="status-cookies-label"
                      >
                        <Typography className="text-desc" color="common.black">
                          <span>In-View</span>
                          <VisibilityRounded
                            style={{ marginLeft: '8px', fontSize: '12px' }}
                          />
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                ),
              },
            ],
          },
        ]}
      />

      {openPriceRange && (
        <div
          onClick={() => setOpenPriceRange(false)}
          style={{
            position: 'fixed',
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
            top: '0px',
            left: '0px',
            zIndex: 2,
          }}
        ></div>
      )}
      {openUnitType && (
        <div
          onClick={() => setOpenUnitType(false)}
          style={{
            position: 'fixed',
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
            top: '0px',
            left: '0px',
            zIndex: 2,
          }}
        ></div>
      )}
    </>
  )
}

export default UnitListing
