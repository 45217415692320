import React, { useEffect, useRef, useState } from 'react'
import ImageMapper from 'react-img-mapper'
// @ts-ignore
import { MapInteractionCSS } from 'react-map-interaction'

enum UnitStatus {
  Available = 'AVAILABLE',
  Reserved_Sold = 'BOOK',
  SPA_Signed = 'SIGN',
  Available_Bumi = 'AVAILABLE BUMI',
  Reserved_Sold_Bumi = 'BOOK BUMI',
  SPA_Signed_Bumi = 'SIGN BUMI',

  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export const SalesChart3D = (props: any) => {
  const {
    blockData,
    imgUrl,
    getUnitDetail,
    // AVAIL_COLOR,
    // UNAVAIL_COLOR,
    // setSelected,
    // favUnit,
    contW,
    // checked,
    // available,
  } = props
  let imgRef: any = useRef()
  const [imgDimension, setImgDim] = useState({
    imgW: 0,
    imgH: 0,
  })
  const [windowSize, setWindowSize] = useState(0)

  useEffect(() => {
    setWindowSize(window.innerWidth)
  }, [window.innerWidth])

  const convStr = str => {
    const splitArr = str.split(',')
    const num = splitArr.map(v => parseFloat(v))

    return num
  }

  const MAPPED_AREAS = blockData?.map((v, i) => {
    return {
      name: v.unit_id,
      shape: 'poly',
      coords: convStr(v.coordinates),
      preFillColor:
        v.unit_status === 'AVAILABLE'
          ? `rgba(33, 2201, 134, 0.5)` // green
          : `rgba(197, 197, 197, 0.6)`, // grey
      fillColor:
        v.unit_status === 'AVAILABLE' ? `rgba(33, 2201, 134, 0.4)` : null,
      data: v,
    }
  })

  useEffect(() => {
    let img = new Image()
    img.src = imgUrl
    img.onload = () => {
      setImgDim({ imgW: img.width, imgH: img.height })
    }
  }, [imgUrl, blockData])

  const [val, setVal] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  })

  const handleOpenBottomSheet_for3DChart = (unit: any) => {
    if (
      unit.unit_status === UnitStatus.SPA_Signed ||
      unit.unit_status === UnitStatus.Inactive ||
      unit.unit_status === UnitStatus.Reserved_Sold
    ) {
      return
    }

    getUnitDetail({
      variables: {
        unitId: unit.unit_id,
      },
    })
  }

  return (
    <MapInteractionCSS
      value={val}
      onChange={value => setVal(value)}
      maxScale={5}
    >
      <ImageMapper
        src={imgUrl}
        onLoad={(imageRef, parentDim) => {
          imgRef = imageRef
        }}
        width={windowSize <= 600 ? 500 : contW}
        imgWidth={imgDimension.imgW}
        onClick={(area: any) => {
          // if (area.data.unit_status === "AVAILABLE") {
          //   getUnitDetail({
          //     variables: {
          //       unitId: area.name,
          //     },
          //   });
          //   setSelected(area.name);
          //   // setOpenBottomSheet(true);
          // }
          handleOpenBottomSheet_for3DChart(area.data)
        }}
        onTouchEnd={(area: any) => {
          // if (area.data.unit_status === "AVAILABLE") {
          //   getUnitDetail({
          //     variables: {
          //       unitId: area.name,
          //     },
          //   });
          //   setSelected(area.name);
          //   // setOpenBottomSheet(true);
          // }
          handleOpenBottomSheet_for3DChart(area.data)
        }}
        map={{
          name: 'my-map',
          areas: MAPPED_AREAS,
        }}
      />
    </MapInteractionCSS>
  )
}
