let agentDetail = '';
let name = '';
let email = '';
let url = '';

export const setAgentDetail = (s: string) => {
    agentDetail = s;
};

export const getAgentDetail = () => {
    return agentDetail;
};


export const setName = (s: string) => {
    name = s;
};

export const getName = () => {
    return name;
};

export const setEmail = (s: string) => {
    email = s;
};

export const getEmail = () => {
    return email;
};

export const setUrl = (s: string) => {
    url = s;
};

export const getUrl = () => {
    return url;
};

export const reset = () => {
    agentDetail = ''
    name = ''
    url = ''
}