import { yupResolver } from '@hookform/resolvers/yup'
import {
  AvatarUpload,
  Card,
  Container,
  Footer,
  Dialog,
  Subheader,
  SubheaderText,
  ColumnFlex,
  RowGrid,
  EmptyState,
  List,
  ListItemText,
  Chip,
  RowFlex,
  Menu,
} from '@ifca-ui/core'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import AppContext, { AppContextProps } from 'containers/context/Context'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, Form, useForm, useWatch } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import useNav from 'component/RouteService/useNav'
import { TopSectionHeader } from 'component/HeaderSection'
import { PhoneValidationService } from 'containers/helper/phoneValidationHelper'
import { TranslationServices } from 'translateService'
import MuiPhoneNumber from 'material-ui-phone-number'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  useProjectSummaryQuery,
  useJointBuyerFormSourceQuery,
  useAddJointBuyerMutation,
  useProjectSpecSettingQuery,
  useGetMainBuyerDetailsQuery,
  useGetJointBuyerEInvoiceDataQuery,
  useGetIdentityTypeQuery,
  useGetEditJointBuyerQuery,
  AddressJsonInput,
} from 'generated/graphql'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Webcam from 'react-webcam'
import Tesseract from 'tesseract.js'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

interface addJointBuyerForm {
  isForeignBuyer: boolean
  isRequireEInv: boolean
  isGov: boolean

  nationality: string
  name: string
  identityType: string
  identityNo: string
  TIN: string
  SST: string
  customerOrigin: string

  salutation: string
  race: string
  DOB: string
  gender: string
  maritalStatus: string
  occupation: string
  email: string
  mobileNo: string
  homeTelNo: string
  officeTelNo: string

  AddressType: string
  address: string
  country: string
  state: string
  city: string
  postCode: string
  district: string
  isPrimary: boolean
}

interface AddressForm {
  AddressType: string
  address: string
  country: string
  state: string
  city: string
  postCode: string
  district: string
  isPrimary: boolean
}

const EditJointBuyer = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const saleId = queryParams.get('saleId')
  const unitId = queryParams.get('unitId')
  const contactId = queryParams.get('contactId')
  const projectid = localStorage.getItem('lastestProject')

  const navigate = useNavigate()

  const [isEdited, setIsEdited] = useState(false)

  const [openAddressDialog, setOpenAddressDialog] = useState(false)

  // region Project Header

  const { data: { getProjectById: projectName } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Add New Joint Buyer',
          },
        },
      },
    })
  }, [projectName])

  // region end

  // region Joint Buyer Info

  const {
    data: { GetAllMasterInfo: BookingMasterData } = {
      GetAllMasterInfo: null,
    },
  } = useJointBuyerFormSourceQuery({
    variables: {
      unitId: unitId,
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { GetBookingDetail: BookingDetailsData } = { GetBookingDetail: null },
  } = useGetJointBuyerEInvoiceDataQuery({
    variables: {
      saleId: saleId,
      projectId: projectid,
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { GetJointBuyer: jointBuyerData } = { GetJointBuyer: null },
  } = useGetEditJointBuyerQuery({
    variables: {
      contactId: contactId!,
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { getIdentityType: IdentityData } = { getIdentityType: null },
  } = useGetIdentityTypeQuery({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { GetBookingDetail: BookingAddressData } = { GetBookingDetail: null },
  } = useGetMainBuyerDetailsQuery({
    variables: {
      sale_id: saleId,
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  // region Edit Joint Buyer Info

  const [addJointBuyer] = useAddJointBuyerMutation({
    onCompleted: data => {
      if (data && data.AddJointBuyer) {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: TranslationServices.Translate(
              'New joint buyer added successfully.'
            ),
          },
        })
      }
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  const validationScheme = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    identityType: Yup.string().required('Identity Type is required'),
    identityNo: Yup.string().required('Identity No is required'),
    TIN: Yup.string().required('TIN is required'),
    SST: Yup.string().required('SST Registration Number is required'),
    email: Yup.string()
      .required('Email address is required')
      .email('Email address is invalid'),
    mobileNo: Yup.string()
      .required('Mobile No. is required')
      .test(`isValidPhoneNo`, 'Mobile No. is invalid', value => {
        let validateResult = PhoneValidationService.isValidPoneNumber(
          PhoneValidationService.validatePhonePrefix(value)
        )
        return validateResult
      }),
  })

  // region end

  // region Form Value Control

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<addJointBuyerForm>({
    defaultValues: {
      isForeignBuyer: false,
      isRequireEInv: false,
      isGov: false,

      nationality: '',
      name: '',
      identityType: '',
      identityNo: '',
      TIN: '',
      SST: '',
      customerOrigin: '',

      salutation: '',
      race: '',
      DOB: '',
      gender: '',
      maritalStatus: '',
      occupation: '',
      email: '',
      mobileNo: '',
      homeTelNo: '',
      officeTelNo: '',

      // incomeRange: '',
      // leadChannel: '',
      // employerName: '',
      // employerAddress: '',

      AddressType: '',
      address: '',
      country: '',
      state: '',
      city: '',
      postCode: '',
      district: '',
      isPrimary: false,
    },
    resolver: yupResolver(validationScheme),
    mode: 'onChange',
  })

  // region end

  // region Checkbox Options

  const isForeignBuyer = useWatch({ control, name: 'isForeignBuyer' })
  const isGov = useWatch({ control, name: 'isGov' })
  const isRequireEInv = useWatch({ control, name: 'isRequireEInv' })

  // region end

  // region Pre-defined Form Value

  useEffect(() => {
    if (BookingDetailsData) {
      reset({
        nationality: 'Malaysia',
        identityType: 'Identity Card',
        SST: 'N/A',
        customerOrigin: 'public',
        salutation: 'Mrs',
        race: 'Malay',
      })
    }
    if(jointBuyerData?.contactAddress) setAddressList(jointBuyerData.contactAddress);
  }, [BookingDetailsData])

  // region end

  // region Submit Form

  const onSubmit = (data: addJointBuyerForm) => {
    const addressData: AddressJsonInput = {
      AddressType: data.AddressType,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      district: data.district,
      isPrimary: data.isPrimary,
    }

    addJointBuyer({
      variables: {
        input: {
          sale_id: saleId,

          is_foreign_purchase: data.isForeignBuyer,
          isEInvoiceRequired: data.isRequireEInv,
          isGovAuth: data.isGov,

          nationality_id: data.nationality,
          name: data.name,
          identity_type: data.identityType,
          identity_no: data.identityNo,
          tin: data.TIN,
          sst_reg_no: data.SST,
          customer_origin: data.customerOrigin,

          salutation_id: data.salutation,
          race_id: data.race,
          dob: data.DOB,
          gender_id: data.gender,
          marital_id: data.maritalStatus,
          email: data.email,
          phone_no: data.mobileNo,
          house_tel: data.homeTelNo,
          office_tel: data.officeTelNo,

          address: addressData,
        },
      },
    })
  }

  // region end

  // region Dirty Fields

  useEffect(() => {
    if (
      dirtyFields.isForeignBuyer ||
      dirtyFields.isRequireEInv ||
      dirtyFields.isGov ||
      dirtyFields.nationality ||
      dirtyFields.name ||
      dirtyFields.identityType ||
      dirtyFields.identityNo ||
      dirtyFields.TIN ||
      dirtyFields.SST ||
      dirtyFields.customerOrigin ||
      dirtyFields.salutation ||
      dirtyFields.race ||
      dirtyFields.DOB ||
      dirtyFields.gender ||
      dirtyFields.maritalStatus ||
      dirtyFields.occupation ||
      dirtyFields.email ||
      dirtyFields.mobileNo ||
      dirtyFields.homeTelNo ||
      dirtyFields.officeTelNo ||
      dirtyFields.AddressType ||
      dirtyFields.address ||
      dirtyFields.country ||
      dirtyFields.state ||
      dirtyFields.city ||
      dirtyFields.postCode ||
      dirtyFields.district ||
      dirtyFields.isPrimary
    )
      setIsEdited(true)
    else setIsEdited(false)
  }, [
    dirtyFields.isForeignBuyer ||
      dirtyFields.isRequireEInv ||
      dirtyFields.isGov ||
      dirtyFields.nationality ||
      dirtyFields.name ||
      dirtyFields.identityType ||
      dirtyFields.identityNo ||
      dirtyFields.TIN ||
      dirtyFields.SST ||
      dirtyFields.customerOrigin ||
      dirtyFields.salutation ||
      dirtyFields.race ||
      dirtyFields.DOB ||
      dirtyFields.gender ||
      dirtyFields.maritalStatus ||
      dirtyFields.occupation ||
      dirtyFields.email ||
      dirtyFields.mobileNo ||
      dirtyFields.homeTelNo ||
      dirtyFields.officeTelNo ||
      dirtyFields.AddressType ||
      dirtyFields.address ||
      dirtyFields.country ||
      dirtyFields.state ||
      dirtyFields.city ||
      dirtyFields.postCode ||
      dirtyFields.district ||
      dirtyFields.isPrimary,
  ])

  // region end

  // region Checkbox Options Validation

  useEffect(() => {
    if (isForeignBuyer) {
      setValue('TIN', 'E100000000020')
    } else if (isGov) {
      setValue('TIN', 'E100000000040')
    } else {
      setValue('TIN', '')
    }

    if (isRequireEInv) {
      setValue('SST', '')
    } else {
      setValue('SST', 'N/A')
    }
  }, [isForeignBuyer, isRequireEInv, isGov, setValue])

  // region end

  // region Address Listing

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const handleClose = () => {
    setOpenAddressDialog(false)
    setAnchorEl(null);
  }

  const [addressList, setAddressList] = useState<any[]>([])

  const AddressTypes = [
    'ID Address',
    'Billing Address',
    'Postal Address',
    'Company Address',
  ]

  const [disabledTypes, setDisabledTypes] = useState<string[]>([])

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedIndex(index)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedIndex(null)
  }

  // region end

  // region Save Address

  const {
    control: addressControl,
    handleSubmit: handleAddressSubmit,
    reset: addressReset,
    setValue: setAddressValue,
    watch,
    formState: { errors: addressErrors },
  } = useForm<AddressForm>({
    defaultValues: {
      AddressType: '',
      address: '',
      country: '',
      state: '',
      city: '',
      postCode: '',
      district: '',
      isPrimary: false,
    },
  })
  const country = watch('country');

  useEffect(() => {
    if (openAddressDialog && selectedAddress) {
      addressReset({
        AddressType: selectedAddress?.AddressType || '',
        address: selectedAddress?.Address || '',
        country: selectedAddress?.Country || '',
        state: selectedAddress?.State || '',
        city: selectedAddress?.City || '',
        postCode: selectedAddress?.postCode || '',
        district: selectedAddress?.District || '',
        isPrimary: selectedAddress?.isPrimary || false,
      })
    } else if (openAddressDialog) {
      addressReset({
        AddressType: '',
        address: '',
        country: '',
        state: '',
        city: '',
        postCode: '',
        district: '',
        isPrimary: false,
      })
    }
  }, [openAddressDialog, selectedAddress, addressReset])

  // useEffect(() => {
  //   const savedData = localStorage.getItem('addressData')
  //   if (savedData) {
  //     try {
  //       const parsedData = JSON.parse(savedData)
  //       if (Array.isArray(parsedData)) {
  //         setAddressList(parsedData)
  //       }
  //     } catch (error) {
  //       console.error('Error parsing address data:', error)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    const usedAddressTypes = addressList.map(
      (address: any) => address.AddressType
    )
    setDisabledTypes(prevDisabledTypes => {
      if (
        JSON.stringify(prevDisabledTypes) !== JSON.stringify(usedAddressTypes)
      ) {
        return usedAddressTypes
      }
      return prevDisabledTypes
    })
  }, [addressList])

  const onSave = (data: any) => {
    const index = addressList.findIndex(x => x.isPrimary);
    if(index >= 0 && data?.isPrimary){
      addressList[index].isPrimary = false;
    }

    if (selectedAddress) {
      const index = addressList.findIndex(
        (address: any) => address.AddressType === selectedAddress.AddressType
      )

      if (index !== -1) {
        addressList[index] = { ...addressList[index], ...data }
        localStorage.setItem('addressData', JSON.stringify(addressList))
        setAddressList(addressList)
        setSelectedAddress(null)
      }
    } else {
      const existingAddress = addressList.find(
        (address: any) => address.AddressType === data.AddressType
      )

      if (existingAddress) {
        alert(`Address type "${data.AddressType}" already exists!`)
        return
      }

      if (addressList.length < 4) {
        addressList.push(data)
        localStorage.setItem('addressData', JSON.stringify(addressList))
        setAddressList(addressList)
      } else {
        alert('You can only save up to 4 addresses.')
      }
    }
  }

  const onAddressSubmit = (data: any) => {
    onSave(data)
    setOpenAddressDialog(false)
  }

  // region end

  // region Edit address

  const handleEdit = () => {
    if (selectedIndex !== null) {
      setSelectedAddress(addressList[selectedIndex])
      setOpenAddressDialog(true)
    }
    handleMenuClose()
  }

  // region end

  // region Delete address

  const handleDelete = () => {
    if (selectedIndex !== null) {
      const updatedAddressList = [...addressList]
      updatedAddressList.splice(selectedIndex, 1)
      setAddressList(updatedAddressList)
      localStorage.setItem('addressData', JSON.stringify(updatedAddressList))
    }
    handleMenuClose()
  }

  // region end

  // region Scan id

  const [name, setName] = useState('')
  const [isScanning, setIsScanning] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [scanResult, setScanResult] = useState('')

  const webcamRef = React.useRef(null)

  const startScanning = () => {
    setIsScanning(true)
  }

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImageSrc(imageSrc)
    setIsScanning(false)
    performOCR(imageSrc)
  }

  const performOCR = imageSrc => {
    setIsProcessing(true)
    Tesseract.recognize(imageSrc, 'eng', {
      logger: m => console.log(m),
    }).then(({ data: { text } }) => {
      setScanResult(text)
      const extractedName = extractNameFromText(text)
      setName(extractedName)
      setIsProcessing(false)
    })
  }

  const extractNameFromText = text => {
    const nameRegex = /Name:?\s*([A-Za-z\s]+)/
    const match = text.match(nameRegex)
    return match ? match[1] : ''
  }

  // region end

  // region Date

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  // region end

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <ColumnFlex fullWidth>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <FormControlLabel
                    label={'Require E-Invoice'}
                    control={
                      <Controller
                        name="isRequireEInv"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    sx={{
                      height: '30px',
                      '.MuiFormControlLabel-label': {
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                  />

                  <FormControlLabel
                    label={'Foreign Buyer'}
                    control={
                      <Controller
                        name="isForeignBuyer"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                            disabled={isGov}
                          />
                        )}
                      />
                    }
                    sx={{
                      height: '30px',
                      '.MuiFormControlLabel-label': {
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                  />

                  <FormControlLabel
                    label={'Government/Authority Purchase'}
                    control={
                      <Controller
                        name="isGov"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                            disabled={isForeignBuyer}
                          />
                        )}
                      />
                    }
                    sx={{
                      height: '30px',
                      '.MuiFormControlLabel-label': {
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                  />
                </ColumnFlex>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={startScanning}
                    disabled={isScanning || isProcessing}
                    style={{
                      padding: '4px 8px',
                      fontSize: '12px',
                      height: '30px',
                      width: '100px',
                    }}
                  >
                    Scan ID
                  </Button>
                </Box>

                {isScanning && (
                  <div>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width="100%"
                      videoConstraints={{
                        facingMode: 'environment',
                      }}
                    />
                    <div style={{ marginTop: '10px' }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={captureImage}
                        style={{ marginRight: '10px' }}
                      >
                        Capture
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setIsScanning(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </RowGrid>
            </ColumnFlex>

            <Controller
              control={control}
              name="nationality"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onChange={e => {
                    onChange(e?.target?.value)
                  }}
                  onBlur={onBlur}
                  value={value || '     '}
                  label="Nationality"
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  name="nationality"
                  select
                >
                  <MenuItem value="Malaysia">Malaysia</MenuItem>

                  {BookingMasterData?.Nationalities &&
                    BookingMasterData.Nationalities.length > 0 &&
                    BookingMasterData.Nationalities.map(nationality => (
                      <MenuItem key={nationality.id} value={nationality.id}>
                        {nationality.name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.name ? true : false}
                  helperText={errors.name?.message}
                  label="Name"
                  type="text"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
              <Controller
                control={control}
                name="identityType"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    onChange={e => {
                      onChange(e?.target?.value)
                    }}
                    onBlur={onBlur}
                    value={value || '     '}
                    label="Identity Type"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    required
                    margin="normal"
                    name="identityType"
                    select
                  >
                    <MenuItem value="Identity Card">Identity Card</MenuItem>

                    {IdentityData &&
                      IdentityData.map(identity => (
                        <MenuItem key={identity.id} value={identity.id}>
                          {identity.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="identityNo"
                render={({ field }) => (
                  <TextField
                    error={errors.identityNo ? true : false}
                    helperText={errors.identityNo?.message}
                    label="Identity No."
                    type="text"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </RowGrid>

            {!isRequireEInv && (
              <>
                <Controller
                  control={control}
                  name="TIN"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="TIN"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      value={field.value}
                      onChange={e => field.onChange(e.target.value)}
                      error={!!errors.TIN}
                      helperText={errors.TIN?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="SST"
                  render={({ field }) => (
                    <TextField
                      error={errors.SST ? true : false}
                      helperText={errors.SST?.message}
                      label="SST Registration No."
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      value={field.value}
                      onChange={e => field.onChange(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </>
            )}

            {isRequireEInv && (
              <>
                <Controller
                  control={control}
                  name="TIN"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="TIN"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      value={field.value}
                      onChange={e => field.onChange(e.target.value)}
                      error={!!errors.TIN}
                      required
                      helperText={errors.TIN?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="SST"
                  render={({ field }) => (
                    <TextField
                      error={errors.SST ? true : false}
                      helperText={errors.SST?.message}
                      label="SST Registration No."
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      value={field.value}
                      required
                      onChange={e => field.onChange(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </>
            )}

            <ColumnFlex fullWidth>
              <Controller
                control={control}
                name="customerOrigin"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    onChange={e => {
                      onChange(e?.target?.value)
                    }}
                    onBlur={onBlur}
                    value={value || 'public'}
                    label="Customer Origin"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="customerOrigin"
                    select
                  >
                    <MenuItem value="staff_purchase">Staff Purchase</MenuItem>
                    <MenuItem value="public">Public</MenuItem>
                    <MenuItem value="government_staff">
                      Government Staff
                    </MenuItem>
                    <MenuItem value="company_purchase">
                      Company Purchase
                    </MenuItem>
                  </TextField>
                )}
              />

              <Divider sx={{ marginY: 2 }} />

              <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                <Controller
                  control={control}
                  name="salutation"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      onChange={e => {
                        onChange(e?.target?.value)
                      }}
                      onBlur={onBlur}
                      value={value || '     '}
                      label="Salutation"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      name="salutation"
                      select
                    >
                      <MenuItem value="Mrs">Mrs</MenuItem>

                      {BookingMasterData?.Salutations &&
                        BookingMasterData.Salutations.length > 0 &&
                        BookingMasterData.Salutations.map(salutation => (
                          <MenuItem key={salutation.id} value={salutation.id}>
                            {salutation.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />

                <Controller
                  control={control}
                  name="race"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      onChange={e => {
                        onChange(e?.target?.value)
                      }}
                      onBlur={onBlur}
                      value={value || '     '}
                      label="Race"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      name="race"
                      select
                    >
                      <MenuItem value="Malay">Malay</MenuItem>

                      {BookingMasterData?.Races &&
                        BookingMasterData.Races.length > 0 &&
                        BookingMasterData.Races.map(race => (
                          <MenuItem key={race.id} value={race.id}>
                            {race.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </RowGrid>
            </ColumnFlex>

            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  control={control}
                  name="DOB"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <DatePicker
                      label="Date"
                      name="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      slotProps={{
                        textField: {
                          sx: {
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderWidth: 0,
                                borderBottom: '1px solid #757575',
                              },
                              height: '50x',
                              marginTop: '8px',
                            },
                            '& input::placeholder': {
                              fontSize: '12px',
                            },
                            '& input': {
                              paddingLeft: '0px',
                              paddingTop: '22px',
                              fontSize: '12px',
                            },
                          },
                          InputLabelProps: {
                            sx: {
                              marginLeft: '-14px',
                              marginTop: '14px',
                            },
                            shrink: true,
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    onChange={e => {
                      onChange(e?.target?.value)
                    }}
                    onBlur={onBlur}
                    value={value || '     '}
                    label="Gender"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="gender"
                    select
                  >
                    {Object.keys(BookingMasterData?.Genders || {}).map(key => (
                      <MenuItem key={key} value={key}>
                        {BookingMasterData?.Genders[key]}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </RowGrid>

            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
              <Controller
                control={control}
                name="maritalStatus"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    onChange={e => {
                      onChange(e?.target?.value)
                    }}
                    onBlur={onBlur}
                    value={value || '     '}
                    label="Marital Status"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="maritalStatus"
                    select
                  >
                    {BookingMasterData?.MaritalStatus &&
                      BookingMasterData.MaritalStatus.length > 0 &&
                      BookingMasterData.MaritalStatus.map(marital => (
                        <MenuItem key={marital.id} value={marital.id}>
                          {marital.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />

              <Controller
                control={control}
                name="occupation"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    onChange={e => {
                      onChange(e?.target?.value)
                    }}
                    onBlur={onBlur}
                    value={value || '     '}
                    label="Occupation"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="occupation"
                    select
                  >
                    {BookingDetailsData?.contact?.race && (
                      <MenuItem
                        key={BookingDetailsData.contact.race.id}
                        value={BookingDetailsData.contact.race.id}
                      >
                        {BookingDetailsData.contact.race.name}
                      </MenuItem>
                    )}
                  </TextField>
                )}
              />
            </RowGrid>

            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                    label="Email Address"
                    type="email"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="mobileNo"
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    disableAreaCodes={true}
                    defaultCountry={'my'}
                    variant="standard"
                    fullWidth
                    margin="normal"
                    label="Mobile No."
                    value={field.value}
                    error={errors.mobileNo ? true : false}
                    helperText={errors.mobileNo?.message}
                    required
                    autoFormat={false}
                  />
                )}
              />
            </RowGrid>

            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
              <Controller
                control={control}
                name="homeTelNo"
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    disableAreaCodes={true}
                    defaultCountry={'my'}
                    variant="standard"
                    fullWidth
                    margin="normal"
                    label="Home Tel No."
                    value={field.value}
                    error={errors.homeTelNo ? true : false}
                    helperText={errors.homeTelNo?.message}
                    autoFormat={false}
                  />
                )}
              />
              <Controller
                control={control}
                name="officeTelNo"
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    disableAreaCodes={true}
                    defaultCountry={'my'}
                    variant="standard"
                    fullWidth
                    margin="normal"
                    label="Office Tel No."
                    value={field.value}
                    error={errors.officeTelNo ? true : false}
                    helperText={errors.officeTelNo?.message}
                    autoFormat={false}
                  />
                )}
              />
            </RowGrid>

            <Card
              className="card-container-detail"
              header={{
                title: (
                  <SubheaderText
                    primary={
                      <Box display="flex" margin="2px 0px" width="100%">
                        <Typography
                          className="text-xsTitle"
                          color="FF9800"
                          component="span"
                          flex="1 1"
                          variant="inherit"
                        >
                          Address Listing
                        </Typography>
                      </Box>
                    }
                  />
                ),
                action: (
                  <>
                    <IconButton
                      onClick={() => {
                        setSelectedAddress(null)
                        setOpenAddressDialog(true)
                      }}
                    >
                      <AddCircleOutlineIcon color="primary" />
                    </IconButton>
                  </>
                ),
              }}
              sx={{ width: '100%', margin: '10px' }}
            >
              <Container>
                <List sx={{ width: '100%' }}>
                  {addressList.length === 0 ? (
                    <EmptyState />
                  ) : (
                    addressList.map((addressListing, index) => (
                      <Box component="div" key={index} sx={{ width: '100%' }}>
                        <ListItemText
                          primary={
                            <RowFlex gap="5px" fullWidth>
                              <Typography
                                className="text-icon text-xsTitle"
                                color="black"
                                component="span"
                                flex="1"
                                variant="inherit"
                              >
                                {addressListing.AddressType}
                              </Typography>

                              {addressListing?.isPrimary && (
                                <Chip
                                  label={
                                    <Typography
                                      component="span"
                                      className="text-label"
                                      variant="inherit"
                                    >
                                      Primary Address
                                    </Typography>
                                  }
                                  sx={{
                                    background: '#696969',
                                    color: '#ffffff',
                                    padding: '0px',
                                  }}
                                />
                              )}
                            </RowFlex>
                          }
                          secondary={
                            <RowGrid
                              gridTemplateColumns="1fr 1fr"
                              height="15px"
                            >
                              <Typography
                                className="text-icon text-desc"
                                color="#454545"
                                component="div"
                                variant="inherit"
                              >
                                {addressListing
                                  ? `${addressListing.address || ''} ${
                                      addressListing.postCode || ''
                                    } ${addressListing.city || ''} ${
                                      addressListing.state || ''
                                    } ${addressListing.country || ''}`
                                  : 'Loading address...'}
                              </Typography>

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <IconButton
                                  sx={{ marginRight: '-14px' }}
                                  onClick={event =>
                                    handleMenuClick(event, index)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Box>
                            </RowGrid>
                          }
                        />
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem onClick={handleEdit}>Edit</MenuItem>
                          <MenuItem onClick={handleDelete}>Delete</MenuItem>
                        </Menu>
                      </Box>
                    ))
                  )}
                </List>
              </Container>
            </Card>
          </Card>
        </form>

        <Footer
          buttons={[
            {
              children: 'Save',
              color: 'primary',
              onClick: handleSubmit(onSubmit),
            },
          ]}
        />
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openAddressDialog}
        onClose={() => setOpenAddressDialog(false)}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Typography
                      className="text-mdTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Address
                    </Typography>
                  </Box>
                }
              />
            </Subheader>
          </>
        }
        body={
          <>
            <form onSubmit={handleAddressSubmit(onAddressSubmit)}>
              <ColumnFlex gap="12px">
                <ColumnFlex>
                  <Controller
                    control={addressControl}
                    name="AddressType"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={e => onChange(e?.target?.value)}
                        onBlur={onBlur}
                        value={value || '     '}
                        label="Address Type"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        required
                        margin="normal"
                        name="AddressType"
                        select
                      >
                        {AddressTypes.map(AddressType => (
                          <MenuItem
                            key={AddressType}
                            value={AddressType}
                            disabled={disabledTypes.includes(AddressType)}
                          >
                            {AddressType}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </ColumnFlex>

                <ColumnFlex>
                  <Controller
                    control={addressControl}
                    name="address"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={addressErrors.address ? true : false}
                        helperText={addressErrors.address?.message}
                        label="Address"
                        type="text"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </ColumnFlex>

                <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="country"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.country ? true : false}
                          helperText={addressErrors.country?.message}
                          label="Country"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="state"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.state ? true : false}
                          helperText={addressErrors.state?.message}
                          label="State"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required={country?.toLowerCase() === 'malaysia'}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                </RowGrid>

                <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="city"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.city ? true : false}
                          helperText={addressErrors.city?.message}
                          label="City"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="postCode"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.postCode ? true : false}
                          helperText={addressErrors.postCode?.message}
                          label="Postcode"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                </RowGrid>

                <ColumnFlex>
                  <Controller
                    control={addressControl}
                    name="district"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={addressErrors.district ? true : false}
                        helperText={addressErrors.district?.message}
                        label="District"
                        type="text"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </ColumnFlex>

                <ColumnFlex>
                  <FormControlLabel
                    label={'Primary Address'}
                    control={
                      <Controller
                        name="isPrimary"
                        control={addressControl}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                  />
                </ColumnFlex>
              </ColumnFlex>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button color="primary" type="submit">
                  Save
                </Button>
                <Button
                  color="primary"
                  onClick={() => setOpenAddressDialog(false)}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </>
        }
      />
    </>
  )
}

export default EditJointBuyer
