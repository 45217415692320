import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Login from '../modules/Authentication/Login' // Adjust the path to your Login component
// const Login = lazy(() => import('./Login'))
const GoogleReCaptchaWrapper: React.FC = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'head',
      }}
    >
      <Login />
    </GoogleReCaptchaProvider>
  )
}

export default GoogleReCaptchaWrapper
