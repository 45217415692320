import Fuse from 'fuse.js'
import React, { useEffect } from 'react'

/**
 *
 * @function handleSearch
 * 3 parameters (origList, filter, keys)
 *
 * @param keys
 * keys to be search in the object
 *
 * first layer:
 * - {name: "chia"}
 * - keys = ["name"]
 *
 * nested object:
 * - { person: { name: "chia", size: { height: "180", weight: "120"}}}
 * - keys = ["person.name.size.height", "person.name.size.weight"]
 *
 * @param origList
 * original list to be search
 *
 * @param filter
 * filter string or pattern words being search
 * -- empty will return the original list
 *
 * @function reset
 * -- only use if you want to reset the list manually,
 * else the search should be able to auto reset the list to original
 */

export const useFuseSearch = () => {
  const [filteredList, setFiltered] = React.useState([])
  const [originalList, setOriginalList] = React.useState([])
  const [searchText, setSearchText] = React.useState<string>()
  const [searchKey, setSearchKey] = React.useState<string[]>([])
  const setOriginalListing = (origList: any, customDataChanges?: any) => {

    let tempOrig = origList
    if (!!customDataChanges) {
      tempOrig?.map(v => customDataChanges(v))
    }
    setFiltered(tempOrig)
    setOriginalList(tempOrig)
  }

  useEffect(()=>{
    if(searchText && (searchKey && searchKey.length>0)){
      filterFn(searchText,searchKey);
    }
  },[originalList])

  useEffect(()=>{
  },[filteredList])

  
  const filterFn = (searchText:string,keys:string[])=>{
    if (originalList && originalList.length> 0) {
      const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
         threshold: 0.0,
        // distance: 100,
        // useExtendedSearch: false,
         ignoreLocation: true,
        // ignoreFieldNorm: false,
        keys: keys,
      }
      if(searchText){
        const myFuse = new Fuse(originalList, options)
        const result = myFuse.search(searchText)
        const val = result?.map(x => x.item)
        setFiltered(val)
      }else{
        setFiltered(originalList)
      }
    }

  }

  //refer below to setup the option
  //reference: https://fusejs.io/api/options.html
  const handleSearch = (filter: string, keys: string[]) => {
    setSearchText(filter);
    setSearchKey(keys);

    filterFn(filter,keys);
  }

  const reset = () => {
    setFiltered(originalList)
  }

  return {
    filteredList,
    handleSearch,
    setOriginalListing,
    reset,
  }
}
