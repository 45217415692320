import {
  DesktopHeader,
  Drawer,
  Header,
  layoutClasses,
  LayoutRoot,
  ModuleSection,
  ProfileMenuItem,
  ProfileSection,
  Spinner,
} from '@ifca-ui/core'
import {
  Home as HomeIcon,
  Logout as LogoutIcon,
  Profile as ProfileIcon,
} from '@ifca-ui/icons/dist/index.esm'
import PeopleIcon from '@mui/icons-material/People'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SideMenuBg from 'assets/images/product/sidemenu-background.svg'
import clsx from 'clsx'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { ACCT_PATH } from 'containers/modules/Account/Routes'
import { AUTH_PATH } from 'containers/modules/Authentication/Routes'
import { AGENT_PATH } from 'containers/modules/Agent/Routes'
import { useAgencyNameLazyQuery, useProfileLazyQuery } from 'generated/graphql'
import * as React from 'react'
import { NavLink, useNavigate, useLocation, Outlet } from 'react-router-dom'
import {
  setAgentDetail,
  getAgentDetail,
  setName,
  getName,
  setEmail,
  getEmail,
  setUrl,
  getUrl,
  reset,
} from 'UserDetail'
import { HOME_PATH } from 'containers/modules/Home/Routes'
import { navigationService } from 'navigationService'
import { setAccessToken } from 'AccessToken'
import { Suspense, useEffect } from 'react'
import HeaderX from 'component/HeaderX'
import { setDefaultResultOrder } from 'dns'
import { getEnvironmentData } from 'worker_threads'
import teamIcon from 'assets/images/icon/team.svg'
import homeIcon from 'assets/images/main-menu/home.svg'
import { TranslationServices } from 'translateService'
interface Modules {
  name: string
  path?: string
  icon?: any
  iconActived?: any
}

interface SidebarComponentProps {
  children?: React.ReactNode
  window?: () => Window
}

const SidebarComponent: React.FC<SidebarComponentProps> = ({
  children,
  window,
}) => {
  const nav = new navigationService()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [role, setRole] = React.useState('')
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)

  const ModulesListWithAgent: Modules[] = [
    {
      name: TranslationServices.Translate('Home'),
      path: HOME_PATH.HOME,
      icon: <img src={homeIcon} alt="Home Icon" />,
      iconActived: <HomeIcon className="svg-icon" />,
    },
    {
      name: TranslationServices.Translate('Agent / Negiotiator'),
      path: AGENT_PATH.AGENT_LIST,
      icon: <img src={teamIcon} alt="Team Icon" />,
      iconActived: <PeopleIcon className="svg-icon" />,
    },
    {
      name: TranslationServices.Translate('Log Out'),
      path: '/logout',
      icon: <LogoutIcon className="svg-icon" />,
      iconActived: <LogoutIcon className="svg-icon" />,
    },
  ]
  const ModulesListWithoutAgent: Modules[] = [
    {
      name: TranslationServices.Translate('Home'),
      path: HOME_PATH.HOME,
      icon: <img src={homeIcon} alt="Home Icon" />,
      iconActived: <HomeIcon className="svg-icon" />,
    },
    {
      name: TranslationServices.Translate('Log Out'),
      path: '/logout',
      icon: <LogoutIcon className="svg-icon" />,
      iconActived: <LogoutIcon className="svg-icon" />,
    },
  ]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const theme = useTheme()
  const container =
    window !== undefined ? () => window().document.body : undefined
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const profileMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ProfileMenuItem
        onClick={() => {
          if (!isDesktop) {
            handleDrawerOpen()
          }
          navigate(ACCT_PATH.PROFILE)
          handleClose()
        }}
      >
        <ProfileIcon width="18px" height="18px" />
        PROFILE
      </ProfileMenuItem>
      <ProfileMenuItem onClick={() => clickLogout()}>
        {' '}
        <LogoutIcon width="18px" height="18px" />
        LOG_OUT
      </ProfileMenuItem>
    </Menu>
  )
  const handleDrawerOpen = () =>
    rootDispatch({ type: 'isDrawerOpen', payload: !rootState.isDrawerOpen })
  React.useEffect(() => {
    if (isDesktop && !rootState.isDrawerOpen) {
      document?.getElementById('main-header')?.classList.add('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.add('full-width')
      document?.getElementById('footer')?.classList.add('full-width')
    } else {
      document?.getElementById('main-header')?.classList.remove('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.remove('full-width')
      document?.getElementById('footer')?.classList.remove('full-width')
    }
  }, [rootState.isDrawerOpen, isDesktop])

  const ErrorPage = [
    {
      label: '404',
      path: AUTH_PATH.NOT_FOUND,
    },
  ]

  const [authOpen, setAuthOpen] = React.useState(false)
  const [acctOpen, setAcctOpen] = React.useState(false)
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [componentOpen, setComponentOpen] = React.useState(true)
  const handleAuthClick = () => {
    setAuthOpen(!authOpen)
  }
  const handleAcctClick = () => {
    setAcctOpen(!acctOpen)
  }
  const handleErrorClick = () => {
    setErrorOpen(!errorOpen)
  }
  const handleComponentClick = () => {
    setComponentOpen(!componentOpen)
  }

  const [getAgentName, { data }] = useAgencyNameLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.getAgencyName) {
        console.log(data?.getAgencyName, 'getAgentName')
        setAgentDetail(data?.getAgencyName)
      }
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const [
    getProfileName,
    {
      data: { profile: profile } = {
        profile: null,
      },
    },
  ] = useProfileLazyQuery({
    fetchPolicy: 'no-cache',
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
    onCompleted: (data) => {
      console.log(data, 'profile')
      setName(data?.profile?.name ?? '')
      setEmail(data?.profile?.email ?? '')
      data?.profile?.photoUrl !== null ? setUrl(data?.profile?.photoUrl ?? '') : setUrl(' ')
      sessionStorage.setItem('UserRole', data?.profile?.role ?? '')
      JSON.stringify(
        localStorage.setItem(
          'getTranslation',
          data?.profile?.userLanguageChoose?.obj!
        )
      )
    },
  })

  let ModulesList =
    sessionStorage.getItem('UserRole') === 'STAFF'
      ? ModulesListWithoutAgent
      : ModulesListWithAgent

  useEffect(() => {
    if (sessionStorage.getItem('UserRole') === 'STAFF') {
      ModulesList = ModulesListWithoutAgent
    } else {
      ModulesList = ModulesListWithAgent
    }
  })

  useEffect(() => {
    setName('')
    setEmail('')
    setUrl('')
    getProfileName()
    getAgentName()
  }, [])

  console.log(getName(), getEmail(), getUrl(), getAgentDetail(), 'name')

  function clickLogout() {
    console.log('masuk logout')
    localStorage.clear()
    sessionStorage.clear()
    nav.clearArray()
    // reset()
    navigate('/authentication/login')
    if (localStorage.getItem('getTranslation')) {
      localStorage.removeItem('getTranslation')
    }
  }

  function handle(ob: any) {
    if (!isDesktop) {
      handleDrawerOpen()
    }
    if (ob === 'Home') {
      nav.backHome()
    }
    if (ob === 'Log Out') {
      clickLogout()
    }
    if (ob === 'Agent/Negotiator') {
      // nav.clickSideBar(ob)
      // nav.backHome()
    }
  }

  return (
    <LayoutRoot component="div" className={layoutClasses.root} display="flex">
      {isDesktop ? (
        <DesktopHeader
          title={getAgentDetail()}
          leftIcon={{
            props: {
              onClick: () => handleDrawerOpen(),
            },
          }}
        />
      ) : null}

      <HeaderX isDesktop={isDesktop} />
      <Drawer
        container={container}
        onClose={handleDrawerOpen}
        open={isDesktop ? rootState.isDrawerOpen : !rootState.isDrawerOpen}
        variant={isDesktop ? 'persistent' : 'temporary'}
      >
        <ProfileSection
          drawerImage={SideMenuBg}
          avatar={{
            src: getUrl(),
          }}
          profile={{
            title: `${getName()}`,
            subTitle: `${getEmail()}`,
          }}
          dropDownIcon={{
            onClick: e => handleClick(e),
          }}
        />
        <ModuleSection as="div">
          <List disablePadding>
            {ModulesList.map((module, index) => (
              <ListItem
                id={module.name}
                key={index}
                dense
                divider
                component={NavLink}
                to={module.path}
                onClick={e => {
                  handle(module.name)
                }}
              >
                {/* <ListItemIcon>
                  {pathname !== module.path && module.icon}
                  {pathname === module.name && module.iconActived}
                </ListItemIcon> */}
                <ListItemIcon>{module.icon}</ListItemIcon>
                <ListItemText
                  primary={module.name}
                  primaryTypographyProps={{
                    variant: 'inherit',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </ModuleSection>
      </Drawer>
      {profileMenu}
      <Box
        component="main"
        className={clsx(layoutClasses.mainContent, {
          [layoutClasses.contentShift]: rootState.isDrawerOpen,
        })}
      >
        <Suspense fallback={<Spinner />}>
          <div style={{ height: `${rootState.headerHeight}px` }} />
          <Outlet />
        </Suspense>
      </Box>
    </LayoutRoot>
  )
}

export default SidebarComponent
