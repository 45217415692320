import AcctRoutes from '../modules/Account/Routes'
import AccessSecurityRoutes from '../modules/AccessSecurity/Routes'
import { Navigate, useRoutes } from 'react-router-dom'
import MainLayout from '../app/SidebarComponent'
import AuthRoutes from '../modules/Authentication/Routes'
import { AuthGuard } from './AuthGuard'
import { Blank } from './Blank'
import { AUTH_PATH } from '../modules/Authentication/Routes'
import HomeRoutes from '../modules/Home/Routes'
import AgentRoutes from '../modules/Agent/Routes'
import SalesGalleryRoutes from 'containers/modules/Sales Gallery/Routes'
import SalesDocumentRoutes from 'containers/modules/Sales Document/Routes'
import WaitingListRoutes from 'containers/modules/Waiting List/Routes'
import LoanRoutes from 'containers/modules/Loan/Routes'
import BusinessInsightRoutes from 'containers/modules/Business Insight/Routes'
import AuthorizedSalesAgentRoutes from 'containers/modules/Authorized Sales Agent/Routes'
import BookingListRoutes from 'containers/modules/Booking List/Routes'

export function MainRoutes() {
  const RootRoutes = useRoutes([
    {
      path: '',
      element: <AuthGuard />,
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <Navigate to="/dashboards/landing" />,
            },
            {
              path: 'dashboards',
              children: [
                {
                  path: 'landing',
                  children: [...HomeRoutes],
                },
                {
                  path: 'sales-gallery',
                  children: [...SalesGalleryRoutes],
                },
              ],
            },
            {
              path: 'profile',
              children: [...AcctRoutes],
            },
            {
              path: 'book',
              children: [
                ...SalesDocumentRoutes,
                ...WaitingListRoutes,
                ...BookingListRoutes,
              ],
            },
            {
              path: 'agent',
              children: [...AgentRoutes, ...AuthorizedSalesAgentRoutes],
            },
            {
              path: 'loan',
              children: [...LoanRoutes],
            },
            {
              path: 'business-insight',
              children: [...BusinessInsightRoutes],
            },
            {
              path: 'access-security',
              children: [...AccessSecurityRoutes],
            },
          ],
        },
      ],
    },
    {
      path: '',
      element: <Blank />,
      children: [...AuthRoutes],
    },
    {
      path: '*',
      element: <Navigate replace to={AUTH_PATH.NOT_FOUND}></Navigate>,
    },
  ])

  return RootRoutes
}
// export default RootRoutes
