import { createUploadLink } from 'apollo-upload-client'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { ClearAccessToken, getAccessToken, setAccessToken } from 'AccessToken'
import jwtDecode from 'jwt-decode'
import { setLoading } from 'containers/helper/loadingHelper'
import { navigateTo } from './containers/helper/navigationSubjectHelper'
import { setSnackBar } from './containers/helper/snackBarSubjectHelper'
import { AUTH_PATH } from 'containers/modules/Authentication/Routes'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
  HttpLink,
  RequestHandler,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

let apiNum: number = 0

export let GoogleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY

let nodeUrl = import.meta.env.VITE_API_URL
const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  // true - invalid, false - valid
  isTokenValidOrUndefined: () => {
    const token = getAccessToken()
    if (!token) {
      return true
    }

    try {
      const { exp } = jwtDecode(token)
      if (Date.now() >= exp * 1000) {
        return false
      } else {
        return true
      }
    } catch (err) {
      return false
    }
  },
  fetchAccessToken: () => {
    return fetch(`${nodeUrl}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        query: `
                query refreshToken {
                    refreshToken
                  }
                `,
      }),
    })
  },
  handleFetch: accessToken => {
    setAccessToken(accessToken)
  },
  handleError: err => {
    // console.warn('Your refresh token is invalid. Try to relogin')
    // console.error(err)
    sessionStorage.clear()
    localStorage.clear()
    navigateTo(AUTH_PATH.LOGIN)
  },
})

const errHandler = onError(({ graphQLErrors, networkError, response }) => {
  // all graphQl error will be thrown here
  //network error will navigate to error page
  console.log('zara zya')
  if (networkError) {
    // apiNum = 0
    // setLoading(false)
    navigateTo(AUTH_PATH.NOT_FOUND)
  } else {
    // if jwt expired error thrown then just prompt user to try again
    if (
      graphQLErrors &&
      graphQLErrors[0]?.message.toLowerCase().includes('expired'.toLowerCase())
    ) {
      setSnackBar('Please try again')
    } else {
      // all other situtations should just throw any msg returned from API
      setSnackBar(graphQLErrors[0].message)
    }

    if (
      graphQLErrors[0]?.message
        .toLowerCase()
        .includes('not authenticated'.toLowerCase()) ||
      graphQLErrors[0]?.message
        .toLowerCase()
        .includes('Token is not valid, please try login again'.toLowerCase()) ||
      graphQLErrors[0]?.message
        .toLowerCase()
        .includes('Token is blacklisted, please login again'.toLowerCase())
    ) {
      sessionStorage.clear()

      //when token is empty it will auto navigate to login page
      setAccessToken('')
      navigateTo(AUTH_PATH.LOGIN)
    }
  }
})

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken =
            sessionStorage.getItem('resetPasswordToken') !== null
              ? sessionStorage.getItem('resetPasswordToken')
              : getAccessToken()
          setLoading(true)
          apiNum++
          if (accessToken) {
            const projectid = localStorage.getItem('lastestProject')
            operation.setContext({
              headers: {
                authorization: `Bearer ${accessToken}`,
                projectid: projectid,
              },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const responseData = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (apiNum > 0) {
      --apiNum
    }
    let resultResponse = JSON.parse(JSON.stringify(response))
    if (apiNum === 0) {
      setLoading(false)
    }
    return resultResponse
  })
})

const uploadLink = createUploadLink({
  uri: nodeUrl,
  credentials: 'include',
})

const httpLink = new HttpLink({
  uri: nodeUrl,
  credentials: 'include',
})

const cache = new InMemoryCache({})

export const client = new ApolloClient({
  link: ApolloLink.from([
    errHandler,
    requestLink,
    responseData,
    uploadLink as any as ApolloLink | RequestHandler,
    refreshTokenLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
})
