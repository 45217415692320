import GoogleReCaptchaWrapper from 'containers/app/GoogleReCaptchaWrapper'
import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const Login = lazy(() => import('./Login'))
const Logout = lazy(() => import('./Logout'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))
const NotFound = lazy(() => import('./NotFound'))
const NewPassword = lazy(() => import('./NewPassword'))
const Register = lazy(() => import('./Register'))

export const AUTH_PATH = {
  LOGIN: '/authentication/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/authentication/forgot',
  NOT_FOUND: '/authentication/404-not-found',
  NEW_PASSWORD: '/authentication/new-pwd',
  REGISTER: '/authentication/register',
}

const AuthRoutes: RouteObject[] = [
  {
    path: AUTH_PATH.LOGIN,
    element: <GoogleReCaptchaWrapper />,
  },
  {
    path: AUTH_PATH.LOGOUT,
    element: <Logout />,
  },
  {
    path: AUTH_PATH.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: AUTH_PATH.NOT_FOUND,
    element: <NotFound />,
  },
  {
    path: AUTH_PATH.NEW_PASSWORD,
    element: <NewPassword />,
  },
  {
    path: AUTH_PATH.REGISTER,
    element: <Register />,
  },
]

export default AuthRoutes
