import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Container,
  Footer,
  Dialog,
  Subheader,
  SubheaderText,
  MenuItem,
  RowGrid,
} from '@ifca-ui/core'
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Box,
  Button,
} from '@mui/material'
import AppContext, { AppContextProps } from 'containers/context/Context'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router'
import * as Yup from 'yup'
import MuiPhoneNumber from 'material-ui-phone-number'
import {
  useWaitlistSaveMutation,
  useGetBookingFormMasterDataQuery,
  useWaitlistByIdQuery,
  useGetUnitBookedListQuery,
} from 'generated/graphql'
import { getName, getAgentDetail } from 'UserDetail'
import { navigationService } from 'navigationService'
import { PhoneValidationService } from '../../helper/phoneValidationHelper'
import { TranslationServices } from 'translateService'
import { WAITINGLIST_PATH } from './Routes'
import useNav from 'component/RouteService/useNav'
import Webcam from 'react-webcam'
import Tesseract from 'tesseract.js'

interface newWaitListForm {
  companyPurchase: boolean
  purchaserName: string
  companyName: string
  companyRegNo: string
  directorName: string

  nationality: string
  NRICNo: string
  email: string
  mobileNo: string
  unitNo: string
}

const NewWaitList = () => {
  const { navBack } = useNav()
  const nav = new navigationService()
  let historyItem = nav.getSessionStorage()
  const previousUrl = historyItem[historyItem.length - 2]?.path
  const pathName = useLocation()
  useEffect(() => {
    if (nav.getSearchValue() !== null) {
      nav.updateState(nav.getSearchValue())
    }
  }, [pathName])
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const navigate = useNavigate()
  const [isEdited, setIsEdited] = useState(false)
  const projectid = localStorage.getItem('lastestProject')
  const validationSchema = Yup.object().shape({
    purchaserName: Yup.string().required('Agent name is required'),
    companyName: Yup.string().required('Company name is required'),
    companyRegNo: Yup.string().required(
      'Company registration number is required'
    ),
    directorName: Yup.string().required('Director name is required'),
    NRICNo: Yup.string()
      .required('NRIC No. is required')
      .email('NRIC No. is invalid'),
    email: Yup.string()
      .required('Email address is required')
      .email('Email address is invalid'),
    mobileNo: Yup.string()
      .required('Mobile No. is required')
      .test(`isValidPhoneNo`, 'Mobile No. is Invalid', value => {
        let validateResult = PhoneValidationService.isValidPoneNumber(
          PhoneValidationService.validatePhonePrefix(value)
        )
        return validateResult
      }),
    unitNo: Yup.string()
      .required('Unit No. is required')
      .email('Unit No. is invalid'),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm<newWaitListForm>({
    defaultValues: {
      companyPurchase: null,
      purchaserName: '',
      companyName: '',
      companyRegNo: '',
      directorName: '',

      nationality: '',
      unitNo: '',
      NRICNo: '',
      email: '',
      mobileNo: '+60',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  })

  const { data: { GetAllMasterInfo } = { nationalities: null } } =
    useGetBookingFormMasterDataQuery({
      variables: { projectId: projectid },
      onError: error => {
        error.graphQLErrors.forEach(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  function backPage() {
    navigate(`${previousUrl}`)
    nav.backPreviousPage()
  }

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: getName(),
          title: getAgentDetail(),
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 1,
            current: 'New Waiting List',
          },
        },
      },
    })
  }, [getName(), getAgentDetail(), isEdited])

  const {
    data: { waitingListById: waitingListId } = { waitingListById: null },
  } = useWaitlistByIdQuery({
    variables: {
      waitlistId: projectid,
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const [newWaitList] = useWaitlistSaveMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      rootDispatch({
        type: 'snackBar',
        payload: {
          open: true,
          message: TranslationServices.Translate(
            'New Waiting List Added Successfully'
          ),
        },
      })
      navigate(WAITINGLIST_PATH.WAITINGLIST)
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const onSubmit = (data: newWaitListForm) => {
    newWaitList({
      variables: {
        input: {
          is_company: data?.companyPurchase,
          full_name: data?.purchaserName,
          nationality_id: data?.nationality,
          email: data?.email,
          phone_no: data?.mobileNo,
          unit_id: data?.unitNo,
        },
      },
    })
  }

  useEffect(() => {
    if (
      dirtyFields.companyPurchase ||
      dirtyFields.purchaserName ||
      dirtyFields.companyName ||
      dirtyFields.companyRegNo ||
      dirtyFields.directorName ||
      dirtyFields.nationality ||
      dirtyFields.NRICNo ||
      dirtyFields.email ||
      dirtyFields.mobileNo ||
      dirtyFields.unitNo
    )
      setIsEdited(true)
    else setIsEdited(false)
  }, [
    dirtyFields.companyPurchase ||
      dirtyFields.purchaserName ||
      dirtyFields.companyName ||
      dirtyFields.companyRegNo ||
      dirtyFields.directorName ||
      dirtyFields.nationality ||
      dirtyFields.NRICNo ||
      dirtyFields.email ||
      dirtyFields.mobileNo ||
      dirtyFields.unitNo,
  ])

  const [closeDialog, setCloseDialog] = React.useState(false)
  const CloseDialog = (
    <Dialog
      fullWidth={true}
      open={closeDialog}
      onClose={() => setCloseDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Exit Confirmation
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box>
          <Box width="100%" boxSizing="border-box" marginBottom="14px">
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-xsTitle"
            >
              Are you sure you want to exit? Your changes will not be saved.
            </Typography>
          </Box>
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'secondary',
            onClick: () => setCloseDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => {
              navigate(`${previousUrl}`)
              nav.backPreviousPage()
            },
          },
        ],
      }}
    />
  )

  // region Checkbox option

  const isCompanyPurc = useWatch({ control, name: 'companyPurchase' })

  // region end

  // region Scan id

  const [name, setName] = useState('')
  const [isScanning, setIsScanning] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [scanResult, setScanResult] = useState('')

  const webcamRef = React.useRef(null)

  const startScanning = () => {
    setIsScanning(true)
  }

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImageSrc(imageSrc)
    setIsScanning(false)
    performOCR(imageSrc)
  }

  const performOCR = (imageSrc: any) => {
    setIsProcessing(true)
    Tesseract.recognize(imageSrc, 'eng', {
      logger: m => console.log(m),
    }).then(({ data: { text } }) => {
      setScanResult(text)
      const extractedName = extractNameFromText(text)
      setName(extractedName)
      setIsProcessing(false)
    })
  }

  const extractNameFromText = (text: any) => {
    const nameRegex = /Name:?\s*([A-Za-z\s]+)/
    const match = text.match(nameRegex)
    return match ? match[1] : ''
  }

  // region end

  return (
    <>
      {CloseDialog}
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth>
              <Container
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Container sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    label={'Company Purchase'}
                    control={
                      <Controller
                        name="companyPurchase"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                  />
                </Container>
              </Container>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={startScanning}
                  disabled={isScanning || isProcessing}
                  style={{
                    padding: '4px 8px',
                    fontSize: '12px',
                    height: '36px',
                    width: '100px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Scan ID
                </Button>
              </Box>

              {isScanning && (
                <div>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    videoConstraints={{
                      facingMode: 'environment',
                    }}
                  />
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={captureImage}
                      style={{ marginRight: '10px' }}
                    >
                      Capture
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setIsScanning(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </RowGrid>

            {!isCompanyPurc && (
              <Controller
                control={control}
                name="purchaserName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.purchaserName ? true : false}
                    helperText={errors.purchaserName?.message}
                    label="Purchaser Name as per NRIC"
                    type="text"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    required
                  />
                )}
              />
            )}

            {isCompanyPurc && (
              <>
                <Controller
                  control={control}
                  name="companyName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.companyName ? true : false}
                      helperText={errors.companyName?.message}
                      label="Company Name"
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="companyRegNo"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.companyRegNo ? true : false}
                      helperText={errors.companyRegNo?.message}
                      label="Company Registration No."
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="directorName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.directorName ? true : false}
                      helperText={errors.directorName?.message}
                      label="Director Name"
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                    />
                  )}
                />
              </>
            )}

            <Controller
              control={control}
              name="nationality"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onChange={e => {
                    onChange(e?.target?.value)
                  }}
                  onBlur={onBlur}
                  value={value || ''}
                  label="Nationality"
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  name="nationality"
                  select
                  required
                >
                  {GetAllMasterInfo?.Nationalities?.map(
                    (nationalities, index) => (
                      <MenuItem key={index} value={nationalities?.id}>
                        {nationalities?.name}
                      </MenuItem>
                    )
                  )}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="NRICNo"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.NRICNo ? true : false}
                  helperText={errors.NRICNo?.message}
                  label="NRIC No."
                  type="text"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  required
                />
              )}
            />

            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="10px">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                    label="Email"
                    type="email"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="mobileNo"
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    disableAreaCodes={true}
                    defaultCountry={'my'}
                    variant="standard"
                    fullWidth
                    margin="normal"
                    label="Mobile No."
                    value={field.value}
                    error={errors.mobileNo ? true : false}
                    helperText={errors.mobileNo?.message}
                    required
                    autoFormat={false}
                    type="tel"
                  />
                )}
              />
            </RowGrid>

            <Controller
              control={control}
              name="unitNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onChange={e => {
                    onChange(e?.target?.value)
                  }}
                  onBlur={onBlur}
                  value={value || ''}
                  label="Unit No"
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  name="unitNo"
                  select
                  required
                >
                  <MenuItem value={waitingListId?.unit?.unitNo}>
                    {waitingListId?.unit?.unitNo}
                  </MenuItem>
                </TextField>
              )}
            />
          </Card>
        </form>
      </Container>
      <Footer
        buttons={[
          {
            children: 'Save',
            color: 'primary',
            onClick: handleSubmit(onSubmit),
          },
        ]}
      />
    </>
  )
}

export default NewWaitList
