import { RouteObject } from 'react-router-dom'
import UnitListing from './UnitListing'
import BookingList from './BookingList'
import NewBooking from './NewBooking'
import BookingDetails from './BookingDetails'
import BookingPurchaserDocuments from './BookingPurchaserDocuments'
import JoinBuyerList from './JointBuyerListing'
import BookingFee from './BookingFee'
import ShareELettering from './ShareELettering'
import EditPurchaser from './EditPurchaser'
import AddJointBuyer from './AddJointBuyer'
import EditJointBuyer from './EditJointBuyer'

export const BOOKING_LIST_PATH = {
  UNITLISTING: '/book/unit',
  BOOKINGLIST: '/book/booking-list',
  NEWBOOKING: '/book/new-booking',
  BOOKINGDETAILS: '/book/booking-detail',
  BOOKINGPURCHASERDOCUMENTS: '/book/purchaser-doc',
  JOINBUYERLIST: '/book/joint-buyer-list',
  BOOKINGFEE: '/book/fee-details',
  SHAREELETTERING: '/book/share-lettering',
  EDITPURCHASER: '/book/edit-purchaser',
  ADDJOINTBUYER: '/book/new-joint-buyer',
  EDITJOINTBUYER: '/book/edit-joint-buyer',
}

const BookingListRoutes: RouteObject[] = [
  {
    path: BOOKING_LIST_PATH.UNITLISTING,
    element: <UnitListing />,
  },
  {
    path: BOOKING_LIST_PATH.BOOKINGLIST,
    element: <BookingList />,
  },
  {
    path: BOOKING_LIST_PATH.NEWBOOKING,
    element: <NewBooking />,
  },
  {
    path: BOOKING_LIST_PATH.BOOKINGDETAILS,
    element: <BookingDetails />,
  },
  {
    path: BOOKING_LIST_PATH.BOOKINGPURCHASERDOCUMENTS,
    element: <BookingPurchaserDocuments />,
  },
  {
    path: BOOKING_LIST_PATH.JOINBUYERLIST,
    element: <JoinBuyerList />,
  },
  {
    path: BOOKING_LIST_PATH.BOOKINGFEE,
    element: <BookingFee />,
  },
  {
    path: BOOKING_LIST_PATH.SHAREELETTERING,
    element: <ShareELettering />,
  },
  {
    path: BOOKING_LIST_PATH.EDITPURCHASER,
    element: <EditPurchaser />,
  },
  {
    path: BOOKING_LIST_PATH.ADDJOINTBUYER,
    element: <AddJointBuyer />,
  },
  {
    path: BOOKING_LIST_PATH.EDITJOINTBUYER,
    element: <EditJointBuyer />,
  },
]

export default BookingListRoutes
