import {
  Container,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  useMenuOption,
  SubContainerX,
  Footer,
  RowFlex,
  EmptyState,
} from '@ifca-ui/core'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, Checkbox, IconButton, Typography } from '@mui/material'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetBrochureListQuery,
  useGetBrochureListLazyQuery,
  useProjectDetailLazyQuery,
} from 'generated/graphql'
import { Children, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router'
import { getName, getAgentDetail } from 'UserDetail'
import { navigationService } from 'navigationService'
import { TranslationServices } from 'translateService'
import { AUTH_PATH } from '../Authentication/Routes'
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar'
// @ts-ignore
import { saveAs } from 'file-saver'
import { EmailFormType } from '../Sales Gallery/SalesGallery'
import EmailEditor from '../Sales Gallery/EmailEditor'
import { getPathCookie } from 'containers/helper/PathCookie'
import { formatDate } from 'containers/helper/formatDate'
import { HOME_PATH } from '../Home/Routes'
import { SwitchProjectDialog } from 'component/SwitchProjectDialog'
import React from 'react'

const Brochure = () => {
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)
  const nav = new navigationService()
  let historyItem = nav.getSessionStorage()
  const stateValue = historyItem[historyItem.length - 1]?.state
  let navigate = useNavigate()

  const { projectid }: any = useParams()

  const [selectedBrochures, setSelectedBrochures] = useState<string[]>([])

  const [isAllSelected, setIsAllSelected] = useState(false)

  const [getProjectDetails, { data: projectDetailsData }] =
    useProjectDetailLazyQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
    })

  const [searchInput, setSearchInput] = useState('')
  const pathName = useLocation()
  useEffect(() => {
    if (nav.getSearchValue() !== null) {
      nav.updateState(nav.getSearchValue())
    }
  }, [pathName])
  useEffect(() => {
    if (stateValue !== null || stateValue !== '') {
      setSearchInput(stateValue)
    }
  }, [stateValue])

  useEffect(() => {
    // if (!projectid) {
    //   navigate(AUTH_PATH.NOT_FOUND)
    // } else {
    getBrochureList({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
    })
    // variables: {
    //   projectid: projectid,
    // },
    // }
  }, [])

  const {
    refetch: refetchProjectQuery,
    data: { getBrochureList: brochureList } = { getBrochureList: null },
  } = useGetBrochureListQuery({
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const [getBrochureList, { data: brochureListData }] =
    useGetBrochureListLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
    })
  // const [openSnackbar] = useSnackbar()

  function resetSearchInput() {
    setSearchInput('')
    nav.setSearchValue('')
  }

  // const handleDownloadBrochure = (brochure: any) => {
  //   if (brochure.attachment.resourceUrl && brochure.attachment.fileName) {
  //     saveAs(brochure.attachment.resourceUrl, brochure.attachment.fileName)
  //   } else {
  //     openSnackbar('Sales Document Not Found.')
  //   }
  // }

  // const handleSelectAll = () => {
  //   if (isAllSelected) {
  //     setSelectedBrochures([])
  //   } else {
  //     const allIds = brochureListData?.getBrochureList.map(
  //       brochure => brochure.id
  //     )
  //     setSelectedBrochures(allIds)
  //   }
  //   setIsAllSelected(!isAllSelected)
  // }

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedBrochures([])
    } else {
      const allIds =
        brochureListData?.getBrochureList.map(brochure => brochure.id) || []
      setSelectedBrochures(allIds)
    }
    setIsAllSelected(!isAllSelected)
  }

  const handleSelect = (id: any) => {
    setSelectedBrochures(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(item => item !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  // const handleWhatsApp = () => {
  //   const message = `Check out these brochures: ${selectedBrochures.join(', ')}`
  //   const url = `https://wa.me/?text=${encodeURIComponent(message)}`
  //   window.open(url, '_blank')
  // }

  // const handleEmail = () => {
  //   const subject = 'Brochures'
  //   const body = `Check out these brochures: ${selectedBrochures.join(', ')}`
  //   window.location.href = `mailto:?subject=${encodeURIComponent(
  //     subject
  //   )}&body=${encodeURIComponent(body)}`
  // }

  const baseUrl = window.location.href.substring(
    0,
    window.location.href.search(window.location.pathname)
  )

  const [whatsappTo, setWhatsappTo] = useState({ whatsappContent: '' })

  const [isOpenEmailEditor, setIsOpenEmailEditor] = useState(false)
  const [emailForm, setEmailForm] = useState<EmailFormType>({
    toEmails: [],
    subject: '',
    message: '',
  })

  useEffect(() => {
    // if (!projectid) {
    //   navigate(AUTH_PATH.NOT_FOUND)
    // } else {
    getProjectDetails({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      // variables: { projectid: projectid },
    })
    // }
  }, [])

  useEffect(() => {
    if (projectDetailsData) {
      const link = `${baseUrl}/${getPathCookie()}/p?id=${
        projectDetailsData?.getProjectById?.id
      }`
      const whatsappContent = `Please find the link below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. ${link}`
      setWhatsappTo({ whatsappContent: whatsappContent })
      setEmailForm({
        toEmails: [],
        subject: `${projectDetailsData?.getProjectById?.name}`,
        message: `Greetings,<br /><br />Please find the links below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. <br /> <a href="${link}">${link}</a><br><br><br>Thank you.`,
      })
    }
  }, [
    projectDetailsData?.getProjectById?.id,
    baseUrl,
    projectDetailsData?.getProjectById?.name,
  ])

  // useEffect(() => {
  //   setEmailForm({
  //     toEmails: [],
  //     subject: `${projectDetailsData?.getProjectById?.name}`,
  //     message: `Greetings,<br /><br />Please find the links below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. <br /> <a href="${shareTo.link}">${shareTo.link}</a><br><br><br>Thank you.`,
  //   })
  // }, [isOpenEmailEditor])

  if (isOpenEmailEditor) {
    return (
      <EmailEditor
        emailForm={emailForm}
        setEmailForm={setEmailForm}
        setIsOpenEmailEditor={setIsOpenEmailEditor}
      />
    )
  }

  const [change, setChanges] = React.useState(false)

  const handleClickChangesDialog = () => {
    setChanges(true)
  }

  const handleCloseChangesDialog = () => {
    setChanges(false)
    nav.removeProject()
    refetchProjectQuery()
  }

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => {
              navigate(`${HOME_PATH.PROJECT_SUMMARY}/${projectid}`)
            },
          },
        },
        rightIcon: {
          icon: 'switch',
          props: {
            onClick: () => handleClickChangesDialog(),
          },
        },
        topSection: {
          smTitle: `${getName()}`,
          title: projectDetailsData?.getProjectById?.name,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 0,
            current: TranslationServices.Translate('.../ Sales Document'),
          },
        },
      },
    })
  }, [
    getName(),
    projectDetailsData?.getProjectById?.name,
    // !rootState.isDrawerOpen,
  ])

  return (
    <>
      <SubContainerX>
        <SearchBar
          title={
            <Typography
              className="text-mdLabel"
              component="span"
              variant="inherit"
            >
              {TranslationServices.Translate('Brochure Listing')} (
              <Typography
                className="text-mdLabel"
                color="primary.main"
                component="span"
                variant="inherit"
              >
                {brochureList?.length}
              </Typography>
              )
            </Typography>
          }
          searchInput={{
            value: searchInput,
            onChangeClearInput: () => {
              resetSearchInput()
            },
            onChange: (e: any) => setSearchInput(e.target.value),
            onKeyUp: (e: any) => nav.setSearchValue(e.currentTarget.value),
          }}
        />
        {brochureListData?.getBrochureList?.length > 0 && (
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
            <Typography sx={{ fontSize: '14px' }}>Select All</Typography>
          </label>
        )}
      </SubContainerX>

      <Container>
        <List>
          {brochureListData?.getBrochureList?.length === 0 ? (
            <EmptyState />
          ) : (
            brochureListData?.getBrochureList.map((brochure, index) => (
              <ListItem
                key={brochure?.id}
                secondaryAction={
                  <IconButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      handleClick(e, brochure?.id, index, brochure)
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <Checkbox
                  checked={selectedBrochures.includes(brochure?.id)}
                  onChange={() => handleSelect(brochure?.id)}
                />
                <ListItemText
                  primary={
                    <Typography
                      color="common.black"
                      component="div"
                      display="flex"
                      variant="inherit"
                    >
                      <Typography
                        className="text-icon text-xsTitle"
                        component="span"
                        flex="1"
                        variant="inherit"
                      >
                        {brochure?.docName}
                      </Typography>
                    </Typography>
                  }
                  secondary={
                    <Typography component="div">
                      <Typography
                        className="text-icon text-desc"
                        color="#454545"
                        component="div"
                      >
                        <Typography
                          className="text-icon text-desc"
                          color="#454545"
                          component="div"
                          variant="inherit"
                        >
                          {/* {TranslationServices.Translate('Upload Date: ') +
                            formatDate(brochure?.modTs)} */}

                          {brochure?.typeDescription}
                        </Typography>
                      </Typography>
                    </Typography>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </Container>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            // handleDownloadBrochure(selectedBrochures)
            handleClose()
          }}
        >
          {TranslationServices.Translate('Download')}
        </MenuItem>
      </Menu>
      {/* <RowFlex> */}
      {brochureListData?.getBrochureList?.length > 0 && (
        <Footer
          buttons={[
            {
              children: TranslationServices.Translate('Whatsapp'),
              color: 'primary',
              onClick: () => {
                window.open(
                  `https://wa.me/?text=${encodeURIComponent(
                    whatsappTo.whatsappContent
                  )}`,
                  '_blank'
                )
              },
              'aria-controls': 'default-menu',
            },
            {
              children: TranslationServices.Translate('Email'),
              color: 'primary',
              onClick: () => {
                setIsOpenEmailEditor(true)
              },
              'aria-controls': 'default-menu',
            },
          ]}
        />
      )}
      {/* </RowFlex> */}
      {/* <Button
          onClick={() =>
            window.open(
              `https://wa.me/?text=${encodeURIComponent(
                shareTo.whatsappContent
              )}`,
              '_blank'
            )
          }
        >
          WhatsApp
        </Button>
        <Button
          onClick={() => {
            setIsOpenEmailEditor(true)
          }}
        >
          Email
        </Button> */}
      <SwitchProjectDialog
        open={change}
        onClose={handleCloseChangesDialog}
        name="Brochure"
        currProjectId={projectid}
        page="Brochure"
      />
    </>
  )
}

export default Brochure
