import {
  Card,
  Container,
  EmptyState,
  RowGrid,
  SubheaderText,
} from '@ifca-ui/core'
import { Box, Button, IconButton, Link, Typography } from '@mui/material'
import { TopSectionHeader } from 'component/HeaderSection'
import useNav from 'component/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useProjectSummaryQuery,
  useBookingFeeDetailQuery,
  useGetMultiELetterBySaleIdQuery,
} from 'generated/graphql'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

const BookingFee = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const saleId = queryParams.get('saleId')

  // region Project Header

  const { data: { getProjectById: projectName } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Booking Fee Details',
          },
        },
      },
    })
  }, [projectName])

  // region end

  // region Booking Fee Listing

  const {
    data: { GetBookingDetail: BookingDetail } = { GetBookingDetail: null },
  } = useBookingFeeDetailQuery({
    variables: {
      saleId: saleId,
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const attachments = BookingDetail?.bookingFeeAttachments

  const {
    data: { getMultiELetterBySaleId: MultiELetterDetail } = {
      getMultiELetterBySaleId: null,
    },
  } = useGetMultiELetterBySaleIdQuery({
    variables: {
      saleId: saleId,
    },
    onCompleted: data => {
      console.log('Letter: ', data?.getMultiELetterBySaleId)
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  return (
    <>
      <Container>
        <Card
          className="card-container-detail"
          header={{
            title: (
              <SubheaderText
                primary={
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      color="FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Booking Fee Details
                    </Typography>
                  </Box>
                }
              />
            ),
          }}
        >
          <Box>
            {BookingDetail ? (
              <Typography className="text-icon value-f">
                <Box marginBottom={2}>
                  <Box>
                    <span style={{ color: '#454545', fontSize: '10px' }}>
                      Total Booking Fee
                    </span>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                      {BookingDetail?.project?.currency +
                        ' ' +
                        new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                          Number(BookingDetail?.bookingFeeInfo?.amount)
                        )}
                    </Typography>
                  </Box>

                  <Box>
                    <span style={{ color: '#454545', fontSize: '10px' }}>
                      Payment To
                    </span>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                      {BookingDetail?.projectCompany?.name}
                    </Typography>
                  </Box>

                  <Box>
                    <span style={{ color: '#454545', fontSize: '10px' }}>
                      Developer Address
                    </span>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                      {BookingDetail?.projectCompany?.address?.address}
                    </Typography>
                  </Box>

                  <Box>
                    <span style={{ color: '#454545', fontSize: '10px' }}>
                      Developer Contact
                    </span>
                    <Typography
                      component="a"
                      href={`tel:${BookingDetail?.projectCompany?.officeTel}`}
                      sx={{
                        textDecoration: 'underline',
                        color: 'orange',
                        display: 'block',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      {BookingDetail?.projectCompany?.officeTel}
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            ) : (
              <EmptyState />
            )}
          </Box>
        </Card>

        <Card
          className="card-container-detail"
          header={{
            title: (
              <SubheaderText
                primary={
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      color="FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Payment
                    </Typography>
                  </Box>
                }
              />
            ),
          }}
        >
          <Box>
            {BookingDetail ? (
              <Typography className="text-icon value-f">
                <Box marginBottom={2}>
                  <Box>
                    <span style={{ color: '#454545', fontSize: '10px' }}>
                      Payment Method
                    </span>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                      {BookingDetail?.bookingFeeInfo?.paymentMethodDesc}
                    </Typography>
                  </Box>

                  <RowGrid gridTemplateColumns="1fr 1fr">
                    <Box>
                      <span style={{ color: '#454545', fontSize: '10px' }}>
                        Paid Amount
                      </span>
                      <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        {BookingDetail?.project?.currency +
                          ' ' +
                          new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            Number(BookingDetail?.bookingFeeInfo?.amount)
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <span style={{ color: '#454545', fontSize: '10px' }}>
                        Reference No
                      </span>
                      <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                        {BookingDetail?.bookingFeeInfo?.refNo}
                      </Typography>
                    </Box>
                  </RowGrid>

                  <Box>
                    <span style={{ color: '#454545', fontSize: '10px' }}>
                      Attachment
                    </span>
                    <Typography
                      component="div"
                      sx={{
                        textDecoration: 'underline',
                        color: 'orange',
                        display: 'block',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      {(attachments && attachments.length > 0) ||
                      (MultiELetterDetail && MultiELetterDetail.length > 0) ? (
                        <>
                          {attachments && attachments.length > 0 && (
                            <>
                              {attachments.map((attachment, index) => {
                                const fileExtension = attachment?.fileName
                                  ?.split('.')
                                  .pop()
                                  ?.toLowerCase()
                                const isImageFile = [
                                  'png',
                                  'jpg',
                                  'jpeg',
                                  'gif',
                                ].includes(fileExtension)

                                return (
                                  <Typography
                                    key={index}
                                    sx={{ fontSize: '12px', fontWeight: 600 }}
                                  >
                                    <a
                                      href={attachment?.resourceUrl}
                                      download={attachment?.fileName}
                                      style={{
                                        textDecoration: 'underline',
                                        color: 'orange',
                                      }}
                                      onClick={e => {
                                        if (!isImageFile) {
                                          e.preventDefault()
                                          const fileUrl =
                                            attachment?.resourceUrl
                                          const pdfUrl = fileUrl
                                            ? `${fileUrl.replace(
                                                /\.[^/.]+$/,
                                                ''
                                              )}.pdf`
                                            : ''
                                          window.location.href = pdfUrl
                                        }
                                      }}
                                    >
                                      {attachment?.fileName}
                                    </a>
                                  </Typography>
                                )
                              })}
                            </>
                          )}

                          {MultiELetterDetail &&
                            MultiELetterDetail.length > 0 && (
                              <>
                                {MultiELetterDetail.map(
                                  (letterDetail, index) => (
                                    <div key={index}>
                                      {letterDetail.letter &&
                                        letterDetail.letter.length > 0 &&
                                        letterDetail.letter.map(
                                          (letter, letterIndex) => {
                                            const fileExtension =
                                              letterDetail?.letterTypeName ||
                                              'pdf'
                                            const downloadLink = `/path/to/your/files/${
                                              letter?.name || 'default.'
                                            }${fileExtension}`

                                            return (
                                              <Typography key={letterIndex}>
                                                <a
                                                  href={downloadLink}
                                                  style={{
                                                    textDecoration: 'underline',
                                                    color: 'orange',
                                                    fontSize: '12px',
                                                    fontWeight: 600,
                                                  }}
                                                  download
                                                >
                                                  {letter?.name ||
                                                    'Download File'}
                                                </a>
                                              </Typography>
                                            )
                                          }
                                        )}
                                    </div>
                                  )
                                )}
                              </>
                            )}
                        </>
                      ) : (
                        <Typography>No Attachment Available</Typography>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            ) : (
              <EmptyState />
            )}
          </Box>
        </Card>
      </Container>
    </>
  )
}

export default BookingFee
