import { parsePhoneNumber } from "libphonenumber-js";

export class PhoneValidationService {

    /**
     * 
     * @param contactNo to verify
     * @returns boolean
     */
    static isValidPoneNumber(contactNo: string) {
        try {
            let phoneNumber = parsePhoneNumber(contactNo);
            return phoneNumber?.isValid();
        } catch (error) {
            return false;
        }

    }

    /**
   * 
   * @param contactNo to include "+" if missing
   * @returns contactNo
   */
    static validatePhonePrefix(contactNo: string) {
        if (contactNo.charAt(0) !== "+") {
            contactNo = `+${contactNo}`
        }
        return contactNo
    }

    /**
    * 
    * @param contactNo to format e.g. +60 11-1111-111 to +60111111111
    * @returns formatted phone number
    */
    static formatPhoneNumber(contactNo: string) {
        let formattedPhoneNumber = contactNo.replace(/[\s-]+/g, '');
        return formattedPhoneNumber;
    }

}