import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const LoanList = lazy(() => import('./LoanList'))
const LoanDetail = lazy(() => import('./LoanDetail'))
const BankDetail = lazy(() => import('./BankDetail'))
const PurchaserDocuments = lazy(() => import('./PurchaserDocuments'))

export const LOAN_PATH = {
  LOAN_LIST: '/loan/loan-list',
  LOAN_DETAIL: '/loan/loan-detail',
  BANK_DETAIL: '/loan/bank-detail',
  PURCHASER_DOCUMENTS: '/loan/purchaser-doc',
}

const LoanRoutes: RouteObject[] = [
  {
    path: `${LOAN_PATH.LOAN_LIST}/:projectid`,
    element: <LoanList />,
  },
  {
    path: `${LOAN_PATH.LOAN_DETAIL}/:saleId/:projectid`,
    element: <LoanDetail />,
  },
  {
    path: `${LOAN_PATH.BANK_DETAIL}/:loanid/:projectid`,
    element: <BankDetail />,
  },
  {
    path: `${LOAN_PATH.PURCHASER_DOCUMENTS}/:loanid`,
    element: <PurchaserDocuments />,
  },
]

export default LoanRoutes
