import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

// const AgentNegiotiator = lazy(() => import('../Agent/AgentNegotiator'))
const AgentList = lazy(() => import('../Agent/AgentList'))
const AgentDetail = lazy(() => import('../Agent/AgentDetail'))
const NewAgent = lazy(() => import('../Agent/AddAgent'))
const EditAgent = lazy(() => import('../Agent/EditAgent'))

export const AGENT_PATH = {
  AGENT_LIST: '/agent/agent-list',
  AGENT_DETAIL: '/agent/agent-details',
  NEW_AGENT: '/agent/new-agent',
  EDIT_AGENT: '/agent/edit-agent',
}

const AgentRoutes: RouteObject[] = [
  {
    path: 'agent-list',
    element: <AgentList />,
  },
  {
    path: `agent-details/:contactId`,
    element: <AgentDetail />,
  },
  {
    path: `new-agent`,
    element: <NewAgent />,
  },
  {
    path: `edit-agent/:contactId`,
    element: <EditAgent />,
  },
]

export default AgentRoutes
