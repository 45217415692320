import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AUTH_PATH } from '../Authentication/Routes'
import React, { useEffect, useRef, useState } from 'react'
import {
  useProjectDetailQuery,
  useProjectDetailLazyQuery,
} from 'generated/graphql'
// @ts-ignore
import { useSnackbar } from 'react-simple-snackbar'
import { getPathCookie } from '../../../containers/helper/PathCookie'
import EmailEditor from './EmailEditor'
import { Card, ColumnFlex, Container, RowFlex, RowGrid } from '@ifca-ui/core'
import ProjectSwiper from './ProjectSwiper'
import {
  ContentCopyRounded,
  EmailRounded,
  GridOnRounded,
  LocationOnRounded,
  VisibilityRounded,
} from '@mui/icons-material'
import { format } from 'date-fns'
import Button from '@mui/material/Button'
import TextX from 'component/TextField/TextX'
import parse from 'html-react-parser'
import FloorPlanSwiper from './FloorPlanSwiper'
import UnitLayoutSwiper from './UnitLayoutSwiper'
import GoogleMapLogo from '../../../assets/images/icon/google_map.svg'
import WazeLogo from '../../../assets/images/icon/waze.svg'
import DefaultCompanyImage from '../../../assets/images/weatherbg.jpg'
import WhatsappIcon from '../../../assets/images/whatsapp.png'
import { Avatar, Icon, Button as Mui_Button } from '@mui/material'
import SimpleDialog from '../../../component/Dialog/SimpleDialog'
import QRCode from 'react-qr-code'
import { SALESGALLERY_PATH } from './Routes'
import { SALESDOCUMENT_PATH } from '../Sales Document/Routes'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { HOME_PATH } from '../Home/Routes'
import { getName } from 'UserDetail'
import { SwitchProjectDialog } from 'component/SwitchProjectDialog'
import { navigationService } from 'navigationService'
import { TranslationServices } from 'translateService'
import { BOOKING_LIST_PATH } from '../Booking List/Routes'

export type EmailFormType = {
  toEmails: string[]
  subject: string
  message: string
}

const SalesGallery = () => {
  const baseUrl = window.location.href.substring(
    0,
    window.location.href.search(window.location.pathname)
  )
  const { projectid }: any = useParams()
  const navigate = useNavigate()
  // const [openSnackbar, closeSnackbar] = useSnackbar()
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [isZoomImage, setIsZoomImage] = useState(false)
  const [getProjectDetails, { data: projectDetailsData }] =
    useProjectDetailLazyQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
    })

  const {
    refetch: refetchProjectQuery,
    data: { getProjectById: project } = { getProjectById: null },
  } = useProjectDetailQuery({
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const [shareTo, setShareTo] = useState({
    link: '',
    whatsappContent: '',
    qrCodeUrl: '',
  })
  const [isOpenEmailEditor, setIsOpenEmailEditor] = useState(false)
  const [emailForm, setEmailForm] = useState<EmailFormType>({
    toEmails: [],
    subject: '',
    message: '',
  })

  useEffect(() => {
    if (projectDetailsData) {
      const link = `${baseUrl}/${getPathCookie()}/p?id=${projectDetailsData
        ?.getProjectById?.id}`
      const whatsappContent = `Please find the link below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. ${link}`
      setShareTo({
        link: link,
        whatsappContent: whatsappContent,
        qrCodeUrl: link,
      })
      setEmailForm({
        toEmails: [],
        subject: `${projectDetailsData?.getProjectById?.name}`,
        message: `Greetings,<br /><br />Please find the links below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. <br /> <a href="${link}">${link}</a><br><br><br>Thank you.`,
      })
    }
  }, [
    projectDetailsData?.getProjectById?.id,
    baseUrl,
    projectDetailsData?.getProjectById?.name,
  ])

  useEffect(() => {
    setEmailForm({
      toEmails: [],
      subject: `${projectDetailsData?.getProjectById?.name}`,
      message: `Greetings,<br /><br />Please find the links below to view ${projectDetailsData?.getProjectById?.name}'s Sales Gallery. <br /> <a href="${shareTo.link}">${shareTo.link}</a><br><br><br>Thank you.`,
    })
  }, [isOpenEmailEditor])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareTo.link)
    // openSnackbar('Copied to clipboard')
  }

  useEffect(() => {
    if (!projectid) {
      navigate(AUTH_PATH.NOT_FOUND)
    } else {
      getProjectDetails({
        // variables: { projectid: projectid },
      })
    }
  }, [])

  const handleJumpToBrochure = () => {
    navigate(`${SALESDOCUMENT_PATH.BROCHURE}?project_id=${projectid}`)
  }

  const handleJumpToMortgage = () => {
    navigate(
      `${
        SALESGALLERY_PATH.MORTGAGE
      }?project_id=${projectid}&project_name=${encodeURIComponent(
        projectDetailsData?.getProjectById?.name || ''
      )}&project_currency=${encodeURIComponent(
        projectDetailsData?.getProjectById?.currency || ''
      )}`
    )
  }

  if (isOpenEmailEditor) {
    return (
      <EmailEditor
        emailForm={emailForm}
        setEmailForm={setEmailForm}
        setIsOpenEmailEditor={setIsOpenEmailEditor}
      />
    )
  }

  const nav = new navigationService()
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const accordianReference = useRef<HTMLDivElement | null>(null)
  const [change, setChanges] = React.useState(false)

  const handleClickChangesDialog = () => {
    setChanges(true)
  }

  const handleCloseChangesDialog = () => {
    setChanges(false)
    nav.removeProject()
    refetchProjectQuery()
  }

  useEffect(() => {
    if (accordianReference.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: accordianReference.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => {
              navigate(`${HOME_PATH.PROJECT_SUMMARY}/${projectid}`)
            },
          },
        },
        rightIcon: {
          icon: 'switch',
          props: {
            onClick: () => handleClickChangesDialog(),
          },
        },
        topSection: {
          smTitle: getName(),
          title: projectDetailsData?.getProjectById?.name,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 1,
            current: TranslationServices.Translate('Sales Gallery'),
          },
        },
      },
    })
  }, [getName(), projectDetailsData?.getProjectById?.name])

  const hasMedia =
    projectDetailsData?.getProjectById &&
    (projectDetailsData.getProjectById.galleryImage?.length > 0 ||
      projectDetailsData.getProjectById.embededYoutubeLink?.length > 0 ||
      projectDetailsData.getProjectById.virtualModel?.length > 0)

  return (
    <>
      <ColumnFlex fullWidth gap="24px">
        {hasMedia && <ProjectSwiper projectDetailsData={projectDetailsData} />}

        <Card>
          <Container padding={{ all: '12px' }} width="100%">
            <ColumnFlex gap="12px">
              <span className="fs-16 fw-700 color-text text-with-shadow">
                {projectDetailsData?.getProjectById?.name}
              </span>
              <RowFlex
                className="fw-600 fs-12 color-text-2"
                crossAxisAlignment="center"
                gap="4px"
              >
                <LocationOnRounded fontSize="inherit" />
                <span className="fs-10">
                  {projectDetailsData?.getProjectById?.city +
                    ', ' +
                    projectDetailsData?.getProjectById?.state || '-'}
                </span>
              </RowFlex>
              {/* <RowFlex
                className="fw-600 fs-14 color-text-2"
                crossAxisAlignment="center"
                gap="4px"
              > */}
              {/* {projectDetailsData?.getProjectById?.unitMinBuiltUp && (
                  <span className="fs-10">
                    fr.
                    {Number.isInteger(
                      parseFloat(
                        projectDetailsData?.getProjectById?.unitMinBuiltUp
                      )
                    )
                      ? Math.round(
                          parseFloat(
                            projectDetailsData?.getProjectById?.unitMinBuiltUp
                          )
                        ).toLocaleString('en-US')
                      : parseFloat(
                          projectDetailsData?.getProjectById?.unitMinBuiltUp
                        ).toLocaleString('en-US')}{' '}
                    {projectDetailsData?.getProjectById?.uom === 'SQ_METER' &&
                      'sq.m.'}
                    {projectDetailsData?.getProjectById?.uom === 'SQ_FEET' &&
                      'sq.ft.'}
                    {projectDetailsData?.getProjectById?.uom !== 'SQ_METER' &&
                      projectDetailsData?.getProjectById?.uom !== 'SQ_FEET' &&
                      projectDetailsData?.getProjectById?.uom}
                  </span>
                )}
                {!projectDetailsData?.getProjectById?.unitMinBuiltUp && (
                  <span className="fs-10">-</span>
                )} */}
              {/* </RowFlex> */}
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex gap="2px">
                  <span className="fw-500 fs-10 color-text-2">Township</span>
                  <span className="fs-12 fw-600 color-text">
                    {projectDetailsData?.getProjectById?.townshipName || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex gap="2px">
                  <span className="fw-500 fs-10 color-text-2">Type</span>
                  <span className="fs-12 fw-600 color-text">
                    {projectDetailsData?.getProjectById?.projectType?.name ||
                      '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>

              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex gap="2px">
                  <span className="fw-500 fs-10 color-text-2">Land Type</span>
                  <span className="fs-12 fw-600 color-text">
                    {projectDetailsData?.getProjectById?.landTypeDesc || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex gap="2px">
                  <span className="fw-500 fs-10 color-text-2">Tensure</span>
                  <span className="fs-12 fw-600 color-text">
                    {projectDetailsData?.getProjectById?.tenureDesc || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>

              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex gap="2px">
                  <span className="fw-500 fs-10 color-text-2">Built-up</span>
                  {projectDetailsData?.getProjectById?.unitMinBuiltUp &&
                    projectDetailsData?.getProjectById?.unitMaxBuiltUp && (
                      <span className="fs-12 fw-600 color-text">
                        {'From '}
                        <span>
                          fr.
                          {Number.isInteger(
                            parseFloat(
                              projectDetailsData?.getProjectById?.unitMinBuiltUp
                            )
                          )
                            ? Math.round(
                                parseFloat(
                                  projectDetailsData?.getProjectById
                                    .unitMinBuiltUp
                                )
                              ).toLocaleString('en-US')
                            : parseFloat(
                                projectDetailsData?.getProjectById
                                  ?.unitMinBuiltUp
                              ).toLocaleString('en-US')}{' '}
                          {projectDetailsData?.getProjectById?.uom ===
                            'SQ_METER' && 'sq.m.'}
                          {projectDetailsData?.getProjectById?.uom ===
                            'SQ_FEET' && 'sq.ft.'}
                          {projectDetailsData?.getProjectById?.uom !==
                            'SQ_METER' &&
                            projectDetailsData?.getProjectById?.uom !==
                              'SQ_FEET' &&
                            projectDetailsData?.getProjectById?.uom}
                        </span>
                        {' - '}
                        <span>
                          fr.
                          {Number.isInteger(
                            parseFloat(
                              projectDetailsData?.getProjectById?.unitMaxBuiltUp
                            )
                          )
                            ? Math.round(
                                parseFloat(
                                  projectDetailsData?.getProjectById
                                    .unitMaxBuiltUp
                                )
                              ).toLocaleString('en-US')
                            : parseFloat(
                                projectDetailsData?.getProjectById
                                  ?.unitMaxBuiltUp
                              ).toLocaleString('en-US')}{' '}
                          {projectDetailsData?.getProjectById?.uom ===
                            'SQ_METER' && 'sq.m'}
                          {projectDetailsData?.getProjectById?.uom ===
                            'SQ_FEET' && 'sq.ft'}
                          {projectDetailsData?.getProjectById?.uom !==
                            'SQ_METER' &&
                            projectDetailsData?.getProjectById?.uom !==
                              'SQ_FEET' &&
                            projectDetailsData?.getProjectById?.uom}
                        </span>
                      </span>
                    )}
                  {!projectDetailsData?.getProjectById?.unitMinBuiltUp &&
                    !projectDetailsData?.getProjectById?.unitMaxBuiltUp && (
                      <span className="fs-12 fw-600 color-text">-</span>
                    )}
                </ColumnFlex>
                <ColumnFlex gap="2px">
                  <span className="fw-500 fs-10 color-text-2">
                    Completion Year
                  </span>
                  <span className="fs-12 fw-600 color-text">
                    {projectDetailsData?.getProjectById?.completionDate
                      ? format(
                          new Date(
                            projectDetailsData?.getProjectById?.completionDate
                          ),
                          'yyyy'
                        )
                      : '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>

              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex gap="2px">
                  <span className="fw-500 fs-10 color-text-2">Launch Date</span>
                  <TextX
                    dataType="Date"
                    data={projectDetailsData?.getProjectById?.launchDate}
                    className="fs-12 fw-600 color-text"
                  />
                </ColumnFlex>
              </RowGrid>
              <RowGrid
                gridTemplateColumns="1fr 1fr"
                gap="5px"
                className="p-t-10"
              >
                <Button
                  onClick={handleJumpToBrochure}
                  color="inherit"
                  sx={{
                    backgroundColor: 'orange',
                    height: '20px',
                    marginTop: '18px',
                  }}
                >
                  <VisibilityRounded fontSize="inherit" />
                  <span className="p-h-10">Brochure</span>
                </Button>
                <Button
                  onClick={handleJumpToMortgage}
                  color="inherit"
                  sx={{
                    backgroundColor: 'orange',
                    height: '20px',
                    marginTop: '18px',
                  }}
                >
                  <GridOnRounded fontSize="inherit" />
                  <span className="p-h-10">Mortgage</span>
                </Button>
              </RowGrid>
            </ColumnFlex>
          </Container>
        </Card>

        {projectDetailsData?.getProjectById?.desc && (
          <Card>
            <Container padding={{ all: '12px' }} fontSize="11px">
              <ColumnFlex>
                <span className="fs-10 fw-500 color-text-2">Description</span>
                <ColumnFlex gap="8px" className="page-details-content">
                  {projectDetailsData?.getProjectById?.desc &&
                    parse(projectDetailsData?.getProjectById?.desc)}
                </ColumnFlex>
              </ColumnFlex>
            </Container>
          </Card>
        )}

        {projectDetailsData?.getProjectById?.floorGalleryImage?.length > 0 && (
          <FloorPlanSwiper projectDetailsData={projectDetailsData} />
        )}

        {projectDetailsData?.getProjectById?.unitLayoutGalleryImage?.length >
          0 && <UnitLayoutSwiper projectDetailsData={projectDetailsData} />}

        <ColumnFlex gap="20px">
          {(projectDetailsData?.getProjectById?.projectAddress ||
            projectDetailsData?.getProjectById?.projectGps) && (
            <Card sx={{ overflow: 'hidden', marginTop: '20px' }}>
              <iframe
                width={'100%'}
                height="300px"
                src={`https://maps.google.com/?q=${projectDetailsData?.getProjectById?.projectGps}&output=embed`}
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
              ></iframe>
              <Container
                // bgcolor="rgba(0,0,0,.7803921568627451)"
                width={'100%'}
                className="color-white fw-700 fs-12"
              >
                <ColumnFlex gap="2px">
                  <span className="color-white fw-700 fs-12">
                    Project Location
                  </span>
                  <span className="color-text-2 fw-500 fs-10">
                    {projectDetailsData?.getProjectById?.projectAddress}
                  </span>

                  <RowFlex gap="10px">
                    <img
                      src={GoogleMapLogo}
                      width={'25px'}
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                            projectDetailsData?.getProjectById?.projectGps
                          )}`
                        )
                      }
                    />
                    <img
                      src={WazeLogo}
                      width={'25px'}
                      onClick={() =>
                        window.open(
                          `https://www.waze.com/ul?ll=${encodeURIComponent(
                            projectDetailsData?.getProjectById?.projectGps
                          )}`
                        )
                      }
                    />
                  </RowFlex>
                </ColumnFlex>
              </Container>
            </Card>
          )}

          {(projectDetailsData?.getProjectById?.saleGalleryAddress ||
            projectDetailsData?.getProjectById?.saleGalleryGps) && (
            <Card sx={{ overflow: 'hidden' }}>
              <iframe
                width={'100%'}
                height="300px"
                src={`https://maps.google.com/?q=${projectDetailsData?.getProjectById?.saleGalleryGps}&output=embed`}
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
              ></iframe>
              <Container
                // bgcolor="rgba(0,0,0,.7803921568627451)"
                width={'100%'}
                className="color-white fw-700 fs-12"
              >
                <ColumnFlex gap="2px">
                  <span className="color-white fw-700 fs-12">
                    Sales Gallery Location
                  </span>
                  <span className="color-text-2 fw-500 fs-10">
                    {projectDetailsData?.getProjectById?.saleGalleryAddress}
                  </span>

                  <RowFlex gap="10px">
                    <img
                      src={GoogleMapLogo}
                      width={'25px'}
                      onClick={() => {
                        const gps =
                          projectDetailsData?.getProjectById?.saleGalleryGps
                        if (gps) {
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              gps
                            )}`
                          )
                        } else {
                          console.log('GPS information is not available.')
                        }
                      }}
                    />
                    <img
                      src={WazeLogo}
                      width={'25px'}
                      onClick={() => {
                        const gps =
                          projectDetailsData?.getProjectById?.saleGalleryGps
                        if (gps) {
                          window.open(
                            `https://www.waze.com/ul?ll=${encodeURIComponent(
                              gps
                            )}`
                          )
                        } else {
                          console.log('GPS information is not available.')
                        }
                      }}
                    />
                  </RowFlex>
                </ColumnFlex>
              </Container>
            </Card>
          )}
        </ColumnFlex>

        <Card sx={{ marginTop: '20px' }}>
          <Container className={'divider-bottom-border'}>
            <RowFlex gap="16px" crossAxisAlignment="center">
              <div className="click-able" onClick={() => setIsZoomImage(true)}>
                <Avatar
                  src={
                    projectDetailsData?.getProjectById?.company?.imageUrl ||
                    DefaultCompanyImage
                  }
                  sx={{ bgcolor: 'transparent' }}
                />
              </div>
              <ColumnFlex>
                <span className="fw-700 fs-16 color-text text-with-shadow">
                  {projectDetailsData?.getProjectById?.company?.name}
                </span>
                <span className="fw-500 fs-10 color-text-2">
                  {projectDetailsData?.getProjectById?.company?.companyRegNo}
                </span>
              </ColumnFlex>
            </RowFlex>
          </Container>
          <Container
            paddingTop="14px"
            paddingLeft="16px"
            paddingRight="16px"
            paddingBottom="16px"
            className="fw-500 fs-10 color-text-2"
          >
            {projectDetailsData?.getProjectById?.company?.description}
          </Container>
        </Card>

        <div className="sales-gallery-footer">
          <RowFlex
            mainAxisAlignment="space-between"
            crossAxisAlignment="center"
            className={`fw-700 fs-14`}
          >
            <span className="color-text-1">
              {projectDetailsData?.getProjectById?.priceRange}
            </span>
            <Mui_Button
              className="disable-full-uppercase"
              sx={{ lineHeight: '1', padding: '12px 20px' }}
              variant="contained"
              onClick={() => {
                navigate(
                  `${BOOKING_LIST_PATH.UNITLISTING}?project_id=${projectid}`
                )
              }}
            >
              <span>
                {projectDetailsData?.getProjectById?.projectSpec[0]
                  .reservationAgency
                  ? 'Registered Interest'
                  : 'Book Now'}
              </span>
            </Mui_Button>
          </RowFlex>
        </div>
        {openShareDialog && (
          <SimpleDialog
            visible={openShareDialog}
            onClose={() => setOpenShareDialog(false)}
          >
            <ColumnFlex gap="10px">
              <span className="color-text text-with-shadow fs-16 fw-700">
                Share To
              </span>
              <RowFlex gap="10px">
                {/* <Avatar
                  style={{ backgroundColor: "transparent" }}
                  src={
                    projectDetailsData?.getProjectById?.firstGalleryImage.url ||
                    DefaultCompanyImage
                  }
                  square
                  size={"large"}
                /> */}
                <Avatar
                  src={
                    projectDetailsData?.getProjectById?.firstGalleryImage
                      ?.url || DefaultCompanyImage
                  }
                  variant="rounded"
                  sx={{
                    bgcolor: 'transparent',
                    width: '60px',
                    height: '60px',
                  }}
                />
                <ColumnFlex gap="2px">
                  <span className="fw-600 fs-12 color-text">
                    {projectDetailsData?.getProjectById?.name}
                  </span>
                  <RowFlex gap="6px" crossAxisAlignment="flex-start">
                    <LocationOnRounded
                      sx={{ width: '12px', height: '12px' }}
                      color="primary"
                      className="page-details-dialog-company-logo"
                    />
                    <span className="fw-600 fs-10 color-text-2">
                      {projectDetailsData?.getProjectById?.saleGalleryAddress ||
                        '-'}
                    </span>
                  </RowFlex>
                  <RowFlex gap="0px" crossAxisAlignment="center">
                    <Button
                      // rounded
                      onClick={() =>
                        window.open(
                          `https://wa.me/?text=${encodeURIComponent(
                            shareTo.whatsappContent
                          )}`,
                          '_blank'
                        )
                      }
                    >
                      <img src={WhatsappIcon} alt="whatsapp" width={21} />
                    </Button>
                    <Button
                      // rounded
                      onClick={() => {
                        setIsOpenEmailEditor(true)
                        setOpenShareDialog(false)
                      }}
                    >
                      <EmailRounded color="primary" />
                    </Button>
                  </RowFlex>
                </ColumnFlex>
              </RowFlex>
              <ColumnFlex gap="10px" fullWidth crossAxisAlignment="center">
                <ColumnFlex gap="0px" fullWidth mainAxisAlignment="flex-start">
                  <span className="fw-600 fs-10 color-text-2">
                    Click to copy link
                  </span>
                  <RowFlex
                    crossAxisAlignment="center"
                    className="click-able"
                    fullWidth
                  >
                    <span
                      className="fs-12 color-text fw-700 click-text"
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                      onClick={copyToClipboard}
                    >
                      {shareTo.link}
                    </span>
                    <div onClick={copyToClipboard}>
                      <ContentCopyRounded fontSize="small" />
                    </div>
                  </RowFlex>
                </ColumnFlex>
                <QRCode value={shareTo.qrCodeUrl} size={150} />
                <span className="color-text-1 fs-12 fw-500">
                  Scan the QR code to view more detail.
                </span>
              </ColumnFlex>
            </ColumnFlex>
          </SimpleDialog>
        )}
      </ColumnFlex>
      <SwitchProjectDialog
        open={change}
        onClose={handleCloseChangesDialog}
        name="Sales Gallery"
        currProjectId={projectid}
        page="SalesGallery"
      />
    </>
  )
}

export default SalesGallery
