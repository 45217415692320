import { FooterButton, FooterFixedRoot, Wrap } from '@ifca-ui/core'
import { Box, BoxProps, ButtonProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useRef, useState } from 'react'

interface textProps {
  label: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

interface lineProps {
  line: textProps[]
}

interface customFooterProps extends BoxProps {
  text: lineProps[]
  textLineProps?: BoxProps
  buttons?: ButtonProps[]
  showBottomLine?: boolean
  heightOnChange?: (height: number) => void
}

export const FooterButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  @media screen and (min-width: 600px) {
    justify-content: flex-end;
    .MuiButtonBase-root {
      margin-left: 18px;
    }
  }
`

export const CustomFooter = ({
  buttons,
  text,
  textLineProps,
  showBottomLine = true,
  heightOnChange,
  ...props
}: customFooterProps) => {
  const onClick = elementOnclick => {
    if (elementOnclick && typeof elementOnclick === 'function') {
      elementOnclick()
    }
  }

  const footerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  //listen to window size on change
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // reset height
  useEffect(() => {
    const footer = footerRef?.current
    setHeight(footer?.clientHeight || 0)

    if (heightOnChange && typeof heightOnChange === 'function') {
      heightOnChange(footer?.clientHeight || 0)
    }
  }, [footerRef?.current, buttons, windowSize, text, textLineProps])

  return (
    <>
      <FooterFixedRoot
        id="footer"
        ref={footerRef}
        className="custom-footer"
        sx={{ boxShadow: 2 }}
        {...props}
      >
        <FooterButtonContainer>
          {/* <Typography component="div" {...textLineProps}> */}
          <Typography
            component="div"
            ref={footerRef}
            {...textLineProps}
            className={`custom-footer-text ${
              showBottomLine && 'footer-bottom-line'
            }`}
          >
            {text?.map(el => {
              return (
                <Typography component="div" className="footer-text">
                  {el?.line?.map(a => {
                    return (
                      <Typography
                        component="div"
                        className={`status-cookies-label`}
                        onClick={e => {
                          e.stopPropagation()
                          onClick(a?.onClick)
                        }}
                      >
                        {a?.label || '-'}
                      </Typography>
                    )
                  })}
                </Typography>
              )
            })}
          </Typography>
          {/* </Typography> */}
          <Typography component="div" className="custom-footer-button">
            <Wrap colGap="10px" rowGap="10px">
              {buttons?.map((button, buttonIndex) => (
                <FooterButton
                  key={buttonIndex.toString()}
                  variant="contained"
                  {...button}
                  sx={{
                    margin:
                      buttons?.length === 1 ? '0 auto !important' : 'unset',
                  }}
                />
              ))}
            </Wrap>
          </Typography>
        </FooterButtonContainer>
      </FooterFixedRoot>

      <div
        style={{
          height: `${height}px`,
        }}
      />
    </>
  )
}
