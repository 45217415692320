import { RouteObject } from 'react-router-dom'
import SalesGallery from './SalesGallery'
import Mortgage from './Mortgage'

export const SALESGALLERY_PATH = {
  SALESGALLERY: '/dashboards/sales-gallery',
  MORTGAGE: '/dashboards/sales-gallery/mortgage',
}

const SalesGalleryRoutes: RouteObject[] = [
  {
    path: `${SALESGALLERY_PATH.SALESGALLERY}/:projectid`,
    element: <SalesGallery />,
  },
  {
    path: SALESGALLERY_PATH.MORTGAGE,
    element: <Mortgage />,
  },
]

export default SalesGalleryRoutes
