import {
  Chip,
  Container,
  Dialog,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  Subheader,
  SubheaderText,
  useMenuOption,
  EmptyState,
  SubContainerX,
  SelectionBar,
  ColumnFlex,
  RowFlex,
  RowGrid,
} from '@ifca-ui/core'
import {
  Add as AddIcon,
  DateRange,
  HouseOutlined,
  Person,
  Star,
} from '@mui/icons-material'
import EmailIcon from '@mui/icons-material/Email'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import StarIcon from '@mui/icons-material/Star'
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait'
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { Filter } from 'containers/helper/searchFilterHelper'
import {
  useWaitlistByProjectQuery,
  useGetWaitingListUnitDetailsQuery,
  useGetBookingUnitDetailsQuery,
  useProjectSummaryQuery,
  useWaitlistByUnitQuery,
  WaitlistLeaveQueueDocument,
} from 'generated/graphql'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router'
import React from 'react'
import { useFuseSearch } from 'containers/helper/hooks/useSearch'
import useNav from 'component/RouteService/useNav'
import { TopSectionHeader } from 'component/HeaderSection'
import { formatDate } from 'containers/helper/formatDate'
import { amtStr } from 'containers/helper/numFormatter'
import { HOME_PATH } from '../Home/Routes'
import { useMutation } from '@apollo/client/react/hooks/useMutation'
import { WAITINGLIST_PATH } from './Routes'
import { navigationService } from 'navigationService'
import { SwitchProjectDialog } from 'component/SwitchProjectDialog'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'

const WaitingList = () => {
  const { navBack, navTo, navData } = useNav()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const projectid = localStorage.getItem('lastestProject')
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const [searchInput, setSearchInput] = React.useState('')
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [filterStatus, setFilterStatus] = useState<
    'ALL' | 'AVAILABLE' | 'UNAVAILABLE'
  >('ALL')
  const [limit, setLimit] = useState(30)
  const [page, setPage] = useState(1)
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null)
  const selectionRef = useRef(null)
  const { anchorEl, menu, handleClick: openMenu, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)
  let navigate = useNavigate()
  const filterOptions = [
    {
      id: 'ALL',
      label: 'All',
    },
    {
      id: 'AVAILABLE',
      label: 'Available',
    },
    {
      id: 'UNAVAILABLE',
      label: 'Unavailable',
    },
  ]
  useState<any>([])

  const nav = new navigationService()
  let historyItem = nav.getSessionStorage()

  const [change, setChanges] = React.useState(false)

  const handleClickChangesDialog = () => {
    setChanges(true)
  }
  const handleCloseChangesDialog = () => {
    setChanges(false)
    nav.removeProject()
    refetchProjectQuery()
  }

  const {
    refetch: refetchProjectQuery,
    data: { getProjectById: project } = { getProjectbyId: null },
  } = useProjectSummaryQuery({
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // Dialog here

  const [openUnitInfoDialog, setOpenUnitInfoDialog] = useState(false)

  const {
    data: { waitingListByProject: list } = { waitingListByProject: null },
    loading,
    error,
  } = useWaitlistByProjectQuery({
    variables: { limit, page, search: searchInput, statusFilter: filterStatus },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message,
          },
        })
      })
    },
  })

  const [unitID, setUnitID] = useState('')

  const [leaveQueue] = useMutation(WaitlistLeaveQueueDocument)
  const [reason, setReason] = useState('')
  const [selectedWaitlistId, setSelectedWaitlistId] = useState(null)

  const handleClick = (e: any, unitId: any, index: any) => {
    setUnitID(unitId)
    openMenu(e)
  }

  const { data: { GetUnitDetails: Unit } = { GetUnitDetails: null } } =
    useGetBookingUnitDetailsQuery({
      variables: { unitId: unitID },
      skip: !unitID,
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    if (navData) {
      setFilterStatus(navData?.['filterStatus'] || 'ALL')
      setSearchInput(navData?.['searchInput'] || '')
      handleSearch(navData?.['searchInput'] || '', ['name'])
    }
  }, [navData])

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget)
  }
  const statusMenuOpen = Boolean(statusAnchorEl)
  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null)
  }
  const handleStatusMenuItemClick = (
    status: 'ALL' | 'AVAILABLE' | 'UNAVAILABLE'
  ) => {
    setFilterStatus(status)
    setStatusAnchorEl(null)
  }

  const searchValue = (x: any) => {
    handleSearch(x, ['name'])
  }

  const handleSearchInputChange = (value: any) => {
    setSearchInput(value)
    searchValue(value)
  }

  const handleStatusChange = (status: any) => {
    setFilterStatus(status)
  }

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          icon: 'switch',
          props: {
            onClick: () => handleClickChangesDialog(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${project?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Waiting List',
          },
        },
      },
    })
  }, [project])

  // Leaves Queue Here

  const handleLeaveQueue = async () => {
    if (!reason) {
      alert('Please provide a reason for leaving the queue.')
      return
    }

    try {
      const { data } = await leaveQueue({
        variables: {
          input: {
            project_id: localStorage.getItem('lastestProject'),
            unit_id: unitID,
            waitlist_ids: [selectedWaitlistId],
            reason,
          },
        },
      })
      console.log('Successfully left queue:', data)
      window.location.reload()
    } catch (error) {
      console.error('Error leaving queue:', error)
      alert('Cannot leave the queue.')
    }
  }

  return (
    <>
      <SubContainerX>
        <Box width="100%" display="flex" mb="8px">
          <Box width="70%">
            <SearchBar
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  {`Waiting List`} (
                  <Typography
                    component="span"
                    variant="inherit"
                    className="search-bar-count-f"
                  >
                    {list?.data?.length ?? 0}
                  </Typography>
                  )
                </Typography>
              }
              searchInput={{
                value: searchInput,
                onChange: e => {
                  handleSearchInputChange(e.target.value)
                },
                onChangeClearInput: () => {
                  handleSearchInputChange('')
                },
              }}
              isAdvanceSearch={false}
            />
          </Box>

          <Box width="30%" pl="6px" ref={selectionRef}>
            <SelectionBar
              title={
                <Typography
                  component="span"
                  className="text-mdLabel"
                  variant="inherit"
                >
                  {filterOptions?.find(el => el.id === filterStatus)?.label}
                </Typography>
              }
              selectionItem={{
                onClick: e => handleStatusClick(e),
              }}
            />
          </Box>
        </Box>
        <Menu
          id="default-menu"
          divRef={selectionRef}
          anchorEl={statusAnchorEl}
          open={statusMenuOpen}
          onClose={handleStatusMenuClose}
          MenuListProps={{
            role: 'listbox',
          }}
        >
          {filterOptions?.map((el, index) => (
            <MenuItem key={el.id} onClick={() => handleStatusChange(el.id)}>
              {el.label}
            </MenuItem>
          ))}
        </Menu>
      </SubContainerX>

      <Container>
        <List>
          {list === undefined || list?.data?.length === 0 ? (
            <EmptyState />
          ) : (
            Filter(list?.data, searchInput)?.map((item: any, index: any) => {
              console.log('Item ID:', item?.unitId)
              return (
                <ListItem
                  key={item?.unitId}
                  secondaryAction={
                    <IconButton
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        handleClick(e, item?.unitId, index)
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                        color="common.black"
                      >
                        <Typography
                          component="span"
                          className="text-icon text-xsTitle"
                          variant="inherit"
                          flex="1"
                          onClick={() => {
                            const id = item?.unitId
                            setUnitID(id)
                            setOpenUnitInfoDialog(true)
                          }}
                        >
                          <HouseOutlined />
                          {item?.unitNo}
                        </Typography>

                        <Typography
                          component="span"
                          variant="inherit"
                          color="#060607"
                          className="text-icon text-desc"
                        ></Typography>
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          component="div"
                          display="flex"
                          variant="inherit"
                          color="common.black"
                        >
                          <Typography
                            component="span"
                            className="text-icon text-xsTitle"
                            variant="inherit"
                            flex="1"
                            color="#454545"
                          >
                            <Person />
                            {item?.contacts && item.contacts.length > 0
                              ? item.contacts
                                  .map((contact: any) =>
                                    item.isCompany
                                      ? `${contact.companyName} (${contact.fullName})`
                                      : contact.fullName
                                  )
                                  .join(', ')
                              : 'No Contacts'}
                          </Typography>

                          <Typography
                            component="span"
                            variant="inherit"
                            color="primary"
                            className="text-icon text-desc"
                          >
                            <LocalAtmIcon
                              sx={{
                                fontSize: '12px',
                                marginRight: '5px',
                              }}
                            />
                            <Tooltip
                              title="Selling Price"
                              id="sellingPriceTooltip"
                              placement="left-start"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: '#ff9800',
                                    color: 'black',
                                  },
                                },
                              }}
                            >
                              <div>{amtStr(item?.price)}</div>
                            </Tooltip>
                          </Typography>
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              )
            })
          )}
        </List>
      </Container>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            const unitId = unitID
            const projectId = projectid

            navigate(`${WAITINGLIST_PATH.WAITINGLISTDETAIL}?unitId=${unitId}`)
          }}
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            const reason = prompt(
              'Please enter a reason for leaving the queue:'
            )
            if (reason) {
              setReason(reason)
              handleLeaveQueue()
            }
          }}
        >
          Leave Queue
        </MenuItem>
      </Menu>
      <FloatButton
        color="primary"
        onClick={() => {
          navigate(`${WAITINGLIST_PATH.NEWWAITLIST}?project_id=${projectid}`)
        }}
      >
        <AddIcon />
      </FloatButton>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openUnitInfoDialog}
        onClose={() => setOpenUnitInfoDialog(false)}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Typography
                      className="text-mdTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Unit Info
                    </Typography>
                  </Box>
                }
              />
            </Subheader>
          </>
        }
        body={
          <>
            <ColumnFlex gap="12px">
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Block</span>
                  <span className="color-text fw-600 fs-12">
                    {Unit?.block || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Unit No</span>
                  <span className="color-text fw-600 fs-12">
                    {Unit?.unitNo || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">List Price</span>
                  <span className="color-text fw-600 fs-12">
                    {Unit?.price
                      ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(Unit.price)
                      : '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Type</span>
                  <span className="color-text fw-600 fs-12">
                    {Unit?.unitType?.name || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Build-up Area
                  </span>
                  <span className="color-text fw-600 fs-12">
                    {Unit?.builtUp + ' Sq.ft.' || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Direction</span>
                  <span className="color-text fw-600 fs-12">
                    {Unit?.facingDirection?.name || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Completion Year
                </span>
                <span className="color-text fw-600 fs-12">
                  {Unit?.project?.completionDate || '-'}
                </span>
              </ColumnFlex>
            </ColumnFlex>
          </>
        }
      />
      <SwitchProjectDialog
        open={change}
        onClose={handleCloseChangesDialog}
        name="Waiting List"
        currProjectId={projectid}
        page="WaitingList"
      />
    </>
  )
}

export default WaitingList
