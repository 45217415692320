import {
  Container,
  Dialog,
  EmptyState,
  List,
  ListItem,
  SearchBar,
  Spinner,
  Subheader,
  SubHeaderContainer,
  SubheaderText,
} from '@ifca-ui/core'
import { Box, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useProjectGetAllLazyQuery } from 'generated/graphql'
import { useFuseSearch } from 'containers/helper/hooks/useSearch'
import { HOME_PATH } from 'containers/modules/Home/Routes'
import { SALESGALLERY_PATH } from 'containers/modules/Sales Gallery/Routes'
import { SALESDOCUMENT_PATH } from 'containers/modules/Sales Document/Routes'
import { WAITINGLIST_PATH } from 'containers/modules/Waiting List/Routes'
import { LOAN_PATH } from 'containers/modules/Loan/Routes'
import { BUSINESS_INSIGHT_PATH } from 'containers/modules/Business Insight/Routes'
import { AUTHORIZED_SALES_AGENT_PATH } from 'containers/modules/Authorized Sales Agent/Routes'

export const SwitchProjectDialog = (props: any) => {
  let navigate = useNavigate()
  const {
    open,
    onClose,
    page,
    currProjectId,
    // setCurrProjectID,
    mode,
    name,
    transactionType,
  } = props

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [projectId, setProjectId] = React.useState('')
  const [search, setSearchInput] = React.useState('')

  ////////// Get Project //////////
  //#region graphQL

  // const { loading, data: { getAllProject } = { getAllProject: null } } =
  //   useProjectGetAllQuery({
  //     fetchPolicy: 'no-cache',
  //   })
  // //#endregion

  const [
    fetchProject,
    {
      loading: projectLoading,
      error: projectError,
      data: { getAllProject } = { getAllProject: [] },
    },
  ] = useProjectGetAllLazyQuery({
    onCompleted: data => {
      if (data.getAllProject && data.getAllProject?.length > 0) {
        if (!projectId) {
          if (setProjectId) {
            setProjectId(data?.getAllProject[0]?.id)
          }
        }
        setOriginalListing(data.getAllProject)
      }
    },
  })

  // useEffect(() => {
  //   if (getAllProject && getAllProject?.length > 0) {
  //     setOriginalListing(getAllProject)
  //   }
  // }, [getAllProject])

  useEffect(() => {
    if (open) {
      fetchProject()
      setSearchInput('')
      handleSearch('', ['name'])
    }
  }, [open])

  const handleClick = (id: string) => {
    if (currProjectId === id) return false
    let path: string = ''
    switch (page) {
      case 'ProjectSummary':
        path = `${HOME_PATH.PROJECT_SUMMARY}/${id}`
        break
      case 'SalesGallery':
        path = `${SALESGALLERY_PATH.SALESGALLERY}/${id}`
        break
      case 'Brochure':
        path = `${SALESDOCUMENT_PATH.BROCHURE}?project_id=${id}`
        break
      case 'BookingList':
        path = `${HOME_PATH.PROJECT_SUMMARY}/${id}`
        break
      case 'WaitingList':
        path = `${WAITINGLIST_PATH.WAITINGLIST}?project_id=${id}`
        break
      case 'LoanList':
        path = `${LOAN_PATH.LOAN_LIST}/${id}`
        break
      case 'AgencyTeamPerformance':
        path = `${BUSINESS_INSIGHT_PATH.AGENCY_TEAM_PERFORMANCE}/${id}`
        break
      case 'AuthorizedSalesAgent':
        path = `${AUTHORIZED_SALES_AGENT_PATH.AUTHORIZED_SALES_AGENT}/${id}`
        break
      default:
        path = `/dashboards/landing/${id}`
        break
    }
    setProjectId(id)
    localStorage.setItem('lastestProject', id)
    navigate(`${path}`)
    window.location.reload()
    onClose()
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        className={'switch-project-dialog'}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Box display="flex" margin="2px 0px" width="100%">
                      <Typography
                        className="text-xsTitle"
                        color="#FF9800"
                        component="span"
                        flex="1 1"
                        variant="inherit"
                      >
                        {`Switch ${name}`}
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </Subheader>
            <List
              style={{
                padding: '8px',
              }}
            >
              <SearchBar
                title={
                  <Typography
                    component="span"
                    className="text-mdLabel"
                    variant="inherit"
                  >
                    {`Project Listing`} (
                    <Typography
                      component="span"
                      variant="inherit"
                      className="text-mdLabel search-bar-count-f"
                    >
                      {filteredList?.length}
                    </Typography>
                    )
                  </Typography>
                }
                searchInput={{
                  value: search,
                  onChange: e => {
                    setSearchInput(e.target.value)
                    handleSearch(e.target.value, ['name'])
                  },
                  onChangeClearInput: () => {
                    setSearchInput('')
                    handleSearch('', ['name'])
                  },
                }}
              />
            </List>
          </>
        }
        body={
          <>
            <List>
              {filteredList?.length === 0 ? (
                <EmptyState title="No Record found" />
              ) : (
                <>
                  {filteredList?.map((item, index) => {
                    return (
                      <>
                        <ListItem
                          key={item.id}
                          onClick={() => handleClick(item.id)}
                          className={
                            projectId === item.id ? 'bg-blue-light' : ''
                          }
                        >
                          {/* <IconImg iconimg={CompanyLogo} /> */}
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                component="div"
                                display="flex"
                                variant="inherit"
                                color="common.black"
                              >
                                <Typography
                                  component="span"
                                  className="text-xsTitle"
                                  variant="inherit"
                                  flex="1"
                                >
                                  {item.name}
                                </Typography>
                              </Typography>
                            }
                            secondary={
                              <Typography
                                component="div"
                                display="flex"
                                variant="inherit"
                                color="common.black"
                              >
                                <Typography
                                  component="span"
                                  className="text-desc"
                                  variant="inherit"
                                >
                                  {item.city}, {item.state}
                                </Typography>
                              </Typography>
                            }
                          />
                        </ListItem>
                      </>
                    )
                  })}{' '}
                </>
              )}
            </List>
          </>
        }
        footer={{
          buttons: [
            {
              children: 'Close',
              color: 'primary',
              onClick: onClose,
            },
          ],
        }}
      />
    </>
  )
}
