import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const AgencyTeamPerformance = lazy(() => import('./AgencyTeamPerformance'))

export const BUSINESS_INSIGHT_PATH = {
  AGENCY_TEAM_PERFORMANCE: '/business-insight/agency-team-performance',
}

const BusinessInsightRoutes: RouteObject[] = [
  {
    path: `${BUSINESS_INSIGHT_PATH.AGENCY_TEAM_PERFORMANCE}/:projectid`,
    element: <AgencyTeamPerformance />,
  },
]

export default BusinessInsightRoutes
