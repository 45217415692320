import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const LandingComponent = lazy(() => import('./LandingComponent'))
const ProjectSummary = lazy(() => import('./ProjectSummary'))

export const HOME_PATH = {
  Root: '/',
  HOME: '/dashboards/landing',
  PROJECT_SUMMARY: '/dashboards/landing/project-summary',
}

const HomeRoutes: RouteObject[] = [
  {
    path: '',
    element: <LandingComponent />,
  },
  {
    path: 'dashboards/landing',
    element: <LandingComponent />,
  },
  {
    path: `${HOME_PATH.PROJECT_SUMMARY}/:projectid`,
    element: <ProjectSummary />,
  },
]

export default HomeRoutes
