import {
  Card,
  Container,
  ColumnFlex,
  RowGrid,
  RowFlex,
  Wrap,
} from '@ifca-ui/core'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useProjectSummaryQuery } from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import NumberFormatTextField from 'component/TextField/NumberFormat'
import TextX from 'component/TextField/TextX'
import { abbreviateNumber } from 'containers/helper/AbbreviateNumber'
import LensIcon from '@mui/icons-material/Lens'
import { PieChart } from '@mui/x-charts/PieChart'
import { NoEncryption } from '@mui/icons-material'
import useNav from 'component/RouteService/useNav'
import { TopSectionHeader } from 'component/HeaderSection'

const Mortgage = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const [priceError, setPriceError] = useState<string | undefined>()
  const [downPaymentError, setDownPaymentError] = useState<string | undefined>()
  const [downPercentError, setDownPercentError] = useState<string | undefined>()
  const [yearError, setYearError] = useState<string | undefined>()
  const [rateError, setRateError] = useState<string | undefined>()
  const [monthlyPay, setMonthlyPay] = useState<number>(0)
  const [principal, setPrincipal] = useState<number>(0)
  const [interest, setInterest] = useState<number>(0)

  // region Project Header

  const { data: { getProjectById: projectName } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Mortgage',
          },
        },
      },
    })
  }, [projectName])

  // region end

  const [formData, setFormData] = useState({
    price: 0.0,
    downPayment: 0.0,
    downPercent: 10.0,
    year: 35,
    rate: 3,
  })

  const handleChange = (name: any, value: any) => {
    setFormData({ ...formData, [name]: value })
  }

  useEffect(() => {
    const { price, downPercent } = formData
    const downPaymentValue = (price / 100) * downPercent
    setFormData({
      ...formData,
      price: price,
      downPayment: downPaymentValue,
      downPercent: downPercent,
    })
  }, [formData.price])

  useEffect(() => {
    const { price, downPayment } = formData
    const downPaymentPercent = (downPayment / price) * 100
    setFormData({
      ...formData,
      price: price,
      downPayment: downPayment,
      downPercent: downPaymentPercent,
    })
  }, [formData.downPayment])

  useEffect(() => {
    const { price, downPercent } = formData
    const downPaymentValue = (price / 100) * downPercent
    setFormData({
      ...formData,
      price: price,
      downPayment: downPaymentValue,
      downPercent: downPercent,
    })
  }, [formData.downPercent])

  useEffect(() => {
    if (
      priceError ||
      downPaymentError ||
      downPercentError ||
      yearError ||
      rateError
    ) {
      setMonthlyPay(0)
      setPrincipal(0)
      setInterest(0)

      return
    }

    const { price, downPayment, year, rate } = formData

    const principalValue = price - downPayment
    const rateValue = formData.rate / 1200
    const monthlyPayValue =
      (principalValue * rateValue) /
      (1 - Math.pow(1 / (1 + rateValue), formData.year * 12))
    const totalInterest =
      monthlyPayValue * (formData.year * 12) - principalValue

    setMonthlyPay(monthlyPayValue)
    setPrincipal(principalValue)
    setInterest(totalInterest)
  }, [formData])

  const { data: { getProjectById: project } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  return (
    <ColumnFlex fullWidth gap="40px">
      <Container>
        <Card>
          <ColumnFlex fullWidth gap="40px" className="neu-text-field-style">
            <ColumnFlex fullWidth gap="6px">
              <span className="fw-400 fs-12 color-text-2">Property Price</span>
              <NumberFormatTextField
                name="price"
                value={formData.price}
                onChange={(value: any) => handleChange('price', value)}
                onValidation={(value: any) => setPriceError(value.errorMessage)}
                required
                moreThan={0}
                objectName="Property Price"
                style={{ border: 'none', borderBottom: '1px solid #000' }}
              />
              {priceError && (
                <span className="fw-400 fs-12 color-red">{priceError}</span>
              )}
            </ColumnFlex>
            <RowGrid gridTemplateColumns="1fr 1fr" gap="20px">
              <ColumnFlex gap="6px" fullWidth>
                <span
                  className="fw-400 fs-12 color-text-2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Down Payment
                </span>
                <NumberFormatTextField
                  width={'50px'}
                  value={formData.downPayment}
                  objectName="Down Payment"
                  onChange={(value: any) => handleChange('downPayment', value)}
                  minValue={0}
                  onValidation={(value: any) =>
                    setDownPaymentError(value.errorMessage)
                  }
                  required
                  style={{
                    border: 'none',
                    borderBottom: '1px solid #000',
                    width: '100%',
                  }}
                  additionalValidate={(values: any) => {
                    const { floatValue } = values
                    if (floatValue > formData.price) {
                      return {
                        valid: false,
                        errorMessage: `Down Payment must less than Property Price`,
                      }
                    }
                    return { valid: true, errorMessage: undefined }
                  }}
                />
                {downPaymentError && (
                  <span className="fw-400 fs-12 color-red">
                    {downPaymentError}
                  </span>
                )}
              </ColumnFlex>
              <ColumnFlex gap="6px">
                <span
                  className="fw-400 fs-12 color-text-2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Percentage (%)
                </span>
                <NumberFormatTextField
                  value={formData.downPercent}
                  objectName="Percentage"
                  onChange={(value: any) => handleChange('downPercent', value)}
                  minValue={0}
                  maxValue={100}
                  onValidation={(err: any) =>
                    setDownPercentError(err.errorMessage)
                  }
                  required
                  style={{
                    border: 'none',
                    borderBottom: '1px solid #000',
                  }}
                />
                {downPercentError && (
                  <span className="fw-400 fs-12 color-red">
                    {downPercentError}
                  </span>
                )}
              </ColumnFlex>
            </RowGrid>

            <ColumnFlex fullWidth gap="6px">
              <span className="fw-400 fs-12 color-text-2">
                Loan Tenure (Years)
              </span>
              <NumberFormatTextField
                value={formData.year}
                objectName="Loan Tenure (Years)"
                onChange={(value: any) => handleChange('year', value)}
                minValue={1}
                onValidation={(err: any) => setYearError(err.errorMessage)}
                required
                decimalScale={0}
                style={{ border: 'none', borderBottom: '1px solid #000' }}
              />
              {yearError && (
                <span className="fw-400 fs-12 color-red">{yearError}</span>
              )}
            </ColumnFlex>

            <ColumnFlex fullWidth gap="6px">
              <span className="fw-400 fs-12 color-text-2">
                Interest Rate (%)
              </span>
              <NumberFormatTextField
                value={formData.rate}
                objectName="Interest Rate"
                onChange={(value: any) => handleChange('rate', value)}
                moreThan={0}
                maxValue={100}
                onValidation={(err: any) => setRateError(err.errorMessage)}
                required
                decimalScale={2}
                style={{ border: 'none', borderBottom: '1px solid #000' }}
              />
              {rateError && (
                <span className="fw-400 fs-12 color-red">{rateError}</span>
              )}
            </ColumnFlex>
          </ColumnFlex>
        </Card>
      </Container>

      <Container>
        <Card>
          <RowGrid gridTemplateColumns="1fr 1fr" gap="500px" fullWidth>
            <ColumnFlex gap="10px" className="fw-400 fs-16 color-text-1">
              <span className="fs-12">Estimation</span>
              <ColumnFlex gap="20px">
                <span>
                  {monthlyPay.toFixed(2).toString().length < 10 ? (
                    <TextX
                      data={Number(monthlyPay).toFixed(2)}
                      dataType="Number"
                      className="fw-700 fs-20 color-text"
                    />
                  ) : (
                    <span className="fw-700 fs-20 color-text">
                      {abbreviateNumber(Number(monthlyPay.toFixed(2)))}
                    </span>
                  )}{' '}
                  {'/month'}
                </span>
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <LensIcon sx={{ width: '10px', color: '#1976d2' }} />
                  <span>
                    {principal.toFixed(2).toString().length < 10 ? (
                      <TextX
                        data={Number(principal).toFixed(2)}
                        dataType="Number"
                      />
                    ) : (
                      <span>
                        {abbreviateNumber(Number(principal.toFixed(2)))}
                      </span>
                    )}{' '}
                    Principal
                  </span>
                </RowFlex>
                <RowFlex gap="6px" crossAxisAlignment="center">
                  <LensIcon sx={{ width: '10px', color: '#26dad2' }} />
                  <span>
                    {interest.toFixed(2).toString().length < 10 ? (
                      <TextX
                        data={Number(interest).toFixed(2)}
                        dataType="Number"
                      />
                    ) : (
                      <span>
                        {abbreviateNumber(Number(interest.toFixed(2)))}
                      </span>
                    )}{' '}
                    Interest
                  </span>
                </RowFlex>
              </ColumnFlex>
            </ColumnFlex>

            <ColumnFlex gap="10px">
              <Wrap rowGap="20px" colGap="8px">
                <RowFlex gap="4px" crossAxisAlignment="center">
                  <div
                    style={{
                      backgroundColor: '#1976d2',
                      width: '30px',
                      height: '12px',
                    }}
                  ></div>
                  <span className="color-text-1 fw-500 fs-12">Principal</span>
                </RowFlex>
                <RowFlex gap="4px" crossAxisAlignment="center">
                  <div
                    style={{
                      backgroundColor: '#26dad2',
                      width: '30px',
                      height: '12px',
                    }}
                  ></div>
                  <span className="color-text-1 fw-500 fs-12">Interest</span>
                </RowFlex>
              </Wrap>
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: principal,
                        color: '#1976d2',
                      },
                      {
                        id: 1,
                        value: interest,
                        color: '#26dad2',
                      },
                    ],
                  },
                ]}
                width={300}
                height={300}
              />
            </ColumnFlex>
          </RowGrid>
        </Card>
      </Container>
    </ColumnFlex>
  )
}

export default Mortgage
