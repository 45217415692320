import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Cancel } from '@mui/icons-material'
import pdfIcon from '../../../assets/images/icon/pdf-icon.png'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import { saveAs } from 'file-saver'
import {
  Box,
  ButtonBase,
  Button as Mui_Button,
  styled,
  Typography,
} from '@mui/material'
import {
  useProjectSummaryQuery,
  useBookingAttachmentsLazyQuery,
  useAddAttachmentsMutation,
  useDeleteAttachmentsMutation,
  useDownloadAttachmentMutation,
  useUploadNricPassportMutation,
} from 'generated/graphql'
import {
  AttachmentHelper,
  attachmentRefTypeList,
  acceptFileType,
  compressImage,
} from 'containers/helper/Attachment/attachmentHelper'
import {
  Card,
  ColumnFlex,
  Container,
  Dialog,
  RowFlex,
  RowGrid,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import { StackItem, StackX } from 'component/Layout/LayoutX/StackLayout'
import AppContext, { AppContextProps } from 'containers/context/Context'
import useNav from 'component/RouteService/useNav'
import { TopSectionHeader } from 'component/HeaderSection'
import React from 'react'

const docType = {
  bookingForm: 'BOOKING_FORM',
  customerIC: [
    'BOOKING_BUYER_NRIC_ATTACHMENT',
    'BOOKING_JOINT_BUYER_NRIC_ATTACHMENT',
    'BOOKING_BUYER_PASSPORT_ATTACHMENT',
  ],
  customerCreditReport: 'BOOKING_CCR_ATTACHMENT',
  pdpaConsent: 'LOAN_PDPA_CONSENT',
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const BookingPurchaserDocuments = () => {
  const navigation = useNavigate()

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const saleId = queryParams.get('saleId')

  type attachmentUploadType = {
    refType: string
    saleId: string
    file: File
  }
  const [attachmentData, setAttachmentData] =
    useState<attachmentUploadType | null>(null)
  const [addAttachments] = useAddAttachmentsMutation()
  const [uploadNricPassport] = useUploadNricPassportMutation()

  const [downloadAttachmentMutation] = useDownloadAttachmentMutation()

  const [deleteAttachments] = useDeleteAttachmentsMutation()
  const [deleteDialog, setDeleteDialog] = React.useState(false)

  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  // region Project Header

  const { data: { getProjectById: projectName } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Purchaser Document',
          },
        },
      },
    })
  }, [projectName])

  // region end

  // region Booking Attachments Listing

  const [getBookingAttachments, { data: bookingAttachmentsData }] =
    useBookingAttachmentsLazyQuery()

  useEffect(() => {
    getBookingAttachments({
      variables: {
        saleId: saleId,
      },
    })
  }, [saleId])

  // region end

  //region Upload Attachment

  const handleFileChange = async (event: any, saleId: any, refType: any) => {
    let file = event.target.files[0]
    if (!file) return
    const attachmentHelper = new AttachmentHelper([''], 25)

    if (file.type !== acceptFileType.PDF) {
      file = await compressImage(file)
    }

    setAttachmentData({
      saleId: saleId,
      refType: refType,
      file: file,
    })
  }

  useEffect(() => {
    if (
      attachmentData &&
      attachmentData.saleId &&
      attachmentData.refType &&
      attachmentData.file
    ) {
      uploadAttachment()
    }
  }, [attachmentData])

  const uploadAttachment = () => {
    if (
      !attachmentData ||
      !attachmentData.file ||
      !attachmentData.refType ||
      !attachmentData.saleId
    ) {
      return
    }
    addAttachments({
      variables: {
        input: {
          sale_id: attachmentData.saleId,
          ref_type: attachmentData.refType,
          attachments: [attachmentData.file],
        },
      },
      onCompleted: data => {
        if (data.addAttachments) {
          window.location.reload()
        }
      },
    })
  }

  const handleNRICFileChange = async (
    event: any,
    saleId: any,
    refType: any
  ) => {
    let file = event.target.files[0]
    if (!file) return
    const attachmentHelper = new AttachmentHelper([''], 25)

    if (file.type !== acceptFileType.PDF) {
      file = await compressImage(file)
    }

    setAttachmentData({
      saleId: saleId,
      refType: refType,
      file: file,
    })
  }

  useEffect(() => {
    if (
      attachmentData &&
      attachmentData.saleId &&
      attachmentData.refType &&
      attachmentData.file
    ) {
      uploadNricAttachment()
    }
  }, [attachmentData])

  const uploadNricAttachment = () => {
    if (!attachmentData || !attachmentData.file || !attachmentData.saleId) {
      return
    }

    const reader = new FileReader()

    reader.onloadend = () => {
      const base64 = reader.result as string
      const contentType = attachmentData.file.type
      const fileName = attachmentData.file.name

      uploadNricPassport({
        variables: {
          input: {
            sale_id: attachmentData.saleId, // Sale ID
            files: [
              {
                base64,
                content_type: contentType,
                file_name: fileName,
                identity_type: 'NRIC',
              },
            ],
          },
        },
        onCompleted: data => {
          if (data.uploadNricPassport) {
            window.location.reload()
          }
        },
      })
    }

    reader.readAsDataURL(attachmentData.file)
  }

  // region end

  // region Download Attachment

  const getAttachmentDetails = (attachmentId: string, docType: string) => {
    const attachmentList =
      docType === 'BOOKING_CCR'
        ? bookingAttachmentsData?.GetBookingDetail?.bookingAttachments
            ?.bookingCcrAttachments
        : docType === 'BOOKING_NRIC'
        ? bookingAttachmentsData?.GetBookingDetail?.bookingAttachments
            ?.bookingNricAttachments
        : docType === 'BOOKING_FORM'
        ? bookingAttachmentsData?.GetBookingDetail?.bookingAttachments
            ?.bookingFeeAttachments
        : docType === 'BOOKING_PDPA'
        ? bookingAttachmentsData?.GetBookingDetail?.bookingAttachments
            ?.bookingPdpaAttachments
        : []

    const attachment = attachmentList?.find(item => item.id === attachmentId)

    if (attachment) {
      return {
        attachmentName: `${attachmentId}.pdf`,
        attachmentUrl: attachment.resourceUrl,
      }
    }

    return {
      attachmentName: `${attachmentId}.pdf`,
      attachmentUrl: '',
    }
  }

  const downloadAttachment = (attachmentId: string, docType: string) => {
    const { attachmentName, attachmentUrl } = getAttachmentDetails(
      attachmentId,
      docType
    )

    downloadAttachmentMutation({
      variables: {
        input: {
          attachmentName,
          attachmentUrl,
        },
      },
      onCompleted: data => {
        const fileContent = data.downloadAttachment?.content
        const fileName = data.downloadAttachment?.fileName

        if (fileContent && fileName) {
          saveAs(fileContent, fileName)
        }
      },
      onError: error => {
        console.error('Error downloading attachment:', error)
      },
    })
  }

  // region end

  // region Delete Attachment

  const handleDeleteDocument = (attachmentId: string) => {
    if (!bookingAttachmentsData) {
      console.error('Booking attachments data is not available')
      return
    }

    let targetAttachment
    const allAttachmentTypes = [
      ...bookingAttachmentsData.GetBookingDetail.bookingAttachments
        .bookingCcrAttachments,
      ...bookingAttachmentsData.GetBookingDetail.bookingAttachments
        .bookingNricAttachments,
      ...bookingAttachmentsData.GetBookingDetail.bookingAttachments
        .bookingFeeAttachments,
      ...bookingAttachmentsData.GetBookingDetail.bookingAttachments
        .bookingPdpaAttachments,
    ]

    targetAttachment = allAttachmentTypes.find(item => item.id === attachmentId)

    if (!targetAttachment || !targetAttachment.allowDelete) {
      console.log('Attachment cannot be deleted or does not exist')
      return
    }

    // Proceed with deletion using the mutation
    deleteAttachments({
      variables: {
        attachmentIds: [attachmentId],
      },
      onCompleted: () => {
        setDeleteDialog(false)
        window.location.reload()
      },
      onError: error => {
        console.error('Delete failed:', error)
      },
    })
  }
  // region end

  // region File Type

  const countSpecificDocType = (
    bookingAttachmentArray: any[],
    type: string | string[]
  ): number | null => {
    const result = bookingAttachmentArray?.filter(doc => {
      if (Array.isArray(type)) {
        return type.includes(doc.refType)
      } else {
        return doc.refType === type
      }
    }).length
    return result === 0 ? null : result
  }

  // region end

  return (
    <>
      <ColumnFlex fullWidth gap="24px">
        <Card>
          <ColumnFlex>
            <Container>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    NRIC Copy/Passport Copy (Main & Joint Buyer)
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      bookingAttachmentsData?.GetBookingDetail
                        ?.bookingAttachments?.bookingNricAttachments ?? [],
                      docType.customerIC
                    ) || 'No'}{' '}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {bookingAttachmentsData?.GetBookingDetail?.bookingAttachments?.bookingNricAttachments.map(
                      item =>
                        docType.customerIC.includes(item.refType) && (
                          <ColumnFlex key={item.id}>
                            <div
                              className="purchaser-doc-img-container click-able"
                              onClick={() =>
                                downloadAttachment(
                                  item.id,
                                  docType.customerCreditReport
                                )
                              }
                            >
                              <img
                                src={
                                  item.mediaType === 'application/pdf'
                                    ? pdfIcon
                                    : item.resourceUrl
                                }
                                className="purchaser-doc-img"
                                style={{ width: '80px', height: 'auto' }}
                              />
                            </div>

                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
                <Mui_Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  className="button"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                >
                  <PublishRoundedIcon />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={event => {
                      handleNRICFileChange(
                        event,
                        bookingAttachmentsData?.GetBookingDetail?.id,
                        attachmentRefTypeList?.customerIC_BuyerIC
                      )
                    }}
                  />
                </Mui_Button>
              </RowFlex>
            </Container>

            <Container>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    Customer Credit Report (ie. CCRIS, CTOS)
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      bookingAttachmentsData?.GetBookingDetail
                        ?.bookingAttachments?.bookingCcrAttachments ?? [],
                      docType.customerCreditReport
                    ) || 'No'}{' '}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {bookingAttachmentsData?.GetBookingDetail?.bookingAttachments?.bookingCcrAttachments?.map(
                      item =>
                        item.refType === docType.customerCreditReport && (
                          <ColumnFlex key={item.id}>
                            <StackX>
                              <div
                                className="purchaser-doc-img-container click-able"
                                onClick={() =>
                                  downloadAttachment(
                                    item.id,
                                    docType.customerCreditReport
                                  )
                                }
                              >
                                <img
                                  src={
                                    item.mediaType === 'application/pdf'
                                      ? pdfIcon
                                      : item.resourceUrl
                                  }
                                  className="purchaser-doc-img"
                                  style={{ width: '80px', height: 'auto' }}
                                />
                              </div>
                              {item.allowDelete && (
                                <StackItem top="-8px" right="-8px">
                                  <ButtonBase
                                    disableRipple
                                    disableTouchRipple
                                    onClick={() => setDeleteDialog(true)}
                                  >
                                    <Cancel color="error" />
                                  </ButtonBase>
                                </StackItem>
                              )}
                            </StackX>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
                <Mui_Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  className="button"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                >
                  <PublishRoundedIcon />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={event => {
                      handleFileChange(
                        event,
                        bookingAttachmentsData?.GetBookingDetail?.id,
                        attachmentRefTypeList?.customerCreditReport
                      )
                    }}
                  />
                </Mui_Button>
              </RowFlex>
            </Container>

            <Container>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">
                    Booking Documents
                  </span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      bookingAttachmentsData?.GetBookingDetail
                        ?.bookingAttachments?.bookingFeeAttachments ?? [],
                      docType.bookingForm
                    ) || 'No'}{' '}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {bookingAttachmentsData?.GetBookingDetail?.bookingAttachments?.bookingFeeAttachments?.map(
                      item =>
                        item.refType === docType.bookingForm && (
                          <ColumnFlex key={item.id}>
                            <StackX>
                              <div
                                className="purchaser-doc-img-container click-able"
                                onClick={() =>
                                  downloadAttachment(
                                    item.id,
                                    docType.bookingForm
                                  )
                                }
                              >
                                <img
                                  src={
                                    item.mediaType === 'application/pdf'
                                      ? pdfIcon
                                      : item.resourceUrl
                                  }
                                  className="purchaser-doc-img"
                                  style={{ width: '80px', height: 'auto' }}
                                  alt={'documentImg'}
                                />
                              </div>
                              {item.allowDelete && (
                                <StackItem top="-8px" right="-8px">
                                  <ButtonBase
                                    disableRipple
                                    disableTouchRipple
                                    onClick={() => setDeleteDialog(true)}
                                  >
                                    <Cancel color="error" />
                                  </ButtonBase>
                                </StackItem>
                              )}
                            </StackX>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                            <Dialog
                              fullWidth={true}
                              open={deleteDialog}
                              onClose={() => setDeleteDialog(false)}
                              header={
                                <Subheader sx={{ padding: '10px 12px' }}>
                                  <SubheaderText
                                    primary={
                                      <Box borderBottom="1px solid #fff">
                                        <Box
                                          display="flex"
                                          margin="2px 0px"
                                          width="100%"
                                        >
                                          <Typography
                                            className="text-xsTitle"
                                            color="#FF9800"
                                            component="span"
                                            flex="1 1"
                                            variant="inherit"
                                          >
                                            Delete Confirmation
                                          </Typography>
                                        </Box>
                                      </Box>
                                    }
                                  />
                                </Subheader>
                              }
                              body={
                                <Box>
                                  <Box
                                    width="100%"
                                    boxSizing="border-box"
                                    marginBottom="14px"
                                  >
                                    <Typography
                                      component="span"
                                      flex="1 1"
                                      variant="inherit"
                                      className="text-xsTitle"
                                    >
                                      Are you sure want to delete this file?
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                              footer={{
                                buttons: [
                                  {
                                    children: 'Cancel',
                                    color: 'secondary',
                                    onClick: () => setDeleteDialog(false),
                                  },
                                  {
                                    children: 'Confirm',
                                    color: 'primary',
                                    onClick: () => {
                                      handleDeleteDocument(item.id)
                                    },
                                  },
                                ],
                              }}
                            />
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
                <Mui_Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  className="button"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                >
                  <PublishRoundedIcon />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={event => {
                      handleFileChange(
                        event,
                        bookingAttachmentsData?.GetBookingDetail?.id,
                        attachmentRefTypeList?.bookingFrom
                      )
                    }}
                  />
                </Mui_Button>
              </RowFlex>
            </Container>

            <Container>
              <RowFlex
                crossAxisAlignment="center"
                mainAxisAlignment="space-between"
                gap="10px"
              >
                <ColumnFlex fullWidth gap="4px">
                  <span className="fw-700 fs-12 color-text">PDPA Consent</span>
                  <span className="fw-500 fs-10 color-text-2">
                    {countSpecificDocType(
                      bookingAttachmentsData?.GetBookingDetail
                        ?.bookingAttachments?.bookingPdpaAttachments ?? [],
                      docType.pdpaConsent
                    ) || 'No'}{' '}
                    Attachments
                  </span>
                  <RowGrid gridTemplateColumns="1fr 1fr 1fr 1fr" gap="14px">
                    {bookingAttachmentsData?.GetBookingDetail?.bookingAttachments?.bookingFeeAttachments?.map(
                      item =>
                        item.refType === docType.pdpaConsent && (
                          <ColumnFlex key={item.id}>
                            <StackX>
                              <div
                                className="purchaser-doc-img-container click-able"
                                onClick={() =>
                                  downloadAttachment(
                                    item.id,
                                    docType.pdpaConsent
                                  )
                                }
                              >
                                <img
                                  src={
                                    item.mediaType === 'application/pdf'
                                      ? pdfIcon
                                      : item.resourceUrl
                                  }
                                  className="purchaser-doc-img"
                                  style={{ width: '80px', height: 'auto' }}
                                  alt={'documentImg'}
                                />
                              </div>
                              {item.allowDelete && (
                                <StackItem top="-8px" right="-8px">
                                  <ButtonBase
                                    disableRipple
                                    disableTouchRipple
                                    onClick={() => setDeleteDialog(true)}
                                  >
                                    <Cancel color="error" />
                                  </ButtonBase>
                                </StackItem>
                              )}
                            </StackX>
                            <span className="fs-10 fw-500 color-text-2">
                              {item.fileName}
                            </span>
                          </ColumnFlex>
                        )
                    )}
                  </RowGrid>
                </ColumnFlex>
                <Mui_Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  className="button"
                  disableRipple
                  disableElevation
                  disableTouchRipple
                  disableFocusRipple
                >
                  <PublishRoundedIcon />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={event => {
                      handleFileChange(
                        event,
                        bookingAttachmentsData?.GetBookingDetail?.id,
                        attachmentRefTypeList?.pdpaConsent
                      )
                    }}
                  />
                </Mui_Button>
              </RowFlex>
            </Container>
          </ColumnFlex>
        </Card>
      </ColumnFlex>
    </>
  )
}

export default BookingPurchaserDocuments
