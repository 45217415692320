import React, { useState, useRef } from 'react'
import {
  ProjectDetailQuery,
  GetProjectDetailsForPublicQuery,
} from '../../../generated/graphql'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Card, IconButton } from '@mui/material'
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
  PlayArrow,
} from '@mui/icons-material'

type SalesGallerySwiperProps = {
  projectDetailsData?: ProjectDetailQuery
  publicProjectDetailData?: GetProjectDetailsForPublicQuery
}

const ProjectSwiper: React.FC<SalesGallerySwiperProps> = ({
  projectDetailsData,
}) => {
  if (!projectDetailsData) {
    return null
  }

  const [isZoomImage, setIsZoomImage] = useState(-1)
  const sliderRef = useRef<Slider>(null)

  const projectData = projectDetailsData.getProjectById
  const {
    embededYoutubeLink = [],
    embededVimeoLink = [],
    galleryImage = [],
  } = projectData

  const mediaLinkItems = [
    ...(embededYoutubeLink || []),
    ...(embededVimeoLink || []),
    ...(galleryImage || []),
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  }

  const swiperStyle: React.CSSProperties = {
    width: 'calc(100% - 60px)',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  }

  const imageStyle: React.CSSProperties = {
    width: 'auto',
    height: 'calc(100% - 40px)',
    maxWidth: '400px',
    maxHeight: '400px',
    objectFit: 'contain',
    margin: '20px 0 15px 0',
  }

  const handleVideoClick = (url: string) => {
    window.open(url, '_blank')
  }

  const handleSlideChange = (index: any) => {
    setIsZoomImage(index)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Card
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <IconButton
          style={{
            position: 'absolute',
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            zIndex: 1,
            background: 'transparent',
          }}
          onClick={() => sliderRef.current?.slickPrev()}
        >
          <NavigateBeforeRounded fontSize="large" />
        </IconButton>

        <Slider ref={sliderRef} {...settings} afterChange={handleSlideChange}>
          {mediaLinkItems.length > 0 &&
            mediaLinkItems.map((item, index) => (
              <div key={item.url} style={swiperStyle}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {item.__typename === 'ProjectYoutubeLinkOutput' ? (
                    <div style={{ position: 'relative' }}>
                      <img
                        src={item.thumbnail || ''}
                        alt="galleryImage"
                        style={imageStyle}
                      />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          borderRadius: '50%',
                          padding: '10px',
                          zIndex: 2,
                        }}
                        onClick={() => handleVideoClick(item.url)}
                      >
                        <PlayArrow
                          style={{ color: '#FF9800', fontSize: '30px' }}
                        />
                      </IconButton>
                    </div>
                  ) : (
                    <img
                      src={item.url || ''}
                      alt="galleryImage"
                      style={imageStyle}
                      className="click-able"
                      onClick={() => setIsZoomImage(index)}
                    />
                  )}
                </div>
              </div>
            ))}
        </Slider>

        <IconButton
          style={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            zIndex: 1,
            background: 'transparent',
          }}
          onClick={() => sliderRef.current?.slickNext()}
        >
          <NavigateNextRounded fontSize="large" />
        </IconButton>
      </Card>
    </div>
  )
}

export default ProjectSwiper
