import { RouteObject } from 'react-router-dom'
import Brochure from './Brochure'

export const SALESDOCUMENT_PATH = {
  BROCHURE: '/book/sales-document',
}

const SalesDocumentRoutes: RouteObject[] = [
  {
    path: SALESDOCUMENT_PATH.BROCHURE,
    element: <Brochure />,
  },
]

export default SalesDocumentRoutes
