import {
  Card,
  ColumnFlex,
  Container,
  Dialog,
  Expansion,
  ExpansionDetails,
  ExpansionSummary,
  List,
  ListItem,
  ListItemText,
  RowFlex,
  RowGrid,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
} from '@mui/icons-material'
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { TopSectionHeader } from 'component/HeaderSection'
import useNav from 'component/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useProjectSummaryQuery,
  useGetBookingDetailDataQuery,
  useDownloadAttachmentMutation,
  useFinancierListQuery,
  useGenerateBookingFormQuery,
  useCancelBookingQuery,
  useLoanScreeningBookingMutation,
  useGetLoanScreeningStatusMutation,
  useGetPurchaserDetailsQuery,
  useGetCustomerOriginQuery,
} from 'generated/graphql'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import EditIcon from '@mui/icons-material/Edit'
import bookingFeeIcon from './../../../assets/images/icon/booking-fee.svg'
import shareELetteringIcon from './../../../assets/images/icon/agency.svg'
import jointBuyerIcon from './../../../assets/images/icon/authorized-agents.svg'
import purchaserDocumentIcon from './../../../assets/images/icon/purchaser account.png'
import creditReportIcon from './../../../assets/images/icon/agreement.svg'
import loanApplicationIcon from './../../../assets/images/icon/loan assignment.png'
import bookingFormIcon from './../../../assets/images/icon/todo.svg'
import { BOOKING_LIST_PATH } from './Routes'
import { LOAN_PATH } from '../Loan/Routes'

const BookingDetails = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const saleId = queryParams.get('saleId')
  const projectid = localStorage.getItem('lastestProject')

  const navigate = useNavigate()

  const [clicked, setClicked] = useState(false)

  // region Project Header

  const { data: { getProjectById: projectName } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: `Booking Details/${bookingDetailsData?.unitNo}`,
          },
        },
      },
    })
  }, [projectName])

  // region end

  //region Get Booking Details API

  const { data } = useGetBookingDetailDataQuery({
    variables: { saleId: saleId || '' },
    onCompleted: data => {
      localStorage.getItem('lastestProject')
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  const bookingDetailsData = data?.getBookingDetailData
  const projectData = data?.getProjectById
  const bookingInfoData = data?.GetBookingDetail

  // region Purchaser Info Dialog

  const [profileID, setProfileID] = useState('')
  const [openProfileInfoDialog, setOpenProfileInfoDialog] = useState(false)

  const {
    data: { GetPurchaserDetails: Profile } = { GetPurchaserDetails: null },
  } = useGetPurchaserDetailsQuery({
    variables: { contactId: profileID },
    skip: !profileID,
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { getCustomerOrigin: CustomerOrigin } = { getCustomerOrigin: null },
  } = useGetCustomerOriginQuery({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  // region Navigation

  const handleNavigationToEP = () => {
    const url = `${BOOKING_LIST_PATH.EDITPURCHASER}?saleId=${saleId}&project_id=${projectid}`

    navigate(url)
  }

  const handleNavigationToBF = () => {
    const url = `${BOOKING_LIST_PATH.BOOKINGFEE}?saleId=${saleId}&project_id=${projectid}`

    navigate(url)
  }

  const handleNavigationToSEL = () => {
    const url = `${BOOKING_LIST_PATH.SHAREELETTERING}?saleId=${saleId}&project_id=${projectid}`

    navigate(url)
  }

  const handleNavigationToJB = () => {
    const url = `${BOOKING_LIST_PATH.JOINBUYERLIST}?saleId=${saleId}&project_id=${projectid}`

    navigate(url)
  }

  const handleNavigationToPD = () => {
    const url = `${BOOKING_LIST_PATH.BOOKINGPURCHASERDOCUMENTS}?saleId=${saleId}&project_id=${projectid}`

    navigate(url)
  }

  const handleNavigationToLA = () => {
    const url = `${LOAN_PATH.LOAN_DETAIL}/${saleId}/${projectid}`

    navigate(url)
  }

  // region end

  // region Generate Booking Form

  const {
    data: { GenerateBookingForm: BookingFormGenerate } = {
      GenerateBookingForm: null,
    },
  } = useGenerateBookingFormQuery({
    variables: {
      salesId: saleId,
      language: 'en-MY',
    },
    skip: !clicked,
    onCompleted: () => {
      handleNavigationToPD()
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const handleClick = () => {
    // setClicked(true)

    alert('Unable to generate Booking Form.')
  }

  // region end

  return (
    <>
      <Container>
        <Card className="card-container-detail">
          <RowGrid gridTemplateColumns="1fr auto" fullWidth>
            <Box>
              <Typography
                className="value-f"
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.stopPropagation()
                  const id = bookingInfoData?.contactId
                  setProfileID(id || '')
                  setOpenProfileInfoDialog(true)
                }}
              >
                {bookingDetailsData?.buyerName}
              </Typography>
              <Typography className="value-f">
                {bookingDetailsData?.buyerNric}
              </Typography>
            </Box>
            <EditIcon
              style={{
                fontSize: '16px',
              }}
              onClick={handleNavigationToEP}
            />
          </RowGrid>
          <RowGrid gridTemplateColumns="1fr 1fr" fullWidth>
            <Box>
              <Typography className="value-f" style={{ fontSize: '10px' }}>
                Contact No.
              </Typography>
              <Typography
                className="value-f"
                style={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  color: 'orange',
                }}
              >
                <span
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  {bookingDetailsData?.buyerPhoneNo}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography className="value-f" style={{ fontSize: '10px' }}>
                Email
              </Typography>
              <Typography
                className="value-f"
                style={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  color: 'orange',
                }}
              >
                <span
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  {bookingDetailsData?.buyerEmail}
                </span>
              </Typography>
            </Box>
          </RowGrid>
        </Card>

        <Card
          className="card-container-detail"
          header={{
            title: (
              <SubheaderText
                primary={
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      <span style={{ color: 'black' }}>Booking No: </span>
                      {bookingDetailsData?.salesNo}
                    </Typography>
                  </Box>
                }
              />
            ),
          }}
        >
          <ColumnFlex fullWidth>
            <RowGrid gridTemplateColumns="1fr 1fr">
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Unit No
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {bookingDetailsData?.unitNo || '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Layout Type
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {bookingDetailsData?.unitLayout || '-'}
                </Typography>
              </Box>
            </RowGrid>
            <RowGrid gridTemplateColumns="1fr 1fr">
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Built-up Area
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {bookingDetailsData?.builtUp + ' sq.ft' || '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Sales Person / Agent
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {bookingDetailsData?.agencyUserName}
                  {bookingDetailsData?.agencyName &&
                    ` (${bookingDetailsData.agencyName})`}
                </Typography>
              </Box>
            </RowGrid>
            <RowGrid gridTemplateColumns="1fr 1fr">
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Unit Layout Description
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {bookingDetailsData?.unitLayoutDes || '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Land Area
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {bookingDetailsData?.landArea || '-'}
                </Typography>
              </Box>
            </RowGrid>

            <Divider style={{ margin: '16px 0' }} />

            <Box>
              <Typography className="value-f" style={{ fontSize: '10px' }}>
                Package
              </Typography>
              {bookingDetailsData?.packageList &&
              bookingDetailsData.packageList.length > 0 ? (
                bookingDetailsData.packageList.map((packageItem, index) => (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    key={index}
                    className="value-f"
                    style={{ fontWeight: 600 }}
                  >
                    {packageItem?.desc}
                  </Typography>
                ))
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="value-f"
                  style={{ fontWeight: 600 }}
                >
                  {'-'}
                </Typography>
              )}
            </Box>
            <RowGrid gridTemplateColumns="1fr 1fr">
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Discount
                </Typography>
                {bookingDetailsData?.saleDiscount &&
                bookingDetailsData.saleDiscount.length > 0 ? (
                  bookingDetailsData.saleDiscount.map((saleDiscount, index) => (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      key={index}
                      className="value-f"
                      style={{ fontWeight: 600 }}
                    >
                      {projectData?.currency + ' '}
                      {saleDiscount?.amount}
                    </Typography>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="value-f"
                    style={{ fontWeight: 600 }}
                  >
                    {'-'}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Sale's Rebate
                </Typography>
                {bookingDetailsData?.saleRebate &&
                bookingDetailsData.saleRebate.length > 0 ? (
                  bookingDetailsData.saleRebate.map((saleRebate, index) => (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      key={index}
                      className="value-f"
                      style={{ fontWeight: 600 }}
                    >
                      {saleRebate?.discountName}
                    </Typography>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="value-f"
                    style={{ fontWeight: 600 }}
                  >
                    {'-'}
                  </Typography>
                )}
              </Box>
            </RowGrid>
            <RowGrid gridTemplateColumns="1fr 1fr">
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  List Price
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {projectData?.currency + ' '}
                  {bookingDetailsData?.listPrice &&
                  !isNaN(Number(bookingDetailsData.listPrice))
                    ? new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(Number(bookingDetailsData.listPrice))
                    : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className="value-f" style={{ fontSize: '10px' }}>
                  Selling Price
                </Typography>
                <Typography className="value-f" style={{ fontWeight: 600 }}>
                  {projectData?.currency + ' '}
                  {bookingDetailsData?.sellingPrice &&
                  !isNaN(Number(bookingDetailsData.sellingPrice))
                    ? new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(Number(bookingDetailsData.sellingPrice))
                    : '-'}
                </Typography>
              </Box>
            </RowGrid>
            <Box>
              <Typography className="value-f" style={{ fontSize: '10px' }}>
                Remarks
              </Typography>
              <Typography className="value-f" style={{ fontWeight: 600 }}>
                {bookingDetailsData?.bookingRemark || '-'}
              </Typography>
            </Box>
          </ColumnFlex>
        </Card>

        <List>
          <ListItem onClick={handleNavigationToBF}>
            <ListItemIcon>
              <img src={bookingFeeIcon} height="30px" alt="" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="common.black"
                  component="div"
                  display="flex"
                  variant="inherit"
                >
                  <Typography
                    className="text-xsTitle"
                    component="span"
                    flex="1"
                    variant="inherit"
                    noWrap
                  >
                    Booking Fee
                  </Typography>
                </Typography>
              }
              secondary={
                <Typography component="div">
                  <Typography
                    className="text-desc"
                    color="#454545"
                    component="div"
                    variant="inherit"
                    noWrap
                  >
                    {bookingDetailsData?.paymentMethod +
                      ': ' +
                      projectData?.currency +
                      ' ' +
                      bookingDetailsData?.bookPaidAmount}
                  </Typography>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem onClick={handleNavigationToSEL}>
            <ListItemIcon>
              <img src={shareELetteringIcon} height="30px" alt="" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="common.black"
                  component="div"
                  display="flex"
                  variant="inherit"
                >
                  <Typography
                    className="text-xsTitle"
                    component="span"
                    flex="1"
                    variant="inherit"
                    noWrap
                  >
                    Share E-Lettering
                  </Typography>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem onClick={handleNavigationToJB}>
            <ListItemIcon>
              <img src={jointBuyerIcon} height="30px" alt="" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="common.black"
                  component="div"
                  display="flex"
                  variant="inherit"
                >
                  <Typography
                    className="text-xsTitle"
                    component="span"
                    flex="1"
                    variant="inherit"
                    noWrap
                  >
                    {'Joint Buyer ' +
                      '(' +
                      bookingDetailsData?.joinBuyer?.length +
                      ')'}
                  </Typography>
                </Typography>
              }
              secondary={
                <Typography component="div">
                  <Typography
                    className="text-desc"
                    color="#454545"
                    component="div"
                    variant="inherit"
                    noWrap
                  >
                    {bookingDetailsData?.joinBuyer?.length
                      ? bookingDetailsData.joinBuyer
                          .map(buyer => buyer.contact?.fullName || '-')
                          .join(', ')
                      : '-'}
                  </Typography>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem onClick={handleNavigationToPD}>
            <ListItemIcon>
              <img
                src={purchaserDocumentIcon}
                height="30px"
                alt=""
                style={{ marginLeft: '1px' }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="common.black"
                  component="div"
                  display="flex"
                  variant="inherit"
                >
                  <Typography
                    className="text-xsTitle"
                    component="span"
                    flex="1"
                    variant="inherit"
                    noWrap
                  >
                    {'Purchaser Document ' +
                      '(' +
                      bookingDetailsData?.attachmentCount?.completedDocCount +
                      '/' +
                      bookingDetailsData?.attachmentCount?.requiredDocCount +
                      ')'}
                  </Typography>
                </Typography>
              }
              secondary={
                <Typography component="div">
                  <Typography
                    className="text-desc"
                    color="#454545"
                    component="div"
                    variant="inherit"
                    noWrap
                  >
                    NRIC, Credit Report, PDPA Consent, Booking Form
                  </Typography>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img
                src={creditReportIcon}
                height="30px"
                alt=""
                style={{ marginLeft: '1px' }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="common.black"
                  component="div"
                  display="flex"
                  variant="inherit"
                >
                  <Typography
                    className="text-xsTitle"
                    component="span"
                    flex="1"
                    variant="inherit"
                    noWrap
                  >
                    Credit Report
                  </Typography>
                </Typography>
              }
              secondary={
                <Typography component="div">
                  <Typography
                    className="text-desc"
                    color="#454545"
                    component="div"
                    variant="inherit"
                    noWrap
                  >
                    {'CCRIS: ' +
                      (bookingInfoData?.loanScreening?.CCRIS || '-') +
                      ' | ' +
                      'CTOS: ' +
                      (bookingInfoData?.loanScreening?.CTOS || '-')}
                  </Typography>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem onClick={handleNavigationToLA}>
            <ListItemIcon>
              <img
                src={loanApplicationIcon}
                height="30px"
                alt=""
                style={{ marginLeft: '3px' }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="common.black"
                  component="div"
                  display="flex"
                  variant="inherit"
                >
                  <Typography
                    className="text-xsTitle"
                    component="span"
                    flex="1"
                    variant="inherit"
                    noWrap
                  >
                    Loan Application
                  </Typography>
                </Typography>
              }
              secondary={
                <Typography component="div">
                  <Typography
                    className="text-desc"
                    color="#454545"
                    component="div"
                    variant="inherit"
                    noWrap
                  >
                    {'Loan applied: ' + bookingDetailsData?.endfinLoanAppsCount}
                  </Typography>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem onClick={handleClick}>
            <ListItemIcon>
              <img
                src={bookingFormIcon}
                height="30px"
                alt=""
                style={{ marginLeft: '2px' }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="common.black"
                  component="div"
                  display="flex"
                  variant="inherit"
                >
                  <Typography
                    className="text-xsTitle"
                    component="span"
                    flex="1"
                    variant="inherit"
                    noWrap
                  >
                    Booking Form
                  </Typography>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        </List>
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openProfileInfoDialog}
        onClose={() => setOpenProfileInfoDialog(false)}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Typography
                      className="text-mdTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Purchaser Info
                    </Typography>
                  </Box>
                }
              />
            </Subheader>
          </>
        }
        body={
          <>
            <ColumnFlex gap="12px">
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Full Name (as per NRIC)
                </span>
                <span className="color-text fw-600 fs-12">
                  {Profile?.fullName || '-'}
                </span>
              </ColumnFlex>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  NRIC No. / Passport No. / ROC No.
                </span>
                <span className="color-text fw-600 fs-12">
                  {Profile?.icNo || '-'}
                </span>
              </ColumnFlex>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Salutation</span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.salutation?.name || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Race</span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.race?.name || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Nationality</span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.nationality?.name || '-'}
                  </span>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Marital Status
                  </span>
                  <span className="color-text fw-600 fs-12">
                    {Profile?.maritalStatus?.name || '-'}
                  </span>
                </ColumnFlex>
              </RowGrid>
              <RowGrid gridTemplateColumns="1fr 1fr">
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">Mobile No.</span>
                  <a
                    className="color-orange fw-600 fs-12"
                    href={`tel:${Profile?.phoneNo}`}
                  >
                    {Profile?.phoneNo || '-'}
                  </a>
                </ColumnFlex>
                <ColumnFlex>
                  <span className="fw-500 fs-10 color-text-2">
                    Email Address
                  </span>
                  <a
                    className="color-orange fw-600 fs-12"
                    href={`tel:${Profile?.email}`}
                  >
                    {Profile?.email || '-'}
                  </a>
                </ColumnFlex>
              </RowGrid>
              <ColumnFlex>
                <span className="fw-500 fs-10 color-text-2">
                  Customer Origin
                </span>
                <span className="color-text fw-600 fs-12">
                  {CustomerOrigin?.length ? CustomerOrigin[0].name : '-'}
                </span>
              </ColumnFlex>
            </ColumnFlex>
          </>
        }
      />
    </>
  )
}

export default BookingDetails
