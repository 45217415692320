import React, { useState } from 'react'
import {
  ProjectDetailQuery,
  GetProjectDetailsForPublicQuery,
} from '../../../generated/graphql'
import { NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Card, ColumnFlex, Container, RowFlex } from '@ifca-ui/core'
import { IconButton } from '@mui/material'

type UnitLayoutSwiperProps = {
  projectDetailsData?: ProjectDetailQuery
  publicProjectDetailsData?: GetProjectDetailsForPublicQuery
}

const CustomArrow = ({
  direction,
  onClick,
}: {
  direction: 'next' | 'prev'
  onClick?: () => void
}) => (
  <IconButton
    onClick={onClick}
    style={{
      color: '#696969',
      position: 'absolute',
      top: '50%',
      zIndex: 1,
      cursor: 'pointer',
      transform: 'translateY(-50%)',
      left: direction === 'prev' ? '10px' : 'auto',
      right: direction === 'next' ? '10px' : 'auto',
      background: 'transparent',
    }}
  >
    {direction === 'next' ? (
      <NavigateNextRounded fontSize="large" />
    ) : (
      <NavigateBeforeRounded fontSize="large" />
    )}
  </IconButton>
)

const UnitLayoutSwiper: React.FC<UnitLayoutSwiperProps> = ({
  projectDetailsData,
  publicProjectDetailsData,
}) => {
  if (!projectDetailsData && !publicProjectDetailsData) return null

  const projectDetailsDataResult =
    projectDetailsData?.getProjectById ||
    publicProjectDetailsData?.getProjectDetailsForPublic

  const unitLayoutGalleryImage =
    projectDetailsDataResult?.unitLayoutGalleryImage || []
  const [isZoomedImage, setIsZoomedImage] = useState<string | null>(null)

  const settings = {
    dots: false, // Disable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
  }

  return (
    <>
      <Container style={{ position: 'relative' }}>
        <span className="fw-700 fs-12 color-text">Unit Layout</span>
        <Slider {...settings}>
          {unitLayoutGalleryImage.map((unitLayout, index) => (
            <div key={index}>
              <Container className="unit-layout">
                <Card
                  className="unit-layout-swiper"
                  style={{ overflow: 'hidden', position: 'relative' }}
                >
                  <img
                    style={{
                      display: 'block',
                      margin: '20px auto', // Uniform margin top and bottom
                      maxWidth: '80%', // Adjust width as needed
                      maxHeight: '300px', // Limit height to maintain aspect ratio
                    }}
                    src={unitLayout.url ?? ''}
                    alt={`Unit Layout ${index}`}
                    className="image"
                    onClick={() => setIsZoomedImage(unitLayout.url || null)}
                  />
                </Card>
                <ColumnFlex
                  fullWidth
                  crossAxisAlignment="center"
                  className="p-v-15"
                  gap="2px"
                >
                  <RowFlex gap="5px" className="fw-600 fs-12 color-text">
                    <span>{unitLayout.unitLayout || 'N/A'}</span>
                    <span>|</span>
                    <span>{unitLayout.noOfBedroom || 0} Bedroom(s)</span>
                    <span>|</span>
                    <span>{unitLayout.noOfBathroom || 0} Bathroom(s)</span>
                  </RowFlex>
                </ColumnFlex>
              </Container>
            </div>
          ))}
        </Slider>
      </Container>
    </>
  )
}

export default UnitLayoutSwiper
