// import React from 'react'
// import { ColumnFlex } from '../../component/Layout/LayoutX/Flex/ColumnFlex'
// import { Card, Dialog } from '@ifca-ui/core'

// type SimpleDialogProps = {
//   visible: boolean
//   onClose: () => void
//   children: React.ReactNode
//   width?: number
//   background?: string
// }

// const SimpleDialog: React.FC<SimpleDialogProps> = ({
//   visible,
//   onClose,
//   children,
//   width,
//   background,
// }) => {
//   const element = document.getElementsByTagName('body')[0]
//   if (visible) {
//     element.classList.add('disable-overflow')
//   } else {
//     element.classList.remove('disable-overflow')
//   }

//   return (
//     // <Dialog onClose={onClose}>
//     //   <Card
//     //     className={'DialogContainer'}
//     //     style={{ width: width, background: background }}
//     //   >
//     //     <ColumnFlex>{children}</ColumnFlex>
//     //   </Card>
//     // </Dialog>
//     <></>
//   )
// }

// export default SimpleDialog

import React from 'react'
// import { ColumnFlex } from '../../component/Layout/LayoutX/Flex/ColumnFlex'
import { Card, ColumnFlex, Dialog } from '@ifca-ui/core'

type SimpleDialogProps = {
  visible: boolean
  onClose: () => void
  children?: React.ReactNode // Make children optional
  width?: number
  background?: string
  // src?: string | null
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  visible,
  onClose,
  children,
  width,
  background,
  // src,
}) => {
  const element = document.getElementsByTagName('body')[0]
  if (visible) {
    element.classList.add('disable-overflow')
  } else {
    element.classList.remove('disable-overflow')
  }

  return (
    <Dialog open={visible} onClose={onClose}>
      {/* <Card className={'DialogContainer'} style={{ width, background }}> */}
      {/* <ColumnFlex> */}
      {children}
      {/* {src && <img src={src} alt="Zoomed" />} */}
      {/* </ColumnFlex> */}
      {/* </Card> */}
    </Dialog>
  )
}

export default SimpleDialog
