import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const AuthorizedSalesAgent = lazy(() => import('./AuthorizedSalesAgent'))

export const AUTHORIZED_SALES_AGENT_PATH = {
  AUTHORIZED_SALES_AGENT: '/agent/auth-agent',
}

const AuthorizedSalesAgentRoutes: RouteObject[] = [
  {
    path: `${AUTHORIZED_SALES_AGENT_PATH.AUTHORIZED_SALES_AGENT}/:projectid`,
    element: <AuthorizedSalesAgent />,
  },
]

export default AuthorizedSalesAgentRoutes
