import { yupResolver } from '@hookform/resolvers/yup'
import {
  AvatarUpload,
  Card,
  Container,
  Footer,
  Dialog,
  Subheader,
  SubheaderText,
  ColumnFlex,
  RowGrid,
  EmptyState,
  List,
  ListItemText,
  Chip,
  Menu,
  RowFlex,
} from '@ifca-ui/core'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import AppContext, { AppContextProps } from 'containers/context/Context'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, Form, useForm, useWatch } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import {
  useProjectSummaryQuery,
  useAddBookingExpressMutation,
  useGetAllMasterDataQuery,
  useCalculateDiscountQuery,
  useGetBookingContactListQuery,
  useGetBookingWaitlistQueueQuery,
  useGetAvailableAndBookedUnitsQuery,
  useCalculateIntegrateDiscountQuery,
  useGetBookingFormSourceQuery,
  useGetCustomerOriginQuery,
  useGetIdentityTypeQuery,
  useGetGeneralTinByPartyQuery,
  useCalculateDiscountAmtQuery,
  useGetTinValidationQuery,
  useLockUnitQuery,
  useUnLockUnitQuery,
  SalesBookingExpressBuyerInput,
  AddressJsonInput,
} from 'generated/graphql'
import useNav from 'component/RouteService/useNav'
import { TopSectionHeader } from 'component/HeaderSection'
import { PhoneValidationService } from 'containers/helper/phoneValidationHelper'
import { TranslationServices } from 'translateService'
import MuiPhoneNumber from 'material-ui-phone-number'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Webcam from 'react-webcam'
import Tesseract from 'tesseract.js'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { PxLetterB } from '@ifca-ui/icons/dist/index.esm'

interface addBookingForm {
  isCompanyPurc: boolean
  isBumi: boolean
  isForeignBuyer: boolean
  isRequireEInv: boolean
  isGov: boolean

  name: string
  companyName: string
  companyRegNo: string
  directorName: string
  nationality: string
  identityType: string
  identityNo: string
  email: string
  mobileNo: string

  TIN: string
  SST: string
  unitNo: string
  customerOrigin: string

  remarks: string
  bookingFee: any
  paymentMethod: string
  paymentRemark: string
  uploadAttachment: any

  salutation: string
  race: string
  DOB: string
  gender: string
  maritalStatus: string
  incomeRange: string
  leadChannel: string
  occupation: string
  employerName: string
  employerAddress: string

  AddressType: string
  address: string
  country: string
  state: string
  city: string
  postCode: string
  district: string
  isPrimary: boolean
}

interface AddressForm {
  AddressType: string
  address: string
  country: string
  state: string
  city: string
  postCode: string
  district: string
  isPrimary: boolean
}

const NewBooking = () => {
  const { navBack } = useNav()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const saleId = queryParams.get('saleId')
  const projectid = localStorage.getItem('lastestProject')

  const navigate = useNavigate()

  const [isEdited, setIsEdited] = useState(false)

  const [selectedFileName, setSelectedFileName] = useState('')

  const [openAddressDialog, setOpenAddressDialog] = useState(false)

  const [expanded, setExpanded] = useState(false)

  const toggleExpansion = () => {
    setExpanded(prevState => !prevState)
  }

  const [selectedUnitId, setSelectedUnitId] = useState<string | null>(null)
  const [selectedStartTime, setselectedStartTime] = useState<Date | null>(null)
  const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null)
  const [countdown, setCountdown] = useState(60)
  const [isLocked, setIsLocked] = useState(false)
  const [lockCompleted, setLockCompleted] = useState(false)

  // region Project Header

  const { data: { getProjectById: projectName } = { getProjectbyId: null } } =
    useProjectSummaryQuery({
      onCompleted: data => {
        localStorage.getItem('lastestProject')
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectName?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Edit Purchaser Info',
          },
        },
      },
    })
  }, [projectName])

  // region end

  // region Booking form info

  const { data: { BookingContactList = [] } = { getBookingContactList: [] } } =
    useGetBookingContactListQuery({
      variables: {
        limit: 10,
        page: 1,
        isCompany: false,
        projectId: projectid,
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  const {
    data: { GetAllMasterInfo: BookingMasterData } = {
      GetAllMasterInfo: null,
    },
  } = useGetAllMasterDataQuery({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { getIdentityType: IdentityData } = { getIdentityType: null },
  } = useGetIdentityTypeQuery({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { getAvailableAndBookedUnits: UnitData } = {
      getAvailableAndBookedUnits: null,
    },
  } = useGetAvailableAndBookedUnitsQuery({
    variables: {
      projectId: projectid,
      limit: 10,
      page: 1,
      isBookingForm: true,
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  const {
    data: { getCustomerOrigin: OriginData } = { getCustomerOrigin: null },
  } = useGetCustomerOriginQuery({
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  // region Submit booking form info

  const [addBookingExpressMutation] = useAddBookingExpressMutation({
    onCompleted: data => {
      if (data && data.saveBookingExpress) {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: TranslationServices.Translate(
              'New booking added successfully.'
            ),
          },
        })
      }
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  // region end

  // region Lock and unlock unit API call

  const { data: { LockUnit: LockUnitData } = { LockUnit: null } } =
    useLockUnitQuery({
      variables: {
        unitId: selectedUnitId || '',
        userTime: selectedStartTime,
        checkOnly: true,
      },
      skip: !selectedUnitId,
      onCompleted: () => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: 'Unit locked for 60 seconds',
          },
        })

        startCountdown()
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  const { data: { UnLockUnit: UnlockUnitData } = { UnLockUnit: null } } =
    useUnLockUnitQuery({
      variables: {
        projectId: projectid,
        unitId: selectedUnitId || '',
        applyBuffer: false,
        userTime: selectedEndTime,
      },
      skip: !lockCompleted,
      onCompleted: () => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: 'Unit unlock',
          },
        })
      },
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
    })

  // region end

  // region Validation

  const validationScheme = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    companyName: Yup.string().required('Company Name is required'),
    companyRegNo: Yup.string().required('Company Registration No is required'),
    directorName: Yup.string().required('Director Name is required'),
    nationality: Yup.string().required('Nationality is required'),
    mobileNo: Yup.string()
      .required('Mobile No. is required')
      .test(`isValidPhoneNo`, 'Mobile No. is invalid', value => {
        let validateResult = PhoneValidationService.isValidPoneNumber(
          PhoneValidationService.validatePhonePrefix(value)
        )
        return validateResult
      }),
    TIN: Yup.string().required('TIN is required'),
    SST: Yup.string().required('SST Registration Number is required'),
    unitNo: Yup.string().required('Unit No is required'),
    customerOrigin: Yup.string().required('Customer origin is required'),
  })

  // region end

  // region Form Value Control

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<addBookingForm>({
    defaultValues: {
      isCompanyPurc: false,
      isBumi: false,
      isForeignBuyer: false,
      isRequireEInv: false,
      isGov: false,

      name: '',
      companyName: '',
      companyRegNo: '',
      directorName: '',
      nationality: '',
      identityType: '',
      identityNo: '',
      email: '',
      mobileNo: '',

      TIN: '',
      SST: '',
      unitNo: '',
      customerOrigin: '',

      remarks: '',
      bookingFee: '',
      paymentMethod: '',
      uploadAttachment: '',

      salutation: '',
      race: '',
      DOB: '',
      gender: '',
      maritalStatus: '',
      incomeRange: '',
      leadChannel: '',
      occupation: '',
      employerName: '',
      employerAddress: '',

      AddressType: '',
      address: '',
      country: '',
      state: '',
      city: '',
      postCode: '',
      district: '',
      isPrimary: false,
    },
    resolver: yupResolver(validationScheme),
    mode: 'onChange',
  })

  // region end

  // region Checkbox Options

  const isCompanyPurc = useWatch({ control, name: 'isCompanyPurc' })
  const isBumi = useWatch({ control, name: 'isBumi' })
  const isForeignBuyer = useWatch({ control, name: 'isForeignBuyer' })
  const isGov = useWatch({ control, name: 'isGov' })
  const isRequireEInv = useWatch({ control, name: 'isRequireEInv' })

  // region end

  // region Pre-defined Form Value

  useEffect(() => {
    reset({
      nationality: 'Malaysian',
      identityType: 'Identity Card',
      SST: 'N/A',
      customerOrigin: 'Public',
      bookingFee: '0.00',
      salutation: 'Mrs',
      race: 'Malay',
    })
  }, [])

  // region end

  // region Submit Form

  const onSubmit = (data: addBookingForm) => {
    const buyerData: SalesBookingExpressBuyerInput = {
      isNric: data.isBumi,

      name: data.name,
      company_name: data.companyName,
      company_reg_no: data.companyRegNo,
      nationality_id: data.nationality,
      identity_no: data.identityNo,
      email: data.email,
      phone_no: data.mobileNo,

      salutation: data.salutation,
      race: data.race,
      dob: data.DOB,
      gender: data.gender,
      maritalStatus: data.maritalStatus,
      leadSource: data.leadChannel,
    }

    const addressData: AddressJsonInput = {
      AddressType: data.AddressType,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      district: data.district,
      isPrimary: data.isPrimary,
    }

    addBookingExpressMutation({
      variables: {
        input: {
          is_bumi: data.isBumi,
          is_foreign_purchase: data.isForeignBuyer,
          isEInvoiceRequired: data.isRequireEInv,
          isGovAuth: data.isGov,

          buyer: buyerData,
          identity_type: data.identityType,

          tin: data.TIN,
          sst_reg_no: data.SST,
          unit_id: data.unitNo,
          customer_origin: data.customerOrigin,

          remarks: data.remarks,
          pay_method: data.paymentMethod,
          pay_remark: data.paymentRemark,
          bookingFeeAttachment: data.uploadAttachment,

          employer_name: data.employerName,
          employer_address: data.employerAddress,

          addressList: [addressData],
        },
      },
    })
  }

  // region end

  // region Dirty Fields

  useEffect(() => {
    if (
      dirtyFields.isCompanyPurc ||
      dirtyFields.isBumi ||
      dirtyFields.isForeignBuyer ||
      dirtyFields.isRequireEInv ||
      dirtyFields.isGov ||
      dirtyFields.name ||
      dirtyFields.companyName ||
      dirtyFields.companyRegNo ||
      dirtyFields.directorName ||
      dirtyFields.nationality ||
      dirtyFields.identityType ||
      dirtyFields.identityNo ||
      dirtyFields.email ||
      dirtyFields.mobileNo ||
      dirtyFields.TIN ||
      dirtyFields.SST ||
      dirtyFields.customerOrigin ||
      dirtyFields.remarks ||
      dirtyFields.bookingFee ||
      dirtyFields.paymentMethod ||
      dirtyFields.paymentRemark ||
      dirtyFields.uploadAttachment ||
      dirtyFields.salutation ||
      dirtyFields.race ||
      dirtyFields.DOB ||
      dirtyFields.gender ||
      dirtyFields.maritalStatus ||
      dirtyFields.incomeRange ||
      dirtyFields.leadChannel ||
      dirtyFields.occupation ||
      dirtyFields.employerName ||
      dirtyFields.employerAddress ||
      dirtyFields.AddressType ||
      dirtyFields.address ||
      dirtyFields.country ||
      dirtyFields.state ||
      dirtyFields.city ||
      dirtyFields.postCode ||
      dirtyFields.district ||
      dirtyFields.isPrimary
    )
      setIsEdited(true)
    else setIsEdited(false)
  }, [
    dirtyFields.isCompanyPurc ||
      dirtyFields.isBumi ||
      dirtyFields.isForeignBuyer ||
      dirtyFields.isRequireEInv ||
      dirtyFields.isGov ||
      dirtyFields.name ||
      dirtyFields.companyName ||
      dirtyFields.companyRegNo ||
      dirtyFields.directorName ||
      dirtyFields.nationality ||
      dirtyFields.identityType ||
      dirtyFields.identityNo ||
      dirtyFields.email ||
      dirtyFields.mobileNo ||
      dirtyFields.TIN ||
      dirtyFields.SST ||
      dirtyFields.customerOrigin ||
      dirtyFields.remarks ||
      dirtyFields.bookingFee ||
      dirtyFields.paymentMethod ||
      dirtyFields.paymentRemark ||
      dirtyFields.uploadAttachment ||
      dirtyFields.salutation ||
      dirtyFields.race ||
      dirtyFields.DOB ||
      dirtyFields.gender ||
      dirtyFields.maritalStatus ||
      dirtyFields.incomeRange ||
      dirtyFields.leadChannel ||
      dirtyFields.occupation ||
      dirtyFields.employerName ||
      dirtyFields.employerAddress ||
      dirtyFields.AddressType ||
      dirtyFields.address ||
      dirtyFields.country ||
      dirtyFields.state ||
      dirtyFields.city ||
      dirtyFields.postCode ||
      dirtyFields.district ||
      dirtyFields.isPrimary,
  ])

  // region end

  // region Checkbox Options Validation

  useEffect(() => {
    if (isForeignBuyer) {
      setValue('TIN', 'E100000000020')
    } else if (isGov) {
      setValue('TIN', 'E100000000040')
    } else {
      setValue('TIN', '')
    }

    if (isRequireEInv) {
      setValue('SST', '')
    } else {
      setValue('SST', 'N/A')
    }
  }, [isForeignBuyer, isRequireEInv, isGov, setValue])

  // region end

  // region Address Listing

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const handleClose = () => {
    setOpenAddressDialog(false)
    setAnchorEl(null);
  }

  const [addressList, setAddressList] = useState<any[]>([])

  const AddressTypes = [
    'ID Address',
    'Billing Address',
    'Postal Address',
    'Company Address',
  ]

  const [disabledTypes, setDisabledTypes] = useState<string[]>([])

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedIndex(index)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedIndex(null)
  }

  // region end

  // region Save Address

  const {
    control: addressControl,
    handleSubmit: handleAddressSubmit,
    reset: addressReset,
    setValue: setAddressValue,
    watch,
    formState: { errors: addressErrors },
  } = useForm<AddressForm>({
    defaultValues: {
      AddressType: '',
      address: '',
      country: '',
      state: '',
      city: '',
      postCode: '',
      district: '',
      isPrimary: false,
    },
  })

  const country = watch('country');

  useEffect(() => {
    if (openAddressDialog && selectedAddress) {
      addressReset({
        addressType: selectedAddress?.addressType || '',
        address: selectedAddress?.address || '',
        country: selectedAddress?.country || '',
        state: selectedAddress?.state || '',
        city: selectedAddress?.city || '',
        postcode: selectedAddress?.postcode || '',
        district: selectedAddress?.district || '',
        primaryAddress: selectedAddress?.primaryAddress || false,
      })
    } else if (openAddressDialog) {
      addressReset({
        AddressType: '',
        address: '',
        country: '',
        state: '',
        city: '',
        postCode: '',
        district: '',
        isPrimary: false,
      })
    }
  }, [openAddressDialog, selectedAddress, addressReset])

  // useEffect(() => {
  //   const savedData = localStorage.getItem('addressData')
  //   if (savedData) {
  //     try {
  //       const parsedData = JSON.parse(savedData)
  //       if (Array.isArray(parsedData)) {
  //         setAddressList(parsedData)
  //       }
  //     } catch (error) {
  //       console.error('Error parsing address data:', error)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    const usedAddressTypes = addressList.map(
      (address: any) => address.AddressType
    )
    setDisabledTypes(prevDisabledTypes => {
      if (
        JSON.stringify(prevDisabledTypes) !== JSON.stringify(usedAddressTypes)
      ) {
        return usedAddressTypes
      }
      return prevDisabledTypes
    })
  }, [addressList])

  const onSave = (data: any) => {
    const index = addressList.findIndex(x => x.isPrimary);
    if(index >= 0 && data?.isPrimary){
      addressList[index].isPrimary = false;
    }

    if (selectedAddress) {
      const index = addressList.findIndex(
        (address: any) => address.AddressType === selectedAddress.AddressType
      )

      if (index !== -1) {
        addressList[index] = { ...addressList[index], ...data }
        localStorage.setItem('addressData', JSON.stringify(addressList))
        setAddressList(addressList)
        setSelectedAddress(null)
      }
    } else {
      const existingAddress = addressList.find(
        (address: any) => address.AddressType === data.AddressType
      )

      if (existingAddress) {
        alert(`Address type "${data.AddressType}" already exists!`)
        return
      }

      if (addressList.length < 4) {
        addressList.push(data)
        localStorage.setItem('addressData', JSON.stringify(addressList))
        setAddressList(addressList)
      } else {
        alert('You can only save up to 4 addresses.')
      }
    }
  }

  const onAddressSubmit = (data: any) => {
    onSave(data)
    setOpenAddressDialog(false)
  }

  // region end

  // region Edit address

  const handleEdit = () => {
    if (selectedIndex !== null) {
      setSelectedAddress(addressList[selectedIndex])
      setOpenAddressDialog(true)

      setAddressValue('addressType', selectedAddress.addressType)
    }
    handleMenuClose()
  }

  // region end

  // region Delete address

  const handleDelete = () => {
    if (selectedIndex !== null) {
      const updatedAddressList = [...addressList]
      updatedAddressList.splice(selectedIndex, 1)
      setAddressList(updatedAddressList)
      localStorage.setItem('addressData', JSON.stringify(updatedAddressList))
    }
    handleMenuClose()
  }

  // region end

  // region Remove address data

  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('addressData')
      setAddressList([])
    }

    const isPageReloaded = sessionStorage.getItem('pageReloaded')
    if (isPageReloaded) {
      clearData()
      sessionStorage.removeItem('pageReloaded')
    } else {
      sessionStorage.setItem('pageReloaded', 'true')
    }

    const handlePopState = () => {
      clearData()
    }

    window.onpopstate = handlePopState

    return () => {
      window.onpopstate = null
    }
  }, [])

  // region end

  // region Scan id

  const [name, setName] = useState('')
  const [isScanning, setIsScanning] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [scanResult, setScanResult] = useState('')

  const webcamRef = React.useRef(null)

  const startScanning = () => {
    setIsScanning(true)
  }

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImageSrc(imageSrc)
    setIsScanning(false)
    performOCR(imageSrc)
  }

  const performOCR = (imageSrc: any) => {
    setIsProcessing(true)
    Tesseract.recognize(imageSrc, 'eng', {
      logger: m => console.log(m),
    }).then(({ data: { text } }) => {
      setScanResult(text)
      const extractedName = extractNameFromText(text)
      setName(extractedName)
      setIsProcessing(false)
    })
  }

  const extractNameFromText = (text: any) => {
    const nameRegex = /Name:?\s*([A-Za-z\s]+)/
    const match = text.match(nameRegex)
    return match ? match[1] : ''
  }

  // region end

  // region Date

  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  // region end

  // region Unit lock / unlock logic

  const startCountdown = () => {
    setIsLocked(true)
    setCountdown(60)
  }

  // Timer logic to count down
  useEffect(() => {
    if (isLocked && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prev => prev - 1)
      }, 1000)

      return () => clearInterval(timer)
    } else if (countdown === 0) {
      setSelectedEndTime(new Date())
      setLockCompleted(true)
    }
  }, [countdown, isLocked])

  // region end

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth>
              <ColumnFlex>
                <FormControlLabel
                  label={'Company Purchase'}
                  control={
                    <Controller
                      name="isCompanyPurc"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={e => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  sx={{
                    height: '30px',
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                  }}
                />

                <FormControlLabel
                  label={'Bumiputera'}
                  control={
                    <Controller
                      name="isBumi"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={e => field.onChange(e.target.checked)}
                          disabled={isCompanyPurc}
                        />
                      )}
                    />
                  }
                  sx={{
                    height: '30px',
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                  }}
                />

                <FormControlLabel
                  label={'Foreign Buyer'}
                  control={
                    <Controller
                      name="isForeignBuyer"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={e => field.onChange(e.target.checked)}
                          disabled={isGov}
                        />
                      )}
                    />
                  }
                  sx={{
                    height: '30px',
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                  }}
                />

                <FormControlLabel
                  label={'Require E-Invoice'}
                  control={
                    <Controller
                      name="isRequireEInv"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={e => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  sx={{
                    height: '30px',
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                  }}
                />

                <FormControlLabel
                  label={'Government/Authority Purchase'}
                  control={
                    <Controller
                      name="isGov"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={e => field.onChange(e.target.checked)}
                          disabled={isForeignBuyer}
                        />
                      )}
                    />
                  }
                  sx={{
                    height: '30px',
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                  }}
                />
              </ColumnFlex>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={startScanning}
                  disabled={isScanning || isProcessing}
                  style={{
                    padding: '4px 8px',
                    fontSize: '12px',
                    height: '30px',
                    width: '100px',
                  }}
                >
                  Scan ID
                </Button>
              </Box>

              {isScanning && (
                <div>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    videoConstraints={{
                      facingMode: 'environment',
                    }}
                  />
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={captureImage}
                      style={{ marginRight: '10px' }}
                    >
                      Capture
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setIsScanning(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </RowGrid>

            {!isCompanyPurc && (
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    onChange={e => onChange(e.target.value)}
                    onBlur={onBlur}
                    value={value || ' '}
                    label="Purchaser Name as per NRIC"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    name="name"
                    select
                    required
                  >
                    {BookingContactList.length > 0 ? (
                      BookingContactList.map(contact => (
                        <MenuItem key={contact.id} value={contact.fullName}>
                          {contact.fullName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No Contacts Available</MenuItem>
                    )}
                  </TextField>
                )}
              />
            )}

            {isCompanyPurc && (
              <>
                <Controller
                  control={control}
                  name="companyName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.companyName ? true : false}
                      helperText={errors.companyName?.message}
                      label="Company Name"
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="companyRegNo"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.companyRegNo ? true : false}
                      helperText={errors.companyRegNo?.message}
                      label="Company Registration No."
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="directorName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.directorName ? true : false}
                      helperText={errors.directorName?.message}
                      label="Director Name"
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </>
            )}

            <Controller
              control={control}
              name="nationality"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.nationality ? true : false}
                  helperText={errors.nationality?.message}
                  label="Nationality"
                  type="text"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
              <Controller
                control={control}
                name="identityType"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    onChange={e => {
                      onChange(e?.target?.value)
                    }}
                    onBlur={onBlur}
                    value={value || ''}
                    label="Identity Type"
                    autoComplete="off"
                    variant="standard"
                    fullWidth
                    required
                    margin="normal"
                    name="identityType"
                    select
                  >
                    <MenuItem value="Identity Card">Identity Card</MenuItem>

                    {IdentityData &&
                      IdentityData.map(identity => (
                        <MenuItem key={identity.id} value={identity.id}>
                          {identity.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />

              <Controller
                control={control}
                name="identityNo"
                render={({ field }) => (
                  <TextField
                    error={errors.identityNo ? true : false}
                    helperText={errors.identityNo?.message}
                    label="Identity No."
                    type="text"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </RowGrid>

            <ColumnFlex fullWidth>
              <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.email ? true : false}
                      helperText={errors.email?.message}
                      label="Email Address"
                      type="email"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="mobileNo"
                  render={({ field }) => (
                    <MuiPhoneNumber
                      {...field}
                      disableAreaCodes={true}
                      defaultCountry={'my'}
                      variant="standard"
                      fullWidth
                      margin="normal"
                      label="Mobile No."
                      value={field.value}
                      error={errors.mobileNo ? true : false}
                      helperText={errors.mobileNo?.message}
                      required
                      autoFormat={false}
                    />
                  )}
                />
              </RowGrid>

              <Divider sx={{ margin: '12px 0' }} />

              {!isRequireEInv && (
                <>
                  <Controller
                    control={control}
                    name="TIN"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="TIN"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={field.value}
                        onChange={e => field.onChange(e.target.value)}
                        error={!!errors.TIN}
                        helperText={errors.TIN?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="SST"
                    render={({ field }) => (
                      <TextField
                        error={errors.SST ? true : false}
                        helperText={errors.SST?.message}
                        label="SST Registration No."
                        type="text"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        value={field.value}
                        onChange={e => field.onChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </>
              )}

              {isRequireEInv && (
                <>
                  <Controller
                    control={control}
                    name="TIN"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="TIN"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        value={field.value}
                        onChange={e => field.onChange(e.target.value)}
                        error={!!errors.TIN}
                        required
                        helperText={errors.TIN?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="SST"
                    render={({ field }) => (
                      <TextField
                        error={errors.SST ? true : false}
                        helperText={errors.SST?.message}
                        label="SST Registration No."
                        type="text"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        value={field.value}
                        required
                        onChange={e => field.onChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </>
              )}
            </ColumnFlex>

            <Controller
              control={control}
              name="unitNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onChange={e => {
                    onChange(e?.target?.value)
                  }}
                  onBlur={onBlur}
                  value={value || ' '}
                  label="Unit No."
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  name="unitNo"
                  required
                  select
                >
                  {UnitData &&
                    UnitData.map(unit => (
                      <MenuItem
                        key={unit.id}
                        value={unit.unitNo}
                        onClick={() => {
                          setSelectedUnitId(unit.id)
                          setselectedStartTime(new Date())
                          startCountdown()
                          onChange(unit.unitNo)
                        }}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          {unit.unitNo}

                          {unit.isBumi && (
                            <Badge
                              sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginLeft: '5px',
                                marginTop: '-8px',
                              }}
                            >
                              <PxLetterB />
                            </Badge>
                          )}
                        </Typography>

                        <Typography>{unit.unitStatus}</Typography>
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="customerOrigin"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onChange={e => {
                    onChange(e?.target?.value)
                  }}
                  onBlur={onBlur}
                  value={value || 'public'}
                  label="Customer Origin"
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  name="customerOrigin"
                  select
                >
                  <MenuItem value="Public">Public</MenuItem>

                  {OriginData &&
                    OriginData.map(origin => (
                      <MenuItem key={origin.id} value={origin.id}>
                        {origin.name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />

            <ColumnFlex fullWidth gap="12px">
              <Box>
                <Typography
                  className="value-f"
                  style={{
                    color: '#454545',
                    fontSize: '14px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  Package
                </Typography>
                <Divider />
              </Box>
              <RowGrid gridTemplateColumns="1fr  1fr">
                <Box>
                  <Typography
                    className="value-f"
                    style={{ fontSize: '10px', color: '#454545' }}
                  >
                    List Price
                  </Typography>
                  <Typography
                    className="value-f"
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    <span>{'-'}</span>
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    className="value-f"
                    style={{ fontSize: '10px', color: '#454545' }}
                  >
                    Selling Price
                  </Typography>
                  <Typography
                    className="value-f"
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    <span>{'-'}</span>
                  </Typography>
                </Box>
              </RowGrid>
              <Box>
                <Typography
                  className="value-f"
                  style={{ fontSize: '10px', color: '#454545' }}
                >
                  Rebate Amount
                </Typography>
                <Typography
                  className="value-f"
                  style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  <span>{'-'}</span>
                </Typography>
              </Box>
            </ColumnFlex>

            <Controller
              control={control}
              name="remarks"
              render={({ field }) => (
                <TextField
                  error={errors.remarks ? true : false}
                  helperText={errors.remarks?.message}
                  label="Remarks"
                  type="text"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="bookingFee"
              render={({ field }) => (
                <TextField
                  error={errors.bookingFee ? true : false}
                  helperText={errors.bookingFee?.message}
                  label="Booking Fee"
                  type="text"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="paymentMethod"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  onChange={e => {
                    onChange(e?.target?.value)
                  }}
                  onBlur={onBlur}
                  value={value || ''}
                  label="Payment Method"
                  autoComplete="off"
                  variant="standard"
                  fullWidth
                  margin="normal"
                  name="paymentMethod"
                  select
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Cheque / Banker's Cheque">
                    Cheque / Banker's Cheque
                  </MenuItem>
                  <MenuItem value="Credit Card / Debit Card">
                    Credit Card / Debit Card
                  </MenuItem>
                  <MenuItem value="Revenue Monster">Revenue Monster</MenuItem>
                  <MenuItem value="Online Bank Transfer">
                    Online Bank Transfer
                  </MenuItem>
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="paymentRemark"
              render={({ field }) => (
                <TextField
                  error={errors.paymentRemark ? true : false}
                  helperText={errors.paymentRemark?.message}
                  label="Payment Remark"
                  type="text"
                  variant="standard"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            {/* <Controller
              control={control}
              name="uploadAttachment"
              render={({ field, fieldState: { error } }) => (
                <div style={{ width: '100%' }}>
                  <input
                    {...field}
                    type="file"
                    accept="image/*, .pdf, .docx, .xlsx"
                    id="uploadAttachment"
                    style={{ display: 'none' }}
                    onChange={e => {
                      const file = e.target.files[0]
                      if (file) {
                        setSelectedFileName(file.name)
                      }
                      field.onChange(e.target.files)
                    }}
                  />
                  <TextField
                    value={selectedFileName}
                    error={!!error}
                    helperText={error ? error.message : ''}
                    label="Upload Attachment"
                    type="text"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Button
                    variant="contained"
                    component="label"
                    htmlFor="uploadAttachment"
                    fullWidth
                    sx={{ width: 'auto' }}
                  >
                    Upload
                  </Button>
                  {error && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        marginTop: '8px',
                      }}
                    >
                      {error?.message}
                    </p>
                  )}
                </div>
              )}
            /> */}
          </Card>

          <Card
            className="card-container-detail"
            header={{
              title: (
                <SubheaderText
                  primary={
                    <Box display="flex" margin="2px 0px" width="100%">
                      <Typography
                        className="text-xsTitle"
                        component="span"
                        flex="1 1"
                        variant="inherit"
                      >
                        Additional Purchaser Information
                      </Typography>
                      <IconButton onClick={toggleExpansion}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>
                  }
                />
              ),
            }}
          >
            {expanded && (
              <Box>
                <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                  <Controller
                    control={control}
                    name="salutation"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={e => {
                          onChange(e?.target?.value)
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        label="Salutation"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        name="salutation"
                        select
                      >
                        <MenuItem value="Mrs">Mrs</MenuItem>

                        {BookingMasterData?.Salutations &&
                          BookingMasterData.Salutations.length > 0 &&
                          BookingMasterData.Salutations.map(salutation => (
                            <MenuItem key={salutation.id} value={salutation.id}>
                              {salutation.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    control={control}
                    name="race"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={e => {
                          onChange(e?.target?.value)
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        label="Race"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        name="race"
                        select
                      >
                        <MenuItem value="Malay">Malay</MenuItem>

                        {BookingMasterData?.Races &&
                          BookingMasterData.Races.length > 0 &&
                          BookingMasterData.Races.map(race => (
                            <MenuItem key={race.id} value={race.id}>
                              {race.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </RowGrid>

                <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      control={control}
                      name="DOB"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <DatePicker
                          label="Date"
                          name="date"
                          value={selectedDate}
                          onChange={handleDateChange}
                          slotProps={{
                            textField: {
                              sx: {
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderWidth: 0,
                                    borderBottom: '1px solid #757575',
                                  },
                                  height: '50x',
                                  marginTop: '8px',
                                },
                                '& input::placeholder': {
                                  fontSize: '12px',
                                },
                                '& input': {
                                  paddingLeft: '0px',
                                  paddingTop: '22px',
                                  fontSize: '12px',
                                },
                              },
                              InputLabelProps: {
                                sx: {
                                  marginLeft: '-14px',
                                  marginTop: '14px',
                                },
                                shrink: true,
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <Controller
                    control={control}
                    name="gender"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={e => {
                          onChange(e?.target?.value)
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        label="Gender"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        name="gender"
                        select
                      >
                        {Object.keys(BookingMasterData?.Genders || {}).map(
                          key => (
                            <MenuItem key={key} value={key}>
                              {BookingMasterData?.Genders[key]}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    )}
                  />
                </RowGrid>

                <Controller
                  control={control}
                  name="maritalStatus"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      onChange={e => {
                        onChange(e?.target?.value)
                      }}
                      onBlur={onBlur}
                      value={value || ''}
                      label="Marital Status"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      name="maritalStatus"
                      select
                    >
                      {BookingMasterData?.MaritalStatus &&
                        BookingMasterData.MaritalStatus.length > 0 &&
                        BookingMasterData.MaritalStatus.map(marital => (
                          <MenuItem key={marital.id} value={marital.id}>
                            {marital.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />

                <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                  <Controller
                    control={control}
                    name="incomeRange"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={e => {
                          onChange(e?.target?.value)
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        label="Income Range"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        name="incomeRange"
                        select
                      >
                        {BookingMasterData?.IncomeRanges &&
                          BookingMasterData.IncomeRanges.length > 0 &&
                          BookingMasterData.IncomeRanges.map(income => (
                            <MenuItem key={income.id} value={income.id}>
                              {income.range}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    control={control}
                    name="leadChannel"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={e => {
                          onChange(e?.target?.value)
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        label="Lead Channel"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        name="leadChannel"
                        select
                      >
                        {BookingMasterData?.LeadSources &&
                          BookingMasterData.LeadSources.length > 0 &&
                          BookingMasterData.LeadSources.map(lead => (
                            <MenuItem key={lead.id} value={lead.id}>
                              {lead.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </RowGrid>

                <Controller
                  control={control}
                  name="occupation"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      onChange={e => {
                        onChange(e?.target?.value)
                      }}
                      onBlur={onBlur}
                      value={value || ''}
                      label="Occupation"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      name="occupation"
                      select
                    >
                      {BookingContactList.length > 0 ? (
                        BookingContactList.map(contact => (
                          <MenuItem
                            key={contact.id}
                            // value={contact.leads}
                          >
                            {contact.leads}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">No Occupation Available</MenuItem>
                      )}
                    </TextField>
                  )}
                />

                <Controller
                  control={control}
                  name="employerName"
                  render={({ field }) => (
                    <TextField
                      error={errors.employerName ? true : false}
                      helperText={errors.employerName?.message}
                      label="Employer Name"
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="employerAddress"
                  render={({ field }) => (
                    <TextField
                      error={errors.employerAddress ? true : false}
                      helperText={errors.employerAddress?.message}
                      label="Employer Address"
                      type="text"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                <Card
                  className="card-container-detail"
                  header={{
                    title: (
                      <SubheaderText
                        primary={
                          <Box display="flex" margin="2px 0px" width="100%">
                            <Typography
                              className="text-xsTitle"
                              color="FF9800"
                              component="span"
                              flex="1 1"
                              variant="inherit"
                            >
                              Address Listing
                            </Typography>
                          </Box>
                        }
                      />
                    ),
                    action: (
                      <>
                        <IconButton
                          onClick={() => {
                            setSelectedAddress(null)
                            setOpenAddressDialog(true)
                          }}
                        >
                          <AddCircleOutlineIcon color="primary" />
                        </IconButton>
                      </>
                    ),
                  }}
                  sx={{ width: '100%', margin: '10px' }}
                >
                  <Container>
                    <List sx={{ width: '100%' }}>
                      {addressList.length === 0 ? (
                        <EmptyState />
                      ) : (
                        addressList.map((addressListing, index) => (
                          <Box
                            component="div"
                            key={index}
                            sx={{ width: '100%' }}
                          >
                            <ListItemText
                              primary={
                                <RowFlex fullWidth>
                                  <Typography
                                    className="text-icon text-xsTitle"
                                    color="black"
                                    component="span"
                                    flex="1"
                                    variant="inherit"
                                  >
                                    {addressListing.AddressType}
                                  </Typography>

                                  {addressListing?.isPrimary && (
                                    <Chip
                                      label={
                                        <Typography
                                          component="span"
                                          className="text-label"
                                          variant="inherit"
                                        >
                                          Primary Address
                                        </Typography>
                                      }
                                      sx={{
                                        background: '#696969',
                                        color: '#ffffff',
                                        padding: '0px',
                                      }}
                                    />
                                  )}
                                </RowFlex>
                              }
                              secondary={
                                <RowGrid
                                  gridTemplateColumns="1fr 1fr"
                                  height="20px"
                                >
                                  <Typography
                                    className="text-icon text-desc"
                                    color="#454545"
                                    component="div"
                                    variant="inherit"
                                    sx={{ marginTop: '-15px' }}
                                  >
                                    {addressListing
                                      ? `${addressListing.address || ''} ${
                                          addressListing.postCode || ''
                                        } ${addressListing.city || ''} ${
                                          addressListing.state || ''
                                        } ${addressListing.country || ''}`
                                      : 'Loading address...'}
                                  </Typography>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                  >
                                    <IconButton
                                      sx={{ marginRight: '-14px' }}
                                      onClick={event =>
                                        handleMenuClick(event, index)
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Box>
                                </RowGrid>
                              }
                            />
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <MenuItem onClick={handleEdit}>Edit</MenuItem>
                              <MenuItem onClick={handleDelete}>Delete</MenuItem>
                            </Menu>
                          </Box>
                        ))
                      )}
                    </List>
                  </Container>
                </Card>
              </Box>
            )}
          </Card>
        </form>
        <Footer
          buttons={[
            {
              children: 'Save',
              color: 'primary',
              onClick: handleSubmit(onSubmit),
            },
          ]}
        />
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={openAddressDialog}
        onClose={() => setOpenAddressDialog(false)}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Typography
                      className="text-mdTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Address
                    </Typography>
                  </Box>
                }
              />
            </Subheader>
          </>
        }
        body={
          <>
            <form onSubmit={handleAddressSubmit(onAddressSubmit)}>
              <ColumnFlex gap="12px">
                <ColumnFlex>
                  <Controller
                    control={addressControl}
                    name="AddressType"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={e => onChange(e?.target?.value)}
                        onBlur={onBlur}
                        value={value || ''}
                        label="Address Type"
                        autoComplete="off"
                        variant="standard"
                        fullWidth
                        margin="normal"
                        name="AddressType"
                        select
                        required
                      >
                        {AddressTypes.map(AddressType => (
                          <MenuItem
                            key={AddressType}
                            value={AddressType}
                            disabled={disabledTypes.includes(AddressType)}
                          >
                            {AddressType}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </ColumnFlex>

                <ColumnFlex>
                  <Controller
                    control={addressControl}
                    name="address"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={addressErrors.address ? true : false}
                        helperText={addressErrors.address?.message}
                        label="Address"
                        type="text"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </ColumnFlex>

                <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="country"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.country ? true : false}
                          helperText={addressErrors.country?.message}
                          label="Country"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="state"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.state ? true : false}
                          helperText={addressErrors.state?.message}
                          label="State"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required={country?.toLowerCase() === 'malaysia'}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                </RowGrid>

                <RowGrid gridTemplateColumns="1fr 1fr" fullWidth gap="20px">
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="city"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.city ? true : false}
                          helperText={addressErrors.city?.message}
                          label="City"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                  <ColumnFlex>
                    <Controller
                      control={addressControl}
                      name="postCode"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={addressErrors.postCode ? true : false}
                          helperText={addressErrors.postCode?.message}
                          label="Postcode"
                          type="text"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </ColumnFlex>
                </RowGrid>

                <ColumnFlex>
                  <Controller
                    control={addressControl}
                    name="district"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={addressErrors.district ? true : false}
                        helperText={addressErrors.district?.message}
                        label="District"
                        type="text"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </ColumnFlex>

                <ColumnFlex>
                  <FormControlLabel
                    label={'Primary Address'}
                    control={
                      <Controller
                        name="isPrimary"
                        control={addressControl}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                  />
                </ColumnFlex>
              </ColumnFlex>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button color="primary" type="submit">
                  Save
                </Button>
                <Button
                  color="primary"
                  onClick={() => setOpenAddressDialog(false)}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </>
        }
      />
    </>
  )
}

export default NewBooking
