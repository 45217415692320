import ReactQuill from 'react-quill'
import './Quill.css'
import EditorToolbar, { modules, formats } from './EditorToolbar'
import { useEffect } from 'react'

export const isEditorEmpty = () => {
  const editor = document.querySelector('.ql-editor')
  if (editor) {
    return editor.textContent.trim().length === 0
  }
  return true
}

const QuillTextEditor = ({ value, onChange }) => {
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
      />
    </div>
  )
}

export default QuillTextEditor
